import { useEffect, RefObject } from "react";

const useSavvyMoneyResizeHandlers = (
  url: string | null,
  iframeRef: RefObject<HTMLIFrameElement>,
) => {
  useEffect(() => {
    if (!url) {
      // Empty clean up function
      return () => {};
    }

    // Extract the allowed origin from the URL
    const allowedOrigin = new URL(url).origin;

    const handleMessage = (e: MessageEvent) => {
      // Validate message origin
      if (e.origin !== allowedOrigin) {
        return;
      }

      // Ensure e.data is an object
      if (typeof e.data !== "object" || e.data === null) {
        return;
      }

      const { task, isSmoothScrollNotSupported, offset, height } = e.data;
      const iframe = iframeRef.current;
      switch (task) {
        case "scrollTo":
          if (typeof offset === "number") {
            window.scrollTo({
              top: offset,
              behavior: isSmoothScrollNotSupported ? "auto" : "smooth",
            });
          }
          break;

        case "scrollToTop":
          window.scrollTo({
            top: 0,
            behavior: isSmoothScrollNotSupported ? "auto" : "smooth",
          });
          break;

        case "resize":
          if (typeof height === "number" && iframe) {
            iframe.style.height = `${height}px`;
            iframe.contentWindow?.postMessage(
              { task: "setAutosizeClassName" },
              allowedOrigin,
            );
          }
          break;

        default:
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [url]);
};

export default useSavvyMoneyResizeHandlers;

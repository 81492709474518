import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  type PropsWithChildren,
} from "react";

import { type FormikValues, type FormikConfig, useFormik } from "formik";

import { useObjectMemo } from "../../hooks";

type CreateFormConfig<Values extends FormikValues> = Omit<
  FormikConfig<Values>,
  "onSubmit"
> & {
  onSubmit?: FormikConfig<Values>["onSubmit"];
};

export function createForm<Values extends FormikValues>(
  defaultProps: CreateFormConfig<Values>,
) {
  type FormType = ReturnType<typeof useFormik<Values>>;
  const FormContext = createContext<FormType | undefined>(undefined);
  const FormValidationContext = createContext<{
    validationSchema: FormikConfig<Values>["validationSchema"];
    setValidationSchema: (
      schema: FormikConfig<Values>["validationSchema"],
    ) => void;
  } | null>(null);

  type FormSubmission = { success: false } | { success: true; values: Values };

  const useForm = () => {
    const form = useContext(FormContext);
    if (!form) {
      throw new Error("useForm must be used within a FormProvider");
    }
    const formValidation = useContext(FormValidationContext);
    if (!formValidation) {
      throw new Error("useForm must be used within a FormValidationProvider");
    }
    const { handleSubmit, values, validateForm } = form;
    const { setValidationSchema, validationSchema } = formValidation;

    const submitForm = useCallback<() => Promise<FormSubmission>>(async () => {
      handleSubmit();
      const errors = await validateForm(values);
      const success = Object.keys(errors).length === 0;
      if (success) {
        return { success, values };
      }
      return { success };
    }, [handleSubmit, validateForm, values]);

    const augmentedForm = useObjectMemo({
      ...form,
      submitForm,
      setValidationSchema,
      validationSchema,
    });
    return augmentedForm;
  };

  interface ProviderProps extends PropsWithChildren {
    initialValues?: Values;
    onSubmit?: FormikConfig<Values>["onSubmit"];
    validateOnMount?: boolean;
    validateOnBlur?: boolean;
    validateOnChange?: boolean;
  }

  const Provider = ({
    children,
    onSubmit,
    initialValues,
    ...restProps
  }: ProviderProps) => {
    const [currentValidationSchema, setValidationSchema] = useState(
      defaultProps.validationSchema,
    );

    const form = useFormik({
      ...defaultProps,
      ...restProps,
      initialValues: initialValues ?? defaultProps.initialValues,
      validationSchema: currentValidationSchema,
      onSubmit: onSubmit ?? defaultProps.onSubmit ?? (() => {}),
    });

    const formValidationValue = useMemo(
      () => ({
        validationSchema: currentValidationSchema,
        setValidationSchema,
      }),
      [currentValidationSchema],
    );

    return (
      <FormValidationContext.Provider value={formValidationValue}>
        <FormContext.Provider value={form}>{children}</FormContext.Provider>
      </FormValidationContext.Provider>
    );
  };

  return {
    Provider,
    useForm,
  };
}

import {
  useEffect,
  useState,
  ReactNode,
  KeyboardEvent,
  useCallback,
} from "react";
import { Localized } from "@fluent/react";
import PropTypes from "prop-types";
import { Menu } from "react-feather";
import { Button, LoadingShim } from "@narmi/design_system";
import {
  InstitutionLogo,
  useNotificationContext,
  useLoadingContext,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";

import ButtonBar from "./ButtonBar";
import FdicBanner from "./FdicBanner";
import Modal from "./Modal";
import Details from "./Details";
import ProfileSwitcher from "./profile_switcher/ProfileSwitcher";
import { useCurrentUser } from "./contexts/CurrentUserContext";
import MobileNavigationDrawer from "./MobileNavigationDrawer";

const noop = () => null;
interface NavBarProps {
  features: API.Features;
  children: ReactNode;
  deviceIsRemembered: boolean;
  institution: {
    // Define more specific type details as required
    [key: string]: any;
  };
}

const NavBar = ({
  features,
  children,
  deviceIsRemembered,
  institution,
}: NavBarProps) => {
  const { currentUser } = useCurrentUser();
  const [open, setOpen] = useState(false);
  // this is to fetch linked profiles in the user details modal
  const [isFetchingProfiles, setIsFetchingProfiles] = useState(false);
  const [linkedProfiles, setLinkedProfiles] = useState([]);
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const profileSwitcherEnabled =
    features.profile_switcher && deviceIsRemembered;

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsFetchingProfiles(true);
      try {
        const response = await ApiHttp.fetch("/linked_profiles", {
          method: "GET",
          endpointIsUrl: true,
        });
        if (response?.linked_profiles) {
          const allLinkedProfiles = response?.linked_profiles;
          const isOnlyCurrentUser =
            allLinkedProfiles.length === 1 &&
            allLinkedProfiles[0].username === currentUser?.username;
          setLinkedProfiles(isOnlyCurrentUser ? [] : allLinkedProfiles);
        }
      } catch {
        sendNotification({
          type: "negative",
          text: "There was an error retrieving your linked profiles, please try again later.",
        });
      }
      setIsFetchingProfiles(false);
    };
    if (profileSwitcherEnabled) {
      fetchProfiles();
    }
  }, [profileSwitcherEnabled, currentUser, sendNotification]);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      toggleOpen();
    }
  };

  const MenuContent = (
    <div className="nds-navbar-container" data-testid="navbar-container">
      <ButtonBar className="nds-navbar-mainmenu">{children}</ButtonBar>
      {/* .nds-navbar-mainmenu */}
      {currentUser && (
        <ButtonBar className="nds-navbar-usermenu">
          <Details
            label={currentUser.getShortDescription()}
            type={linkedProfiles.length > 0 ? "medium details" : "details"}
          >
            <LoadingShim isLoading={isFetchingProfiles}>
              {linkedProfiles.length > 0 && (
                <div
                  className="padding--bottom--l"
                  data-testid="linked-profiles-container"
                >
                  {linkedProfiles.map((profile, index) => (
                    <div className="profile-hover" key={index}>
                      <ProfileSwitcher profile={profile} />
                    </div>
                  ))}
                </div>
              )}
            </LoadingShim>
            {profileSwitcherEnabled && (
              <button
                className="profile-option button--reset"
                data-testid="link-profile-button"
                onClick={() => {
                  setIsLoading(true);
                  window.open("/login/link", "_self");
                }}
              >
                Link another profile
              </button>
            )}
            <button
              onClick={() => window.open("/settings", "_self")}
              className="profile-option button--reset"
              data-testid="go-to-settings-button"
            >
              Settings
            </button>
            {deviceIsRemembered && (
              <button
                className="profile-option button--reset"
                onClick={() => window.open("/logout/forget", "_self")}
                data-testid="forget-this-device-button"
              >
                Forget this device
              </button>
            )}
            <button
              className="profile-option button--reset"
              onClick={() => window.open("/logout", "_self")}
              data-testid="logout-button"
            >
              Logout
            </button>
          </Details>
        </ButtonBar>
      )}
    </div>
  );

  return (
    <div className="navBar always-visible-navBar">
      <div className="nds-container narmi-grid">
        <InstitutionLogo
          institution={institution}
          className="nds-navbar-logo"
        />
        <Modal open={false} setOpen={noop} type="right">
          {MenuContent}
        </Modal>
        <MobileNavigationDrawer isOpen={open} close={close}>
          {MenuContent}
        </MobileNavigationDrawer>
        {currentUser ? (
          <div
            role="button"
            tabIndex={0}
            className="nds-navbar-hamburger"
            onKeyUp={handleKeyUp}
            onClick={toggleOpen}
          >
            <Menu />
          </div>
        ) : (
          !window.location.pathname.match(
            /password_reset|enroll|login\/verify_/,
          ) && (
            <div className="get-started">
              <span>
                {<Localized id="header-get-started">Need to enroll?</Localized>}
                <span>&nbsp;</span>
              </span>
              <Button
                kind="plain"
                label={<Localized id="cta-get-started">Get started</Localized>}
                onClick={() => {
                  window.location.assign("/enroll");
                }}
              />
            </div>
          )
        )}
        {/* .nds-navbar-hamburger */}
      </div>
      {/* .nds-container */}
      <FdicBanner depositInsurer={institution?.depositInsurer} />
    </div>
  );
};

NavBar.propTypes = {
  features: PropTypes.object,
  children: PropTypes.node.isRequired,
  deviceIsRemembered: PropTypes.bool,
  institution: PropTypes.object,
};

export default NavBar;

import { Row, Tooltip, formatNumber } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import { groupAccounts } from "byzantine/src/AccountUtils";
import Filters from "byzantine/src/filters";
import { NotificationContext, Options } from "cerulean";
import cc from "classcat";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionCard from "../SectionCard";
import AccountContext from "../contexts/AccountContext";

const SortPopoverItem = ({
  children,
  isSelected = false,
  hasGutter = false,
  onClick = () => {},
  closeDropdown = () => {},
}) => {
  const handleKeyUp = ({ key }) => {
    if (key === "Enter") {
      onClick();
      closeDropdown();
    }
  };
  return (
    <button
      className={cc([
        "sortPopover-item",
        "button--reset hoverable clickable",
        "padding--y--xs padding--x--s",
        "fontSize--s",
      ])}
      onClick={onClick}
      onKeyUp={handleKeyUp}
      /**
       * The `menuitemradio` role communicates that only one item in a set
       * may be selected at a time. The set is defined by the nearest parent
       * with a role of `menu`.
       */
      role="menuitemradio"
      aria-checked={isSelected}
    >
      <Row gapSize="none" alignItems="center">
        {isSelected && (
          <Row.Item shrink>
            <div
              className={cc([
                "sortPopover-item-check",
                "alignChild--center--center",
                "fontColor--theme--primary fontWeight--semibold fontSize--l",
              ])}
            >
              <span className="narmi-icon-check" />
            </div>
          </Row.Item>
        )}
        <Row.Item>
          <span
            className={cc([
              {
                "padding--left--l": hasGutter && !isSelected,
              },
            ])}
          >
            <span className="padding--left--xxs">{children}</span>
          </span>
        </Row.Item>
      </Row>
    </button>
  );
};

SortPopoverItem.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  hasGutter: PropTypes.bool,
  onClick: PropTypes.func,
  closeDropdown: PropTypes.func,
};

const GroupHeader = ({
  collapsible = false,
  isShowBalanceAndSorting = true,
  isCollapsed,
  setIsCollapsed = () => {},
  groupName,
  groupNameInfoText,
  balance = "",
}) => {
  const { t } = useTranslation();
  const {
    getAccountSortingForGroup,
    clearSorting,
    sortGroupAlphabetically,
    sortGroupByBalance,
    sortGroupByAccountNumber,
    sortAscending,
    sortDescending,
  } = useContext(AccountContext);
  const accountSorting = getAccountSortingForGroup(groupName);
  const anyItemSelected = !!accountSorting?.sort_by;

  const PopoverContent = () => (
    <>
      <div role="menu" aria-label="Sort by">
        <div className="option-title fieldset-label">
          {anyItemSelected && <div style={{ width: "28px" }} />}
          SORT BY
        </div>
        <SortPopoverItem
          hasGutter={anyItemSelected}
          isSelected={accountSorting.sort_by === "alphabetically"}
          onClick={() =>
            accountSorting.sort_by === "alphabetically"
              ? clearSorting(groupName)
              : sortGroupAlphabetically(groupName, accountSorting.order)
          }
        >
          Sort alphabetically
        </SortPopoverItem>
        <SortPopoverItem
          hasGutter={anyItemSelected}
          isSelected={accountSorting.sort_by === "by_balance"}
          onClick={() =>
            accountSorting.sort_by === "by_balance"
              ? clearSorting(groupName)
              : sortGroupByBalance(groupName, accountSorting.order)
          }
        >
          Sort by balance
        </SortPopoverItem>
        <SortPopoverItem
          hasGutter={anyItemSelected}
          isSelected={accountSorting.sort_by === "by_account_number"}
          onClick={() =>
            accountSorting.sort_by === "by_account_number"
              ? clearSorting(groupName)
              : sortGroupByAccountNumber(groupName, accountSorting.order)
          }
        >
          Sort by account number
        </SortPopoverItem>
      </div>
      <hr
        style={{
          color: "var(--color-lightGrey)",
          width: "calc(100% - 16px)",
          marginTop: "10px",
        }}
      />
      <div role="menu" aria-label="Order">
        <div className="option-title fieldset-label">
          {anyItemSelected && <div style={{ width: "28px" }} />}
          ORDER
        </div>
        <SortPopoverItem
          hasGutter={anyItemSelected}
          isSelected={accountSorting.order === "ascending"}
          onClick={() => sortAscending(groupName, accountSorting.sort_by)}
        >
          Ascending
        </SortPopoverItem>
        <SortPopoverItem
          hasGutter={anyItemSelected}
          isSelected={accountSorting.order === "descending"}
          onClick={() => sortDescending(groupName, accountSorting.sort_by)}
        >
          Descending
        </SortPopoverItem>
      </div>
    </>
  );
  return (
    <div
      className="account-group-header"
      role="button"
      tabIndex={0}
      onClick={() => {
        setIsCollapsed(!isCollapsed);
      }}
      onKeyUp={({ key }) => {
        if (key === "Enter") setIsCollapsed(!isCollapsed);
      }}
    >
      <div className="fontWeight--bold padding--y--xs">
        <Row>
          <Row.Item>
            <div className="group-name">
              <div>{t(groupName)}&nbsp;</div>
              {groupNameInfoText && (
                <Tooltip text={groupNameInfoText}>
                  <span className="narmi-icon-info fontColor--primary fontSize--xxs" />
                </Tooltip>
              )}
              {collapsible && (
                <span
                  className={`clickable narmi-icon-chevron-${
                    isCollapsed ? "down" : "up"
                  }`}
                />
              )}
            </div>
          </Row.Item>
          <Row.Item shrink>
            <div className="balance-options">
              {isShowBalanceAndSorting && (
                <span
                  className={cc([
                    "fontColor--heading",
                    {
                      "margin--right--xxs": !isCollapsed,
                      "margin--right--l": isCollapsed,
                    },
                  ])}
                >
                  {balance}
                </span>
              )}
              {!isCollapsed && isShowBalanceAndSorting && (
                <div className="margin--right--xxs">
                  <Options className="popover-option">
                    <div style={{ minWidth: "max-content" }}>
                      <PopoverContent />
                    </div>
                  </Options>
                </div>
              )}
            </div>
          </Row.Item>
        </Row>
      </div>
    </div>
  );
};
GroupHeader.propTypes = {
  collapsible: PropTypes.bool,
  isShowBalanceAndSorting: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  groupName: PropTypes.string,
  groupNameInfoText: PropTypes.string,
  balance: PropTypes.string,
};

const AccountRow = ({ account }) => {
  const { updateAccount } = useContext(AccountContext);
  const { sendNotification } = useContext(NotificationContext);
  return (
    <div className="padding--y--m brand-hover account-row">
      <Row alignItems="center">
        <Row.Item>
          <span
            onClick={() => window.location.assign(`accounts/${account.id}`)}
            role="button"
            onKeyUp={({ key }) => {
              if (key === "Enter")
                window.location.assign(`accounts/${account.id}`);
            }}
            tabIndex={0}
          >
            <div>{account.getShortDescription()}</div>
            {account.getGroupName() === "CDs" &&
              !account.hidden &&
              account.metadata?.maturity_date?.value && (
                <div className="fontSize--s fontColor--secondary">
                  {`Matures ${
                    account.formattedMetadata({
                      date: Filters.longMonthDayYear,
                    })?.maturity_date?.value
                  }`}
                </div>
              )}
          </span>
        </Row.Item>
        <Row.Item shrink>
          <div className="balance-options">
            <span
              onClick={() => window.location.assign(`accounts/${account.id}`)}
              role="button"
              onKeyUp={({ key }) => {
                if (key === "Enter")
                  window.location.assign(`accounts/${account.id}`);
              }}
              tabIndex={0}
            >
              <span className="margin--right--xxs fontColor--primary">
                {[
                  ...Account.PRODUCT_GROUPS.Checking,
                  ...Account.PRODUCT_GROUPS.Savings,
                ].includes(account.product_type)
                  ? account.balances.available
                  : account.balances.ledger}
              </span>
            </span>
            <div className="margin--right--xxs">
              <Options className="account-options">
                <Options.Option
                  icon="narmi-icon-star"
                  text={account.favorited ? "Unfavorite" : "Favorite"}
                  onClick={() => {
                    account
                      .toggleFavorited()
                      .then((response) =>
                        updateAccount(Account.deserialize(response.account)),
                      )
                      .catch(() =>
                        sendNotification({
                          type: "negative",
                          text: "Could not update favorited status",
                        }),
                      );
                  }}
                />
                <Options.Option
                  icon="narmi-icon-eye-off"
                  text={account.hidden ? "Unhide" : "Hide from view"}
                  onClick={() => {
                    account
                      .toggleHidden()
                      .then((response) =>
                        updateAccount(Account.deserialize(response.account)),
                      )
                      .catch(() =>
                        sendNotification({
                          type: "negative",
                          text: "Could not update hidden status",
                        }),
                      );
                  }}
                />
              </Options>
            </div>
          </div>
        </Row.Item>
      </Row>
    </div>
  );
};
AccountRow.propTypes = {
  account: PropTypes.instanceOf(Account),
};

const AccountGroup = ({
  collapsible = false,
  isShowBalanceAndSorting = true,
  groupName,
  groupNameInfoText,
  accountsForGroup = [],
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsible);
  if (accountsForGroup.length === 0) return null;
  return (
    <>
      <GroupHeader
        collapsible={collapsible}
        isShowBalanceAndSorting={isShowBalanceAndSorting}
        isCollapsed={isCollapsed}
        setIsCollapsed={collapsible ? setIsCollapsed : () => {}}
        groupName={groupName}
        groupNameInfoText={groupNameInfoText}
        balance={formatNumber(Account.sumBalancesForAccounts(accountsForGroup))}
      />
      {(!collapsible || !isCollapsed) && (
        <div className="account-rows">
          {accountsForGroup.map((account) => (
            <AccountRow key={account.id} account={account} />
          ))}
        </div>
      )}
    </>
  );
};
AccountGroup.propTypes = {
  collapsible: PropTypes.bool,
  isShowBalanceAndSorting: PropTypes.bool,
  groupName: PropTypes.string,
  groupNameInfoText: PropTypes.string,
  accountsForGroup: PropTypes.arrayOf(PropTypes.instanceOf(Account)),
};

const AccountBalances = () => {
  const {
    favoritedAccounts,
    unfavoritedAccounts,
    hiddenAccounts,
    isUpdatingAccounts,
  } = useContext(AccountContext);
  const only_credit_favorited_accounts = favoritedAccounts.filter(
    (account) => account.account_type === "credit",
  ).length;
  const only_deposit_favorited_accounts = favoritedAccounts.filter(
    (account) => account.account_type === "deposit",
  ).length;
  const displayGroupNameInfoText = () => {
    if (
      only_credit_favorited_accounts === favoritedAccounts.length ||
      only_deposit_favorited_accounts === favoritedAccounts.length
    ) {
      return false;
    } else {
      return true;
    }
  };
  const groupNameInfoText = displayGroupNameInfoText()
    ? "Favorites total balance does not include liabilities such as credit cards or loans."
    : "";

  return (
    <SectionCard isLoading={isUpdatingAccounts}>
      <SectionCard.Title text="Balances" testId="balances-heading" />
      <div>
        <AccountGroup
          groupName="Favorites"
          accountsForGroup={favoritedAccounts}
          groupNameInfoText={groupNameInfoText}
        />
        {Array.from(
          groupAccounts(unfavoritedAccounts),
          ([groupName, accountsForGroup]) => (
            <AccountGroup
              collapsible={groupName === "CDs"}
              groupName={groupName}
              key={groupName}
              accountsForGroup={accountsForGroup}
            />
          ),
        )}
        <AccountGroup
          collapsible
          isShowBalanceAndSorting={false}
          groupName="Hidden accounts"
          accountsForGroup={hiddenAccounts}
        />
      </div>
    </SectionCard>
  );
};
AccountBalances.propTypes = {};

export default AccountBalances;

/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

import { modules } from "byzantine";
import { memo, useEffect, useState } from "react";
import { ScrollingIframe } from "cerulean";

const PositivePay = () => {
  const { fetchHTML } = modules.positivePay.useFetchPositivePayHTML();

  const [htmlBlock, setHtmlBlock] = useState<string>();

  useEffect(() => {
    fetchHTML({
      onData: ({ positive_pay_form }) => {
        const html = atob(positive_pay_form);
        setHtmlBlock(html);
      },
    });
  }, []);

  return <ScrollingIframe title="Positive Pay" srcDoc={htmlBlock} />;
};

export default memo(PositivePay);

import Account from "./Account";
import utils from "./utils";

export const DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING: number = 10;

export type AccountFilter<A = Account> = (account: A) => boolean;

export type GroupedAccounts<A = Account> = Map<string, A[]>;

export enum SORT_BY {
  ALPHABETICALLY = "alphabetically",
  BALANCE = "by_balance",
  ACCOUNT_NUMBER = "by_account_number",
}

export enum SORT_ORDER {
  ASC = "ascending",
  DESC = "descending",
}

export interface AccountGroupSort {
  group_name: string;
  sort_by: "alphabetically" | "by_balance" | "by_account_number";
  order: "ascending" | "descending";
}

export const DEFAULT_SORT = {
  sort_by: SORT_BY.ALPHABETICALLY,
  order: SORT_ORDER.ASC,
  group_name: "",
};

export function isGroupedAccountsArray<A = Account>(
  groupedAccounts: GroupedAccounts<A> | A[],
): groupedAccounts is A[] {
  return Array.isArray(groupedAccounts);
}

export function isGroupedAccountsMap<A = Account>(
  groupedAccounts: GroupedAccounts<A> | A[],
): groupedAccounts is GroupedAccounts<A> {
  return groupedAccounts instanceof Map;
}

export function getValidTransferDestinations(
  accounts: Account[],
  achAllowsPush: boolean,
  selectedSourceAccountId?: Account["id"],
  supportsInternalTransfers: boolean = true,
  areLoansAllowed: boolean = true,
): Account[] {
  const selectedSourceAccount = accounts.find(
    (a) => a.id === selectedSourceAccountId,
  );
  return accounts.filter((a) =>
    a.isValidTransferDestinationForSource(
      selectedSourceAccount,
      achAllowsPush,
      supportsInternalTransfers,
      areLoansAllowed,
    ),
  );
}

export function getValidTransferSources(
  accounts: Account[],
  selectedDestinationAccountId?: Account["id"],
  supportsInternalTransfers: boolean = true,
): Account[] {
  const selectedDestinationAccount = accounts.find(
    (a) => a.id === selectedDestinationAccountId,
  );
  return accounts.filter((a) =>
    a.isValidTransferSourceForDestination(
      selectedDestinationAccount,
      supportsInternalTransfers,
    ),
  );
}

export function groupAccounts<
  A extends { getGroupName: () => string } = Account,
>(accounts: A[]): GroupedAccounts<A> {
  const groupedAccounts: { [name: string]: A[] } = {};

  Object.keys(Account.PRODUCT_GROUPS).forEach((groupName) => {
    accounts.forEach((account) => {
      const accountGroupName = account.getGroupName();
      if (accountGroupName !== groupName) return;
      if (!groupedAccounts[accountGroupName]) {
        groupedAccounts[accountGroupName] = [];
      }
      groupedAccounts[accountGroupName].push(account);
    });
  });

  return new Map(Object.entries(groupedAccounts));
}

export const filterAccounts = (
  accounts: Account[],
  filter: AccountFilter,
  searchTerm: string,
) => {
  return accounts.filter(filter).filter((account) => {
    if (!searchTerm || searchTerm === "") return true;
    const re = new RegExp(utils.escapeRegExp(searchTerm), "i");
    return re.test(account.getDescription());
  });
};

export const filterAndGroupAccounts = (
  accounts: Account[],
  filter: AccountFilter,
  searchTerm: string,
) => {
  const filteredAccounts = filterAccounts(accounts, filter, searchTerm);
  return groupAccounts(filteredAccounts);
};

export const sortAccountGroups = (
  groupedAccounts: GroupedAccounts,
  sorting: AccountGroupSort[],
) => {
  const sortByBalance = (a: Account, b: Account) => {
    const firstDisplayBalance = a.isCredit()
      ? a.ledgerBalanceAsFloat()
      : a.availableBalanceAsFloat();
    const secondDisplayBalance = b.isCredit()
      ? b.ledgerBalanceAsFloat()
      : b.availableBalanceAsFloat();
    if (firstDisplayBalance > secondDisplayBalance) return 1;
    if (secondDisplayBalance > firstDisplayBalance) return -1;
    return 0;
  };
  const sortByAccountNumber = (a: Account, b: Account) => {
    if (a.number > b.number) return 1;
    if (b.number > a.number) return -1;
    return 0;
  };
  const sortAlphabetically = (a: Account, b: Account) => {
    const nameA = a.getShortDescription().toLowerCase();
    const nameB = b.getShortDescription().toLowerCase();
    if (nameA > nameB) return 1;
    if (nameB > nameA) return -1;
    return 0;
  };

  const sortMap = {
    [SORT_BY.ALPHABETICALLY]: sortAlphabetically,
    [SORT_BY.BALANCE]: sortByBalance,
    [SORT_BY.ACCOUNT_NUMBER]: sortByAccountNumber,
  };
  groupedAccounts.forEach((accountsForGroup, groupName) => {
    const accountSort =
      sorting.find((obj) => obj.group_name === groupName.toLowerCase()) ||
      DEFAULT_SORT;
    accountsForGroup.sort(sortMap[accountSort.sort_by]);
    if (accountSort.order === SORT_ORDER.DESC) accountsForGroup.reverse();
  });
};

import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { useFeature } from "../features";

import { hasBusinessPermission } from "./selectors";

export const useBusinessPermissions = (permissions: API.Permission[]) => {
  return useSelector(
    (state) => permissions.map((p) => hasBusinessPermission(state, p)),
    isEqual,
  );
};

export const useBusinessFeature = (featurePermission: [string, string]) => {
  const { featureEquals } = useFeature();
  return featureEquals(...featurePermission);
};

import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { useFeature, modules } from "byzantine";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import ApiPaginatedList, {
  publishApiRefreshNotice,
} from "byzantine/src/ApiPaginatedList";
import DualApprovalRequestTable from "./DualApprovalRequestTable";
import Loading from "../Loading";
import PageNavigation from "../page_navigation/PageNavigation";
import Tabs from "../tabs/Tab";
import "./DualApproval.css";
import DualApprovalContainer, { DualApprovalPermissionsAlert } from "../DualApproval/DualApprovalContainer";

function AwaitingApprovalTab({
  loading,
  selected,
  select,
  unselect,
  updateTotal,
}) {
  const { l10n } = useLocalization();
  return (
    <ApiPaginatedList
      endpoint="approval_requests"
      queryParams={{ exclude_user_requests: true, model: "WireTransaction" }}
      keyname="approval_requests"
      pageSize={10}
    >
      {({ loading: paginationLoading, items, pagination }) => {
        if (loading || paginationLoading) {
          return <Loading />;
        }
        // this design only renders wire dual approvals, so only count those
        updateTotal(items.filter((item) => item.wire).length);
        if (items.length === 0) {
          return (
            <div className="empty-message">
              {l10n.getString("heading-no-approval-history")}
            </div>
          );
        }
        const requests = items.map((a) => DualApprovalRequest.deserialize(a));
        return (
          <div>
            <DualApprovalRequestTable
              dualApprovalRequests={requests}
              selected={selected}
              select={select}
              unselect={unselect}
            />
            <PageNavigation
              pagination={pagination}
              marginClasses="margin--all--l"
            />
          </div>
        );
      }}
    </ApiPaginatedList>
  );
}
AwaitingApprovalTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  updateTotal: PropTypes.func.isRequired,
};

function ApprovalHistoryTab() {
  const { l10n } = useLocalization();
  return (
    <ApiPaginatedList
      endpoint="responded_approval_requests"
      keyname="approval_requests"
      pageSize={10}
    >
      {({ loading: paginationLoading, items, pagination }) => {
        if (paginationLoading) {
          return <Loading />;
        }
        if (items.length === 0) {
          return (
            <div className="empty-message">
              {l10n.getString("heading-no-approval-history")}
            </div>
          );
        }
        const requests = items.map((a) => DualApprovalRequest.deserialize(a));
        return (
          <div>
            <DualApprovalRequestTable dualApprovalRequests={requests} />
            <div className="margin--all--l">
              <PageNavigation pagination={pagination} />
            </div>
          </div>
        );
      }}
    </ApiPaginatedList>
  );
}
ApprovalHistoryTab.propTypes = {};

export default function DualApprovalRequestList(props) {
  const { featureEnabled } = useFeature();
  const { organizationHasMultipleApprovalUsers, loading: orgUsersLoading, isOrgUserWithEditPerms } =
    modules.achPayments.useOrganizationHasMultipleApprovalUsers();
  const showDualApprovalsAlert = !organizationHasMultipleApprovalUsers && !orgUsersLoading && isOrgUserWithEditPerms;

  if (featureEnabled("ach_dual_approvals")) {
    return (
      <DualApprovalContainer
        approvalRequests={props.approvalRequests}
        accounts={props.accounts}
      />
    );
  }

  const { l10n } = useLocalization();
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [totalAwaitingApproval, setTotalAwaitingApproval] = React.useState(
    props.approvalRequests.length,
  );

  const dualApprovalRequests = props.approvalRequests.map((a) =>
    DualApprovalRequest.deserialize(a),
  );
  const select = (uuid) =>
    setSelected((prevSelected) => ({ ...prevSelected, [uuid]: true }));
  const unselect = (uuid) =>
    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };
      delete newSelected[uuid];
      return newSelected;
    });

  const numSelected = Object.keys(selected).length;
  let successfulRejectMessage;
  let successfulApproveMessage;

  if (numSelected >= 1) {
    successfulRejectMessage = l10n.getString("approval-notif-items-rejected", {
      numSelected,
    });
    successfulApproveMessage = l10n.getString("approval-notif-items-approved", {
      numSelected,
    });
  } else {
    successfulRejectMessage = l10n.getString(
      "approval-notif-no-items-selected",
    );
    successfulApproveMessage = l10n.getString(
      "approval-notif-no-items-selected",
    );
  }
  const approvalListHeaderActions = [
    {
      label: l10n.getString("approval-action-reject"),
      className: "ui button primary compact inverted",
      successNotification: { type: "success", text: successfulRejectMessage },
      failNotification: {
        type: "negative",
        text: l10n.getString("error-unknown"),
      },
      onSubmit: (callback) => {
        setLoading(true);
        Promise.allSettled(
          dualApprovalRequests
            .filter((dar) => selected[dar.uuid])
            .map((dar) => {
              unselect(dar.uuid);
              return dar.reject();
            }),
        )
          .then((resultArray) => {
            const someRejectActionsErrored = resultArray.some(
              (r) => r.status === "rejected",
            );
            if (someRejectActionsErrored) {
              // TODO figure out what error message to display.
              callback("An error occurred");
            } else {
              callback();
            }
          })
          .finally(() => {
            setLoading(false);
            publishApiRefreshNotice("approval_requests");
          });
      },
    },
    {
      label: l10n.getString("approval-action-approve"),
      className: "ui button primary compact",
      successNotification: { type: "success", text: successfulApproveMessage },
      failNotification: {
        type: "negative",
        text: l10n.getString("error-unknown"),
      },
      onSubmit: (callback) => {
        setLoading(true);
        Promise.allSettled(
          dualApprovalRequests
            .filter((dar) => selected[dar.uuid])
            .map((dar) => {
              unselect(dar.uuid);
              return dar.approve();
            }),
        )
          .then((resultArray) => {
            const someApproveActionsErrored = resultArray.some(
              (r) => r.status === "rejected",
            );
            if (someApproveActionsErrored) {
              // TODO figure out what error message to display.
              callback("An error occurred");
            } else {
              callback();
            }
          })
          .finally(() => {
            setLoading(false);
            publishApiRefreshNotice("approval_requests");
          });
      },
    },
  ];
  const renderApprovalHistory = () => <ApprovalHistoryTab />;
  const renderUnapprovedList = () => (
    <AwaitingApprovalTab
      dualApprovalRequests={dualApprovalRequests}
      loading={loading}
      selected={selected}
      select={select}
      unselect={unselect}
      updateTotal={setTotalAwaitingApproval}
    />
  );
  const tabPanes = [
    {
      header: {
        text: l10n.getString("heading-pending-approvals-count", {
          totalAwaitingApproval,
        }),
        actions: approvalListHeaderActions,
      },
      render: renderUnapprovedList,
    },
    {
      header: l10n.getString("heading-approval-history"),
      render: renderApprovalHistory,
    },
  ];
  return (
    <div className="DualApprovalRequestList">
      {showDualApprovalsAlert ?
        <DualApprovalPermissionsAlert />
      : null}
      <Tabs panes={tabPanes} />
    </div>
  );
}

DualApprovalRequestList.propTypes = {
  approvalRequests: PropTypes.array.isRequired,
  currentOrganizationUser: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
};

import { useEffect } from "react";

import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { selectUser } from "../../businessLogic/entities/selectors/user.selectors";
import { usePrevious } from "../../hooks";

import type { NetworkPayload } from "../request/request.types";

export type CacheKey = Brand<string, "requestCacheKey"> | -1;

type CacheItem = {
  promise: Promise<unknown>;
  createdAt: DateTime;
};

const requestPromisesCache = new Map<CacheKey, CacheItem>();

const getCacheKey = (
  url: NetworkPayload["url"],
  options: NetworkPayload["options"],
) => {
  if (options.method !== "GET" || options.disableCaching === true) {
    return -1;
  }
  const cacheKey = `${
    options.method
  }:${url}:auth=${!options.disableAuth}` as CacheKey;
  return cacheKey;
};

function addItem<D>(cacheKey: CacheKey, response: Promise<D>) {
  if (cacheKey === -1) {
    return;
  }
  requestPromisesCache.set(cacheKey, {
    promise: response,
    createdAt: DateTime.now(),
  });
}

function checkCache<D>(cacheKey: CacheKey, ttlMilliseconds: number) {
  if (cacheKey === -1) {
    return false;
  }
  const now = DateTime.now();
  const cacheItem = requestPromisesCache.get(cacheKey);

  if (!cacheItem) {
    return false;
  }

  const timeDelta = now.diff(cacheItem.createdAt).milliseconds;

  if (timeDelta < ttlMilliseconds) {
    return cacheItem.promise as Promise<D>;
  }

  requestPromisesCache.delete(cacheKey);
  return false;
}

function clearCache() {
  requestPromisesCache.clear();
}

export const useInvalidateRequestCache = () => {
  const user = useSelector(selectUser);
  const previousUser = usePrevious(user);

  const userId = user?.id;
  const previousUserId = previousUser?.id;

  useEffect(() => {
    if (userId === previousUserId || previousUserId === undefined) {
      return;
    }
    clearCache();
  }, [userId, previousUserId]);
};

export default {
  getCacheKey,
  addItem,
  checkCache,
  useInvalidateRequestCache,
};

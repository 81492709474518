import { DateTime } from "luxon";

import { FREQUENCIES } from "../../../../Recurrence";

import type {
  ACHPaymentFormType,
  LimitsForDisplayType,
} from "./ACHPayment.form";

export const getFluentIdForSECCode = (secCode: API.ACHPayment.SECCode) => {
  switch (secCode) {
    case "CCD":
      return {
        id: "ccd-payment-description",
        vars: {},
        fallback:
          "A CCD entry can be either a buyer-initiated or seller-initiated transaction used to move funds between the buyer’s and seller’s financial institution accounts. It is also used by companies to move funds from outlying depository locations to a central bank account. A CCD entry supports including one addenda and is typically used when paying for one item.",
      } as const;
    case "CIE":
      return {
        id: "cie-payment-description",
        vars: {},
        fallback:
          "A CIE entry is a credit entry initiated on behalf of, and upon the instruction of, a consumer to transfer funds to a non-consumer Receiver.",
      } as const;
    case "CTX":
      return {
        id: "ctx-payment-description",
        vars: {},
        fallback:
          "A CTX entry is similar to a CCD entry, but it supports including multiple addenda.",
      } as const;
    case "IAT":
      return {
        id: "iat-payment-description",
        vars: {},
        fallback:
          "An IAT entry is a debit or credit entry that is part of a payment transaction involving a financial agency’s office that is not located in the territorial jurisdiction of the United States.",
      } as const;
    case "PPD":
      return {
        id: "ppd-payment-description",
        vars: {},
        fallback:
          "A PPD entry is a credit or debit entry originated by an organization to a consumer’s account, based on standing or single-entry authorization from that consumer. A PPD entry supports including one addenda.",
      } as const;
    case "WEB":
      return {
        id: "web-payment-description",
        vars: {},
        fallback:
          "WEB entries are entries transmitted to a consumer Receiver’s account. These entries can be either debits or credits. WEB entries may be either recurring or non-recurring transactions.",
      } as const;
    default:
      return undefined;
  }
};

// takes form values and spits out the values to be used by generateRecurrenceRuleString
export const formatValuesForRecurrenceString = (values: ACHPaymentFormType) => {
  const { recurringRule } = values;

  let formattedStartDate: string;

  if (values.startDate.includes("-")) {
    formattedStartDate = values.startDate;
  } else {
    formattedStartDate = DateTime.fromFormat(
      values.startDate,
      "M/d/yyyy",
    ).toFormat("yyyy-MM-dd");
  }

  const formattedTodayDate = DateTime.now().toFormat("yyyy-MM-dd");

  const formattedUntilDate =
    recurringRule?.ends === "on" && recurringRule.untilDate
      ? DateTime.fromFormat(recurringRule.untilDate, "M/d/yyyy").toFormat(
          "yyyy-MM-dd",
        )
      : null;

  const count =
    recurringRule?.ends === "after" && Number(recurringRule.count) > 0
      ? recurringRule.count
      : null;

  const getFrequency = () => {
    const isToday = formattedStartDate === formattedTodayDate;
    if (recurringRule?.isVisible && recurringRule.frequency) {
      return recurringRule.frequency;
    }
    // if recurringRule is not visible, and it is sent on a future date,
    // set frequency as "ONCE"
    return !isToday ? FREQUENCIES.ONCE : "";
  };

  const frequency = getFrequency();

  return {
    frequency,
    startDate: formattedStartDate,
    untilDate: formattedUntilDate,
    count,
  };
};

export const getStartDate = (achNextCutoff?: number) => {
  const achNextCutoffDate = achNextCutoff
    ? new Date(achNextCutoff * 1000)
    : new Date();
  return new Intl.DateTimeFormat("en-us", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(achNextCutoffDate);
};

export const getIsOneTimePaymentForToday = (
  values: ACHPaymentFormType,
  achNextCutoff?: number,
) => {
  // start date can be 2 different formats
  const parseDate = (dateString: string) => {
    const formats = ["MM/dd/yyyy", "yyyy-MM-dd"];
    return (
      formats
        .map((format) => DateTime.fromFormat(dateString, format))
        .find((parsed) => parsed.isValid) || null
    );
  };

  const formattedStartDate = parseDate(values.startDate);
  const minDate = parseDate(getStartDate(achNextCutoff)) || DateTime.now();
  const isStartDateToday = Boolean(formattedStartDate?.hasSame(minDate, "day"));
  return !values.recurringRule?.isVisible && isStartDateToday;
};

export const getImmediateLimitsByTransactionTypeAsFloat = (
  limits: LimitsForDisplayType,
) => {
  return Object.keys(limits).reduce(
    (acc: { [key: string]: number }, transactionType) => {
      if (
        // only care about ACH-related limits
        !["immediate_rdc", "wire", "organization_user_wire"].includes(
          transactionType,
        )
      ) {
        // limits[transaction] has numbers (which represent days) and "amount_available" as keys
        // amount_available key is relevant for validation
        const amountAvailable = limits[transactionType].amount_available;
        if (amountAvailable) {
          acc[transactionType] = parseFloat(amountAvailable);
        }
      }
      return acc;
    },
    {},
  );
};

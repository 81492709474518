import ApiHttp from "byzantine/src/ApiHttp";
import type MembershipType from "byzantine/src/Membership";
import Membership from "byzantine/src/Membership";
import { Button, ContentCard, useNotificationContext } from "cerulean";
import { useState } from "react";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import LinkInternalAccountModal from "./LinkInternalAccountModal";
import styles from "./LinkedAccountMembershipsCard.module.scss";
import MembershipInfoRow from "./MembershipInfoRow";

interface LinkedAccountMembershipsCardProps {
  initialMemberships: MembershipType[];
}

interface MembershipFetchResponse {
  memberships: string[];
}

const LinkedAccountMembershipsCard = ({
  initialMemberships,
}: LinkedAccountMembershipsCardProps) => {
  const [memberships, setMemberships] = useState(initialMemberships);
  const [linkInternalAccountModalOpen, setLinkInternalAccountModalOpen] =
    useState(false);
  const { sendNotification } = useNotificationContext();

  const { currentUser } = useCurrentUser();
  const userPrimaryMembershipId: string =
    currentUser?.institution_user_identifier
      ? currentUser.institution_user_identifier.toString()
      : "";

  const removeMembership = (id: string) => {
    const membershipsUpdated = structuredClone(memberships);
    const idx = membershipsUpdated.findIndex(
      (m: MembershipType) => m.id === id,
    );
    if (idx < 0) return;
    membershipsUpdated.splice(idx, 1);
    setMemberships(membershipsUpdated);
  };

  const fetchMemberships = () => {
    ApiHttp.fetch("accounts/memberships", { method: "GET" })
      .then((res: MembershipFetchResponse) => {
        const membershipsArray = res.memberships.map((membershipId: string) =>
          Membership.deserialize(membershipId),
        );
        setMemberships(membershipsArray);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: "There was an error fetching linked internal accounts.",
        });
      });
  };

  return (
    <>
      <ContentCard>
        <div className={styles["memberships-card-title"]}>
          <h4 className="nds-sans fontSize--heading5">Membership</h4>
          <Button
            kind="plain"
            label="Link another account"
            size="m"
            onClick={setLinkInternalAccountModalOpen}
          />
        </div>
        <MembershipInfoRow
          name="Primary account number"
          membership={new Membership({ id: userPrimaryMembershipId })}
        />
        {memberships.map((membership) => (
          <MembershipInfoRow
            key={membership.id}
            name="Account number"
            membership={membership}
            removeMembership={removeMembership}
          />
        ))}
      </ContentCard>
      <LinkInternalAccountModal
        linkInternalAccountModalOpen={linkInternalAccountModalOpen}
        setLinkInternalAccountModalOpen={setLinkInternalAccountModalOpen}
        fetchMemberships={fetchMemberships}
      />
    </>
  );
};

export default LinkedAccountMembershipsCard;

import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  ProgressButtons,
  Tooltip,
  ResponsiveFlex,
  Select,
  TextInput,
  Toggle,
  ContentCard,
  Row,
  catcat as cc,
} from "cerulean";
import { modules, entities } from "byzantine";
import { Features } from "byzantine/src/Feature";
import type Account from "byzantine/src/Account";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import {
  type LimitsForDisplayType,
} from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import filters from "byzantine/src/filters";
import { useLocalization } from "@fluent/react";
import {
  Button,
  TruncatedAccount,
  DisabledShim,
  DateInput,
  Radio,
} from "@narmi/design_system";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import * as formAdapters from "../../../src/adapters";
import AccountContext from "../../contexts/AccountContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import AccountSelector from "../../AccountSelector";
import PreviewCard from "../../PreviewCard/PreviewCard";
import FrequencySelector from "../FrequencySelector";
import { useInstitutionSettings } from "../../contexts/InstitutionSettingsContext";
import {
  ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE,
  ACH_PAYMENT_REVIEW_ROUTE,
  ACH_PAYMENT_ROUTE,
} from "./ACHPaymentRoutes";
import SECModal from "./SECDialog";
import LimitsAndDisclosuresDialog from "./LimitsAndDisclosuresDialog";
import RecipientDrawer, {
  DRAWER_TYPES,
} from "./RecipientDrawer/RecipientDrawer";
import { getTotalSteps } from "./RecipientScreen";
import styles from "./PaymentScreen.module.scss";

export const TransactionTypeSection = ({
  codes,
}: {
  codes: API.ACHPayment.SECCode[];
}) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const [showSECDialog, setShowSECDialog] = useState(false);
  const form = modules.achPayments.ACHPaymentForm.useForm();

  const toggleSECDialog = () => {
    setShowSECDialog(!showSECDialog);
  };

  const achScheduledEnabled = features.ach_payments_scheduled;

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {achScheduledEnabled
            ? l10n.getString("add-recipient-drawer-additional-details-title")
            : l10n.getString("ach-payment-transaction-title")}
        </h4>
        <Select
          label={
            achScheduledEnabled
              ? l10n.getString("ach-payment-transaction-title")
              : l10n.getString("ach-payment-sec-code")
          }
          {...formAdapters.select(form, "secCode")}
        >
          {codes.map((code, index) => (
            <Select.Item key={`account_type_${index}`} value={code}>
              {code}
            </Select.Item>
          ))}
        </Select>
        <Button
          label={l10n.getString("ach-payment-view-transaction-types-cta")}
          kind="plain"
          size="s"
          onClick={toggleSECDialog}
        />
        {achScheduledEnabled ? (
          <TextInput
            label={l10n.getString("ach-payment-entry-desc")}
            maxLength={10}
            {...formAdapters.input(form, "entryDesc")}
          />
        ) : null}
      </ResponsiveFlex>
      <SECModal
        isOpen={showSECDialog}
        handleClose={toggleSECDialog}
        codes={codes}
      />
    </>
  );
};

const PaymentDetailsSection = ({
  limits,
}: {
  limits: LimitsForDisplayType;
}) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const { accounts } = useContext(AccountContext);
  const [showLimitsDialog, setShowLimitsDialog] = useState(false);
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const achScheduledEnabled = features.ach_payments_scheduled;
  const { setFieldValue, values } = form;
  const { recurringRule } = values;
  const institution = useInstitutionSettings();
  const minDate = modules.achPayments.getStartDate(institution.ach_next_cutoff);

  const { onChange: startDateOnChange, ...restOfStartDateFieldProps } =
    formAdapters.fieldWithOnChange(form, "startDate");

  const handleStartDateChange = (value: string) => {
    const startDateTime = DateTime.fromFormat(value, "M/d/yyyy");
    const formattedStartDateTimeString = startDateTime.toFormat("MM/dd/yyyy");

    if (
      recurringRule?.untilDate &&
      startDateTime >
        DateTime.fromFormat(recurringRule?.untilDate, "MM/dd/yyyy")
    ) {
      const formattedUntilDate = startDateTime
        .plus({ years: 1 })
        .toFormat("MM/dd/yyyy");
      setFieldValue("recurringRule.untilDate", formattedUntilDate);
    }
    startDateOnChange(formattedStartDateTimeString);
  };

  const validAccounts = accounts.filter((account: Account) =>
    account.isValidAchTransferSource(),
  );

  const toggleLimitsDialog = () => {
    setShowLimitsDialog(!showLimitsDialog);
  };

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {l10n.getString("ach-payment-payment-details-title")}
        </h4>
        <AccountSelector
          field="fromAccount"
          label="From"
          accounts={validAccounts}
          isDestination={true}
          showAddExternalAccountLink={false}
          {...formAdapters.select(form, "fromAccount")}
        />
        <TextInput
          label="Amount"
          {...formAdapters.amountInput(form, "amount")}
        />
        <Button
          label={l10n.getString("ach-payment-view-limits-cta")}
          kind="plain"
          size="s"
          onClick={toggleLimitsDialog}
        />
        {achScheduledEnabled ? (
          <DateInput
            minDate={minDate}
            dateFormat={"m/d/Y"}
            altInput={true}
            altFormat={"m/d/Y"}
            field="date"
            label={l10n.getString("label-date-once")}
            useIsoOnChange={false}
            onChange={handleStartDateChange}
            {...restOfStartDateFieldProps}
          />
        ) : null}
      </ResponsiveFlex>
      <LimitsAndDisclosuresDialog
        isOpen={showLimitsDialog}
        handleClose={toggleLimitsDialog}
        limits={limits}
      />
    </>
  );
};

const RecipientCardSection = () => {
  const { l10n } = useLocalization();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const hasManageRecipientPermission =
    modules.achPayments.useManageRecipientPermission();
  const accountNumber = selectedRecipient?.ach_destination?.account_number;
  const bankName = selectedRecipient?.ach_destination?.institution_name;
  const lastFour =
    accountNumber && accountNumber.substring(accountNumber.length - 4);
  const { nickname } = selectedRecipient as API.Recipient;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const PreviewCardActionButton = (
    <Button kind="plain" label="Edit" onClick={toggleDrawer} />
  );
  const PreviewCardIconContent = (
    <TruncatedAccount lastFour={lastFour} name={bankName} />
  );

  const getPreviewCardHeader = () => {
    let header = "";
    if (selectedRecipient) {
      header += selectedRecipient.name;
    }
    return header;
  };

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {l10n.getString("ach-payment-recipient-title")}
        </h4>
        <PreviewCard
          header={getPreviewCardHeader()}
          subtleHeader={nickname ? ` (${selectedRecipient?.nickname})` : ""}
          iconName="narmi-icon-bank"
          iconContent={PreviewCardIconContent}
          {...(hasManageRecipientPermission && {
            actionButton: PreviewCardActionButton,
          })}
        />
      </ResponsiveFlex>
      <RecipientDrawer
        isOpen={isDrawerOpen}
        handleClose={toggleDrawer}
        recipientId={selectedRecipient?.id}
        fromACHFlow={false}
        drawerType={DRAWER_TYPES.edit}
      />
    </>
  );
};

const RepeatPaymentCard = ({ totalPayments }: { totalPayments: number }) => {
  const { l10n } = useLocalization();
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const { values, setFieldValue } = form;
  const { recurringRule } = values;
  const userToggledOnOption = recurringRule?.ends === "on";
  const userToggledAfterOption = recurringRule?.ends === "after";

  const { onChange: frequencyOnChange, ...restOfFrequencyFieldProps } =
    formAdapters.fieldWithOnChange(form, "recurringRule.frequency");

  const { onChange: countOnChange, ...restOfCountFieldProps } =
    formAdapters.fieldWithOnChange(form, "recurringRule.count");

  const { onChange: untilDateOnChange, ...restOfUntilDateFieldProps } =
    formAdapters.fieldWithOnChange(form, "recurringRule.untilDate");

  const { onChange: endsOnChange, ...restOfEndFieldProps } =
    formAdapters.fieldWithOnChange(form, "recurringRule.ends");

  const handleFrequencyChange = (value: string) => {
    // only when user selects a frequency for the first time, do we prepopulate
    // "ends" with "never" value
    if (value && !recurringRule?.ends) {
      setFieldValue("recurringRule.ends", "never");
    }
    frequencyOnChange(value);
  };

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericalValue = Number(value);

    if (!numericalValue) {
      countOnChange("");
    } else if (numericalValue > 100) {
      countOnChange(value.slice(0, 2));
    } else if (numericalValue < 100) {
      countOnChange(value);
    }
  };

  const handleUntilDateChange = (value: string) => {
    const untilDateTime = DateTime.fromFormat(value, "M/d/yyyy");
    const formattedUntilDateTimeString = untilDateTime.toFormat("MM/dd/yyyy");
    untilDateOnChange(formattedUntilDateTimeString);
  };

  const handleEndChange = (value: string) => {
    endsOnChange(value);
  };

  const calculateTotalAmount = () => {
    const totalAmountInCents = (totalPayments * values.amount) as Cents;
    return filters.currency(totalAmountInCents);
  };

  return (
    <ContentCard kind="bordered">
      <div className={styles.contentCardContainer}>
        <ResponsiveFlex gapSize="m">
          <FrequencySelector
            label="Frequency"
            excludedFrequencies={[FREQUENCIES.ONCE]}
            onChange={handleFrequencyChange}
            {...restOfFrequencyFieldProps}
          />
        </ResponsiveFlex>

        <DisabledShim isDisabled={!recurringRule?.frequency}>
          <ResponsiveFlex gapSize="m">
            <h4 className="fontSize--m nds-sans margin--top--xl">Ends</h4>
            <Radio
              disabled={!recurringRule?.frequency}
              checked={recurringRule?.ends === "never"}
              onCheck={handleEndChange}
              {...restOfEndFieldProps}
              value="never"
            >
              {l10n.getString("ach-payment-never")}
            </Radio>
            <div className={cc(styles.radioInput, styles.dateInput)}>
              <Radio
                disabled={!recurringRule?.frequency}
                checked={recurringRule?.ends === "on"}
                onCheck={handleEndChange}
                {...restOfEndFieldProps}
                value="on"
              >
                {l10n.getString("ach-payment-on")}{" "}
                <DateInput
                  disabled={!userToggledOnOption}
                  minDate={values.startDate}
                  dateFormat={"m/d/Y"}
                  altInput={true}
                  altFormat={"m/d/Y"}
                  field="date"
                  useIsoOnChange={false}
                  onChange={handleUntilDateChange}
                  {...restOfUntilDateFieldProps}
                />
              </Radio>
            </div>
            <div className={cc(styles.radioInput, styles.countInput)}>
              <Radio
                disabled={!recurringRule?.frequency}
                checked={recurringRule?.ends === "after"}
                onCheck={handleEndChange}
                {...restOfEndFieldProps}
                value="after"
              >
                {l10n.getString("ach-payment-after")}
                <TextInput
                  disabled={!userToggledAfterOption}
                  type="text"
                  onChange={handleCountChange}
                  {...restOfCountFieldProps}
                />
                {l10n.getString("ach-payment-payments")}
              </Radio>
            </div>
          </ResponsiveFlex>
          {(userToggledAfterOption && recurringRule.count) ||
          userToggledOnOption ? (
            <div className={styles.totalCountSection}>
              <Row>
                <Row.Item>
                  {`${totalPayments} ${l10n.getString(
                    "ach-payment-total-payments",
                  )}:`}
                </Row.Item>
                <Row.Item shrink>{calculateTotalAmount()}</Row.Item>
              </Row>
            </div>
          ) : null}
        </DisabledShim>
      </div>
    </ContentCard>
  );
};

const PaymentScreen = ({ limits }: { limits: LimitsForDisplayType }) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const navigate = useNavigate();
  const { establishSudo } = useSudoContext();
  const codes = modules.achPayments.useSECCodes();
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const { onContinue } = modules.achPayments.usePaymentDetails();
  const { calculateTotalPayments } =
    modules.achPayments.useCalculateTotalPayments();
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const { values } = form;
  const { recurringRule } = values;
  const [showRepeatPayment, setShowRepeatPayment] = useState(
    values.recurringRule?.isVisible,
  );
  const [totalPayments, setTotalPayments] = useState(0);

  if (!selectedRecipient) return null;

  const { onChange, ...restOfFieldProps } = formAdapters.fieldWithOnChange(
    form,
    "recurringRule.isVisible",
  );

  const toggleRepeatPayment = () => {
    setShowRepeatPayment(!showRepeatPayment);
  };

  const handleOnChange = (isVisible: boolean) => {
    toggleRepeatPayment();
    onChange(isVisible);
  };

  useEffect(() => {
    const payments = calculateTotalPayments();
    setTotalPayments(payments);
  }, [recurringRule, values.startDate]);

  const handleClickNext = () => {
    const achScheduledEnabled = features.ach_payments_scheduled;
    const route = achScheduledEnabled
      ? ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE
      : ACH_PAYMENT_REVIEW_ROUTE;

    const handleNext = () => {
      navigate(`/${ACH_PAYMENT_ROUTE}/${route}`);
    };

    const handleTrySudo = () => {
      // if ach scheduled is enabled, don't try sudo on this step
      if (!achScheduledEnabled) {
        establishSudo(`/${ACH_PAYMENT_ROUTE}/${route}`);
      }
    };

    onContinue(handleNext, handleTrySudo);
  };

  return (
    <>
      <ResponsiveFlex gapSize="xl">
        <HeaderWithSteps
          headerText={l10n.getString("ach-payment-payment-title")}
          step={2}
          totalSteps={getTotalSteps()}
          icon={
            <Tooltip text={l10n.getString("ach-payment-payment-title-tooltip")}>
              <i
                className="narmi-icon-info"
                style={{
                  fontSize: "var(--font-size-s)",
                }}
              />
            </Tooltip>
          }
        />

        <RecipientCardSection />

        <PaymentDetailsSection limits={limits} />

        {features.ach_payments_scheduled ? (
          <Row gapSize="s">
            <Toggle
              onChange={handleOnChange}
              labelledBy="repeat-payment-label"
              isActive={restOfFieldProps.value}
              {...restOfFieldProps}
            />
            <label id="repeat-payment-label" className="fontWeight--bold">
              {l10n.getString("ach-payment-repeat")}
            </label>
          </Row>
        ) : (
          <TransactionTypeSection codes={codes} />
        )}

        {showRepeatPayment ? (
          <RepeatPaymentCard totalPayments={totalPayments} />
        ) : null}

        <ProgressButtons
          backLabel={l10n.getString("button-back")}
          nextLabel={l10n.getString("button-next")}
          onBack={() => navigate(`/${ACH_PAYMENT_ROUTE}`)}
          onNext={handleClickNext}
        />
      </ResponsiveFlex>
    </>
  );
};

export default PaymentScreen;

/* eslint-disable import/prefer-default-export */
import EventEmitter from "events";
import { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

type Props = {
  open: boolean;
  closeModal: () => void;
};

const modalEventBus = new EventEmitter();

export const useEnsureSingleVisibleModal = ({ open, closeModal }: Props) => {
  const modalId = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (!open) {
      return;
    }
    modalEventBus.emit("open", modalId);
  }, [open]);

  useEffect(() => {
    const eventHandler = (openModalId: string) => {
      if (openModalId !== modalId) {
        closeModal();
      }
    };
    modalEventBus.on("open", eventHandler);

    return () => {
      modalEventBus.removeListener("open", eventHandler);
    };
  });
};

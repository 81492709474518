import { Navigate, Routes, Route } from "react-router-dom";
import { Dialog } from "cerulean";
import AppDownloadDialogBody from "./AppDownloadDialogBody";

interface DashboardDialogProps {
  isOpen: boolean;
  androidUrl: string;
  appleUrl: string;
  fiName: string;
  closeDialog: () => void;
}

const DashboardDialog = ({
  isOpen,
  fiName,
  androidUrl,
  appleUrl,
  closeDialog,
}: DashboardDialogProps) => (
  <Dialog
    isOpen={isOpen}
    onUserDismiss={closeDialog}
    title="Download the mobile app"
  >
    <div className="margin--top--s" />
    <Routes>
      <Route
        path="/app_download"
        element={
          <AppDownloadDialogBody
            fiName={fiName}
            androidUrl={androidUrl}
            appleUrl={appleUrl}
          />
        }
      />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  </Dialog>
);

export default DashboardDialog;

import { MouseEventHandler, useCallback, useEffect } from "react";
import { modules } from "byzantine";
import { useLocalization } from "@fluent/react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  ResponsiveFlex,
  ProgressButtons,
  Row,
  DateInput,
  Tag,
  Tooltip
} from "cerulean";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import { DateTime } from "luxon";

import FrequencySelector from "../FrequencySelector";
import { useSudoContext } from "../../SudoContext";
import { TOTAL_STEPS } from "./AccountSelectScreen";
import HeaderWithSteps from "../../HeaderWithSteps";
import * as formAdapters from "../../../src/adapters";
import styles from "./TransferDetails.module.scss";
import { TRANSFER_REVIEW_ROUTE, TRANSFERS_ROUTE } from "./TransferRoutes";
import { moneyFormatter } from "./TransferReview";
import TransferFeesDialog from "./TransferFeesDialog";

function DeliverySpeedSelect({label, description, isSelected, onClick, tag}: {label: string, description: string, isSelected: boolean, onClick: MouseEventHandler, tag?: string | undefined}){
  return <div className={`${styles.deliverySpeedSelectCard} ${isSelected ? styles.deliverySpeedSelectCardSelected : ""}`} onClick={onClick}>
    <Row gapSize="xs">
      <Row.Item shrink>
        <div className={`${styles.cardTitleRadio} ${isSelected ? styles["cardTitleRadio--checked"]: ""}`} role="presentation"/>
      </Row.Item>
      <Row.Item>
      <div className={styles.cardTitleInline}>
        <p className={`padding--bottom--s ${styles.cardTitleInline}`}>{label}</p>
        {tag && <Tag kind="subdued-secondary" label={tag}/>}
      </div>
        <div className="fontSize--s fontColor--secondary"><p>{description}</p></div>
      </Row.Item>
    </Row>
  </div>
}

const DeliverySpeedSelection = () => {
  const form = modules.transfers.TransferForm.useForm();
  const fee = modules.transfers.useCalculateInstantTransferFee();
  const { l10n } = useLocalization();

  function setInstant(value: boolean) {
    if (value) {
      form.setFieldValue("frequency", undefined, true);
      form.setFieldValue("date", undefined, true);
    }
    form.setFieldValue("instant", value, true);
  }

  return (
    <div className="padding--bottom--s">
      <h4 className="fontSize--m nds-sans padding--top--xs padding--bottom--s">
        {l10n.getString("header-delivery-speed")} <Tooltip text={l10n.getString("tooltip-scheduled-instant-unavailable")}>
          <span className="narmi-icon-info"/>
        </Tooltip>
      </h4>
      <div className={styles.deliverySpeedSelectContainer}>
        <DeliverySpeedSelect 
          label={l10n.getString("label-instant-speed")}
          description={fee === 0 ? l10n.getString("label-no-fee") : moneyFormatter.format(fee)}
          tag={l10n.getString("label-new")}
          isSelected={!!form.values.instant}
          onClick={() => setInstant(true)}
        />
        <DeliverySpeedSelect 
          label={l10n.getString("label-ach-speed")}
          description={l10n.getString("label-no-fee")}
          isSelected={!form.values.instant}
          onClick={() => setInstant(false)}
        />
      </div>
      <TransferFeesDialog />
    </div>
  );
};

const ScheduledTransferFields = () => {
  const form = modules.transfers.TransferForm.useForm();
  const isRecurring = form.values.frequency !== FREQUENCIES.ONCE;
  const { l10n } = useLocalization();

  function setDate(value: string) {
    form.setFieldValue("date", value, true);
  }

  useEffect(() => {
    setDate(DateTime.now().toFormat("M/d/yyyy"));
    form.setFieldValue("frequency", FREQUENCIES.ONCE, true);
  }, []);

  return (
    <div>
      <div className="padding--bottom">
        <FrequencySelector
          label={l10n.getString("label-frequency")}
          {...formAdapters.select(form, "frequency")}
        />
      </div>

      <DateInput
        minDate={
          !isRecurring
            ? "today"
            : DateTime.now().plus({ days: 1 }).toFormat("M/d/yyyy")
        }
        dateFormat={"m/d/Y"}
        altInput={true}
        altFormat={"m/d/Y"}
        useIsoOnChange={false}
        label={l10n.getString("label-date")}
        onChange={(val: string) => {
          setDate(val);
        }}
        value={form.values.date}
      />
    </div>
  );
};

export default function TransferDetails() {
  const { useIsInstantEligible, useIsExternalTransfer } = modules.transfers;
  const { useSetInitialSteps } = useSudoContext();
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const form = modules.transfers.TransferForm.useForm();
  const eligible = useIsInstantEligible();
  const requiresSudo = useIsExternalTransfer();
  const { establishSudo } = useSudoContext();
  useSetInitialSteps(TOTAL_STEPS);

  const shouldShowScheduling = !eligible || !form.values.instant;

  const handleSubmit = useCallback(() => {
    if(requiresSudo){
      establishSudo(`/${TRANSFERS_ROUTE}/${TRANSFER_REVIEW_ROUTE}`);
    }
    else{
      navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_REVIEW_ROUTE}`);
    }
  }, [requiresSudo]);

  useEffect(() => {
    form.submitForm().then(({success}) => {
      if (!success) {
        navigate(`/${TRANSFERS_ROUTE}`);
      }
    })
  }, [])

  return (
    <ResponsiveFlex gapSize="m">
      <HeaderWithSteps
        headerText={l10n.getString("header-make-a-transfer")}
        step={2}
        totalSteps={TOTAL_STEPS}
      />
      {eligible && <DeliverySpeedSelection />}

      <h4 className="fontSize--m nds-sans padding--top--xs padding--bottom--s">
        {l10n.getString("header-transfer-additional-details")}
      </h4>
      {shouldShowScheduling && <ScheduledTransferFields />}
      <TextInput 
        label={l10n.getString("label-memo-optional")}
        endContent={form.values.instant ? <Tooltip text={l10n.getString("tooltip-transfers-memo-included")}>
          <span className="narmi-icon-info"></span>
        </Tooltip> : null}
        {...formAdapters.input(form, "memo")} 
      />

      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("button-next")}
        onBack={() => {
          navigate(`/${TRANSFERS_ROUTE}`);
        }}
        onNext={handleSubmit}
      />
    </ResponsiveFlex>
  );
}

import React, { useContext, useMemo } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import {
  getValidTransferDestinations,
  getValidTransferSources,
} from "byzantine/src/AccountUtils";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import { DateInput, TextInput } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import AmountTextInput from "../form/AmountTextInput";
import AccountContext from "../contexts/AccountContext";
import AccountSelector from "../AccountSelector";
import FrequencySelector from "./FrequencySelector";
import LimitsDialog from "./LimitsDialog";
import LoanAmountField from "./LoanAmountField";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import utils from "../../utils";
import { useCurrentUser } from "../contexts/CurrentUserContext";

export const TRANSFER_FORM_TYPES = Object.freeze({
  SIMPLE_TRANSFER__NEW: 0,
  SIMPLE_TRANSFER__EDIT: 1,
  LOAN_PAY_BY_CARD__BANK: 2,
  LOAN_OPTIONALITY__PRESET: 3,
  LOAN_OPTIONALITY__CUSTOM: 4,
  LOAN_OPTIONALITY__PRINCIPAL: 5,
});

const DEFAULT_DISPLAY_OPTIONS = Object.freeze({
  isEditedScheduledTransfer: false,
  isLoanPaymentFlow: false,
  useAmountSelector: false,
  showMemoField: true,
  isAmountFieldDisabled: false,
  iotTransfersOnly: false,
  showExternalAccountsInSourceDropdown: true,
});

const getDisplayOptionsForTransferFormType = (transferFormType) => {
  switch (transferFormType) {
    case TRANSFER_FORM_TYPES.SIMPLE_TRANSFER__NEW:
      return DEFAULT_DISPLAY_OPTIONS;
    case TRANSFER_FORM_TYPES.SIMPLE_TRANSFER__EDIT:
      return {
        ...DEFAULT_DISPLAY_OPTIONS,
        isEditedScheduledTransfer: true,
      };
    case TRANSFER_FORM_TYPES.LOAN_PAY_BY_CARD__BANK:
      return {
        ...DEFAULT_DISPLAY_OPTIONS,
        isLoanPaymentFlow: true,
        useAmountSelector: true,
      };
    case TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__PRESET:
      return {
        ...DEFAULT_DISPLAY_OPTIONS,
        isLoanPaymentFlow: true,
        showMemoField: false,
        isAmountFieldDisabled: true,
        showExternalAccountsInSourceDropdown: false,
      };
    case TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__CUSTOM:
      return {
        ...DEFAULT_DISPLAY_OPTIONS,
        isLoanPaymentFlow: true,
        showMemoField: false,
        showExternalAccountsInSourceDropdown: false,
      };
    case TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__PRINCIPAL:
      return {
        ...DEFAULT_DISPLAY_OPTIONS,
        isLoanPaymentFlow: true,
        showMemoField: false,
        iotTransfersOnly: true,
        showExternalAccountsInSourceDropdown: false,
      };
    default:
      return DEFAULT_DISPLAY_OPTIONS;
  }
};

const SimpleTransferFields = ({ data, limits = {}, formType }) => {
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const isBusiness = currentUser?.isBusiness();
  const { accounts } = useContext(AccountContext);
  const { ach_use_available_balance_for_scheduled_push, ach_allows_push } =
    useContext(InstitutionSettingsContext);

  const {
    internal_transfers: supportsInternalTransfers,
    enable_loans_in_transfer_flow,
  } = useContext(UserFeaturesContext);

  const {
    isEditedScheduledTransfer,
    isLoanPaymentFlow,
    useAmountSelector,
    isAmountFieldDisabled,
    showMemoField: typeSupportsMemo,
    iotTransfersOnly,
    showExternalAccountsInSourceDropdown,
  } = getDisplayOptionsForTransferFormType(formType);
  const showMemoField =
    typeSupportsMemo && !currentUser.features.transfers_disable_all_memos;

  const isRecurring = data?.frequency !== FREQUENCIES.ONCE;
  const immediateLimitsByTransactionType = {}; // what is immediately available for transaction type, used for validation
  const limitsForDisplay = {}; // amount and available amount per time period for each transaction type
  Object.keys(limits).forEach((transaction_type) => {
    if (
      ["immediate_rdc", "wire", "organization_user_wire"].includes(
        transaction_type,
      )
    )
      return; // only care about ACH-related limits
    limitsForDisplay[transaction_type] = {};

    Object.keys(limits[transaction_type]).forEach((key) => {
      // limits[transaction] has numbers (which represent days) and "amount_available" as keys
      if (Number(key)) {
        // only want number keys for display
        limitsForDisplay[transaction_type][key] = limits[transaction_type][key];
      } else {
        // amount_available key is relevant for validation
        immediateLimitsByTransactionType[transaction_type] =
          limits[transaction_type][key];
      }
    });
  });

  const validateTransferAmount = (value, allFields) => {
    if (!value) {
      return l10n.getString("errorRequired");
    }
    const { from_account_id, to_account_id, frequency, date } = allFields;
    const transferAmountAsFloat = utils.parseValueAsFloat(value);
    const limitsAsFloat = {};
    Object.entries(immediateLimitsByTransactionType).forEach(([key, v]) => {
      limitsAsFloat[key] = parseFloat(v);
    });
    const schedule = new TransferSchedule({
      amount: transferAmountAsFloat,
      from_account_id,
      to_account_id,
      frequency,
      start_date: DateTime.fromFormat(date, "M/d/yyyy").toFormat("yyyy-MM-dd"),
      limitsAsFloat,
      ach_use_available_balance_for_scheduled_push,
      accounts,
      useBusinessVerbiage: isBusiness,
    });
    return schedule.validateTransferAmount();
  };

  const amountField = useAmountSelector ? (
    <div className="margin--bottom--l">
      <LoanAmountField data={data} validateAmount={validateTransferAmount} />
    </div>
  ) : (
    <ContextForm.Field required validate={validateTransferAmount}>
      <AmountTextInput
        field="amount"
        label={l10n.getString("label-amount")}
        disabled={isAmountFieldDisabled}
      />
    </ContextForm.Field>
  );

  const recurringSubscript = isEditedScheduledTransfer
    ? "recurring-edit"
    : "recurring-new";
  const dateLabel = l10n.getString(
    `label-date-${isRecurring ? recurringSubscript : "once"}`,
  );

  const showAddExternalAccountLinkInSourceDropdown = useMemo(() => {
    if (!showExternalAccountsInSourceDropdown) {
      return false;
    }
    if (!data?.to_account_id) {
      return true;
    }

    const destinationAccount = accounts.find(
      (a) => a.id === data.to_account_id,
    );

    return destinationAccount?.isValidAchTransferDestination() || false;
  }, [accounts, data?.to_account_id]);

  const showAddExternalAccountLinkInDestinationDropdown = useMemo(() => {
    if (!data?.from_account_id) {
      return true;
    }

    const sourceAccount = accounts.find((a) => a.id === data.from_account_id);

    return sourceAccount?.isValidAchTransferSource() || false;
  }, [accounts, data?.from_account_id]);

  return (
    <div className="transfer-fields">
      <ContextForm.Field required>
        <AccountSelector
          field="from_account_id"
          label={
            isLoanPaymentFlow
              ? l10n.getString("label-account")
              : l10n.getString("label-from")
          }
          accounts={getValidTransferSources(
            accounts,
            data?.to_account_id,
            supportsInternalTransfers,
          ).filter((a) =>
            showExternalAccountsInSourceDropdown ? a : a.isInternal(),
          )}
          showAddExternalAccountLink={
            showAddExternalAccountLinkInSourceDropdown
          }
        />
      </ContextForm.Field>
      {!isLoanPaymentFlow && (
        <ContextForm.Field required>
          <AccountSelector
            field="to_account_id"
            label={l10n.getString("label-to")}
            accounts={getValidTransferDestinations(
              accounts,
              ach_allows_push,
              data.from_account_id,
              supportsInternalTransfers,
              enable_loans_in_transfer_flow,
            )}
            isDestination={true}
            showAddExternalAccountLink={
              showAddExternalAccountLinkInDestinationDropdown
            }
          />
        </ContextForm.Field>
      )}
      {amountField}
      {!iotTransfersOnly && (
        <>
          <ContextForm.Field required>
            <FrequencySelector
              field="frequency"
              label={l10n.getString("label-frequency")}
            />
          </ContextForm.Field>
          <ContextForm.Field required>
            <DateInput
              minDate={
                !isRecurring && !isEditedScheduledTransfer
                  ? "today"
                  : DateTime.now().plus({ days: 1 }).toFormat("MM/dd/yyyy")
              }
              dateFormat={"m/d/Y"}
              altInput={true}
              altFormat={"m/d/Y"}
              field="date"
              label={dateLabel}
              useIsoOnChange={false}
            />
          </ContextForm.Field>
        </>
      )}
      {showMemoField && (
        <ContextForm.Field>
          <TextInput
            field="memo"
            label={l10n.getString("label-memo-optional")}
            maxLength={128}
          />
        </ContextForm.Field>
      )}
      <LimitsDialog limitsForDisplay={limitsForDisplay} />
    </div>
  );
};

SimpleTransferFields.propTypes = {
  data: PropTypes.object,
  limits: PropTypes.object,
  formType: PropTypes.number,
};

export default SimpleTransferFields;

import * as entities from "../../entities";
import WireRecipient from "../../../../WireRecipient";
import Account from "../../../../Account";

import type { RootState } from "../../entities";

export const selectFedwireTemplatesWithRecipients =
  entities.utils.createDeepEqualSelector(
    [
      entities.fedwireTemplates.selectFedwireTemplates,
      entities.wires.selectWireRecipients,
    ],
    (templates, recipients) => {
      const templatesWithRecipients: Array<{
        template: API.FedwireTemplate;
        recipient: API.WireRecipient;
      }> = [];
      templates
        .sort((a, b) => {
          // a and b checks needed for potential null values in last used `Timestamp`
          if (!a.last_used) return 1;
          if (!b.last_used) return -1;
          return Date.parse(b.last_used) - Date.parse(a.last_used);
        })
        .forEach((template) => {
          const recipient = recipients.find((r) => r.id === template.recipient);
          if (!recipient) {
            return;
          }
          templatesWithRecipients.push({
            template,
            recipient,
          });
        });
      return templatesWithRecipients;
    },
  );

export const selectFedwireTemplateDetails =
  entities.utils.createDeepEqualSelector(
    [
      (state: RootState) => state.fedwireTemplates.byId,
      (state: RootState) => state.wires.recipients.byId,
      (state: RootState) => state.accounts.byId,
      (_, fedwireTemplateId?: API.FedwireTemplateId) => fedwireTemplateId,
    ],
    (templatesById, recipientsById, accountsById, fedwireTemplateId) => {
      if (!fedwireTemplateId) {
        return null;
      }

      const template = templatesById[fedwireTemplateId];
      if (!template) {
        return null;
      }
      const recipient = recipientsById[template.recipient];
      if (!recipient) {
        return null;
      }
      const account = accountsById[template.source_account];
      if (!account) {
        return null;
      }

      try {
        return {
          template,
          recipient: new WireRecipient(recipient),
          account: Account.deserialize(account),
        };
      } catch (e) {
        return null;
      }
    },
  );

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".totalCountSection--u2VJx{border-top:1px solid var(--border-color-default);margin-top:var(--space-xl);font-weight:var(--font-weight-semibold);padding-top:var(--space-l)}.radioInput--gWoRp .nds-singleRadio .nds-row{align-items:center !important}.radioInput--gWoRp .nds-singleRadio .nds-row-item{display:flex;align-items:center}.radioInput--gWoRp .nds-singleRadio .nds-input{margin-left:var(--space-m);margin-right:var(--space-m)}.radioInput--gWoRp .nds-singleRadio .nds-input-box{height:2.4rem;min-height:2.4rem}.dateInput--oDFCH .nds-input{width:116px}.countInput--p6h_0 .nds-input{width:47px}.contentCardContainer--zqp1E{padding:var(--space-s)}", "",{"version":3,"sources":["webpack://./components/transfer/ach/PaymentScreen.module.scss"],"names":[],"mappings":"AAAA,0BACE,gDAAA,CACA,0BAAA,CACA,uCAAA,CACA,0BAAA,CAKE,6CACE,6BAAA,CAEF,kDACE,YAAA,CACA,kBAAA,CAEF,+CACE,0BAAA,CACA,2BAAA,CAEF,mDACE,aAAA,CACA,iBAAA,CAMJ,6BACE,WAAA,CAKF,8BACE,UAAA,CAIJ,6BACE,sBAAA","sourcesContent":[".totalCountSection {\n  border-top: 1px solid var(--border-color-default);\n  margin-top: var(--space-xl);\n  font-weight: var(--font-weight-semibold);\n  padding-top: var(--space-l);\n}\n\n.radioInput {\n  :global(.nds-singleRadio) {\n    :global(.nds-row) {\n      align-items: center !important;\n    }\n    :global(.nds-row-item) {\n      display: flex;\n      align-items: center;\n    }\n    :global(.nds-input) {\n      margin-left: var(--space-m);\n      margin-right: var(--space-m);\n    }\n    :global(.nds-input-box) {\n      height: 2.4rem;\n      min-height: 2.4rem;\n    }\n  }\n}\n\n.dateInput {\n  :global(.nds-input) {\n    width: 116px;\n  }\n}\n\n.countInput {\n  :global(.nds-input) {\n    width: 47px;\n  }\n}\n\n.contentCardContainer {\n  padding: var(--space-s);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalCountSection": "totalCountSection--u2VJx",
	"radioInput": "radioInput--gWoRp",
	"dateInput": "dateInput--oDFCH",
	"countInput": "countInput--p6h_0",
	"contentCardContainer": "contentCardContainer--zqp1E"
};
export default ___CSS_LOADER_EXPORT___;

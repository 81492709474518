import * as Yup from "yup";
import { DateTime } from "luxon";

import { createForm } from "../../forms";
import { cents } from "../../../utils";
import Filters from "../../../../filters";

import {
  getImmediateLimitsByTransactionTypeAsFloat,
  getStartDate,
} from "./utils";

import type Account from "../../../../Account";

export type LimitsForDisplayType = {
  [key: string]: {
    [key: string]: any;
  };
};

export type ACHPaymentFormType = {
  fromAccount: string;
  amount: Cents;
  secCode: string;
  memo?: string;
  entryDesc?: string;
  startDate: string;

  recurringRule?: {
    isVisible: boolean;
    ends: "" | "never" | "on" | "after";
    frequency?: string;
    untilDate?: string;
    count?: string;
  };

  "recurringRule.isVisible"?: boolean;
  "recurringRule.ends"?: string;
  "recurringRule.frequency"?: string;
  "recurringRule.untilDate"?: string;
  "recurringRule.count"?: number;
};

export type AchScheduledPaymentValidationSchemaProps = {
  limitsForDisplay: Partial<Record<API.Limits.Type, API.Limits.Limit>>;
  values: ACHPaymentFormType;
  isOneTimePaymentForToday: boolean;
  achUseAvailableBalanceForScheduledPush: boolean;
  isBusinessUser: boolean;
  accounts: Account[];
};

export const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  secCode: Yup.string().required("Required"),
});

export const paymentDetailsValidationSchema = validationSchema
  .pick(["fromAccount", "amount"])
  .concat(
    Yup.object().shape({
      startDate: Yup.string().required("Required"),
      recurringRule: Yup.object().shape({
        isVisible: Yup.boolean(),
        ends: Yup.string(),
        frequency: Yup.string(),
        untilDate: Yup.string().when("ends", {
          is: "on",
          then: (schema) => schema.required(),
        }),
        count: Yup.number().when("ends", {
          is: "after",
          then: (schema) => schema.required("Required"),
        }),
      }),
    }),
  );

export const additionalDetailsValidationSchema =
  paymentDetailsValidationSchema.concat(
    Yup.object().shape({
      secCode: Yup.string().required("Required"),
      memo: Yup.string().max(128),
      entryDesc: Yup.string().max(10),
    }),
  );

// For ACH Push immediate one-time transfer, first validate based on ACH push limit
// Then validate against available balance if transfer is immediate one-time or if transfer is scheduled and achUseAvailableBalanceForScheduledPush is enabled
// It is possible for biz users to also have sub-user ACH Push limits, so check that as well
export const getValidateACHPushTransferErrorMessage = (
  props: AchScheduledPaymentValidationSchemaProps,
) => {
  const {
    limitsForDisplay,
    values,
    isOneTimePaymentForToday,
    achUseAvailableBalanceForScheduledPush,
    isBusinessUser,
    accounts,
  } = props;
  const limitsAsFloat =
    getImmediateLimitsByTransactionTypeAsFloat(limitsForDisplay);
  const dollarAmount = Filters.penniesToDollars(values.amount);
  if (
    !(
      "unverified_ach_push" in limitsAsFloat ||
      "organization_user_unverified_ach_push" in limitsAsFloat
    )
  ) {
    // if the limit isn't defined for this user, allow them to proceed
    return "";
  }

  const achPushLimit = Math.min(
    ...[
      limitsAsFloat?.unverified_ach_push,
      limitsAsFloat?.organization_user_unverified_ach_push,
    ].filter((limit) => limit !== undefined && limit !== null),
  );
  const fromAccount = accounts.find(
    (a: Account) => a.id === values.fromAccount,
  );
  // ensure the error message accurately reflects which limit is being applied
  if (isOneTimePaymentForToday && dollarAmount > achPushLimit) {
    return isBusinessUser && limitsAsFloat.unverified_ach_push === achPushLimit
      ? `This amount exceeds your company's remaining ACH payment limit of ${Filters.currency(achPushLimit as Dollars, { hasDecimal: true })}.`
      : `This amount exceeds your remaining ACH payment limit of ${Filters.currency(achPushLimit as Dollars, { hasDecimal: true })}.`;
  }
  if (
    fromAccount &&
    (isOneTimePaymentForToday || achUseAvailableBalanceForScheduledPush)
  ) {
    if (dollarAmount > fromAccount.transferableBalanceAsFloat()) {
      return "Transfer amount cannot exceed source account's available balance.";
    }
  }
  return "";
};

export const getAchScheduledPaymentValidationSchema = (
  props: AchScheduledPaymentValidationSchemaProps,
) => {
  const amountErrorMessage = getValidateACHPushTransferErrorMessage(props);
  return validationSchema.pick(["fromAccount"]).concat(
    Yup.object().shape({
      amount: Yup.number()
        .integer("Please enter a number.")
        .positive("Please enter a positive amount.")
        .required("Required")
        .test("test-amount-limits", amountErrorMessage, function (value) {
          return !amountErrorMessage;
        }),
      startDate: Yup.string().required("Required"),
      recurringRule: Yup.object().shape({
        isVisible: Yup.boolean(),
        ends: Yup.string(),
        frequency: Yup.string(),
        untilDate: Yup.string().when("ends", {
          is: "on",
          then: (schema) => schema.required(),
        }),
        count: Yup.number().when("ends", {
          is: "after",
          then: (schema) => schema.required("Required"),
        }),
      }),
    }),
  );
};

const getOneYearFromNow = () => {
  const oneYearFromNow = DateTime.now().plus({ years: 1 });
  const formattedDate = oneYearFromNow.toFormat("MM/dd/yyyy");
  return formattedDate;
};

export const initialValues: ACHPaymentFormType = {
  fromAccount: "",
  amount: cents(0),
  secCode: "",
  memo: "",
  entryDesc: "",
  startDate: getStartDate(),

  recurringRule: {
    isVisible: false,
    ends: "",
    frequency: "",
    untilDate: getOneYearFromNow(),
    count: "12",
  },
};

export const transformToApiFields = ({
  amount,
  fromAccount: institution_account,
  achCompany: ach_company,
  secCode: std_ent_cls_code,
  entryDesc: entry_desc,
  recipient,
}: ACHPaymentFormType & {
  recipient: API.RecipientId;
  entryDesc: API.CreateACHPayment.Request["entry_desc"];
  achCompany: API.CreateACHPayment.Request["ach_company"];
}) => {
  return {
    amount,
    institution_account,
    ach_company,
    std_ent_cls_code,
    entry_desc,
    recipient,
  };
};

export const transformToScheduledApiFields = (
  values: ACHPaymentFormType & {
    recipient: API.RecipientId;
    entryDesc: API.CreateACHPayment.Request["entry_desc"];
    achCompany: API.CreateACHPayment.Request["ach_company"];
    transactionType: API.CreateScheduledACHPayment.Request["transaction_type"];
    memo?: API.CreateScheduledACHPayment.Request["memo"];
    recurringRuleString: API.CreateScheduledACHPayment.Request["recurring_rule"];
  },
) => {
  const baseFields = transformToApiFields(values);
  const {
    transactionType: transaction_type,
    recurringRuleString: recurring_rule,
    memo,
  } = values;

  return {
    ...baseFields,
    transaction_type,
    recurring_rule,
    memo,
  };
};

export const ACHPaymentForm = createForm({
  initialValues,
  validationSchema,
});

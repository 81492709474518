import { catcat as cc } from "cerulean";
import appleAppStoreButton from "../../../../assets/images/apple_app_store.svg";
import googlePlayStoreButton from "../../../../assets/images/google_play_store.png";
import style from "../AppDownloadDialogBody.module.scss";

interface MobileDownloadOptionCardProps {
  deviceType: string;
  imageSrc: string;
  fiName: string;
  url: string;
}

const MobileDownloadOptionCard = ({
  deviceType,
  imageSrc,
  fiName,
  url,
}: MobileDownloadOptionCardProps) => (
  <div className={cc("padding--all--s rounded--all--m", style.themeBackground)}>
    <div className="fontSize--s margin--bottom--xxs">Mobile Phone</div>
    <div className="fontSize--l fontColor--heading fontWeight--semibold margin--bottom--m">
      {deviceType}
    </div>
    <div className="fontSize--s margin--bottom--l">
      {`Download the ${fiName} app to your ${deviceType} device to manage your bank acccounts from your own phone.`}
    </div>

    <a
      className="button--reset"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={style.logo}
        alt={`${deviceType} app download button`}
        src={imageSrc}
      />
    </a>
  </div>
);

interface AppDownloadMobileProps {
  fiName: string;
  androidUrl: string;
  appleUrl: string;
}

const AppDownloadMobile = ({
  fiName,
  androidUrl,
  appleUrl,
}: AppDownloadMobileProps) => (
  <>
    <div className="margin--bottom--l">
      Select a device option below to download the app.
    </div>
    <div className="margin--bottom--s">
      <MobileDownloadOptionCard
        deviceType="iOS"
        imageSrc={appleAppStoreButton}
        fiName={fiName}
        url={appleUrl}
      />
    </div>

    <MobileDownloadOptionCard
      deviceType="Android"
      imageSrc={googlePlayStoreButton}
      fiName={fiName}
      url={androidUrl}
    />
  </>
);

export default AppDownloadMobile;

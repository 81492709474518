import { useState, useContext } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Select, TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import {
  isGroupedAccountsArray,
  groupAccounts,
  DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING,
} from "byzantine/src/AccountUtils";
import { Features, featureEquals } from "byzantine/src/Feature";
import { useUserFeatures } from "./contexts/UserFeaturesContext";
import { useInstitutionSettings } from "./contexts/InstitutionSettingsContext";
import AddAccountModal from "./transfer/AddAccountModal";
import AccountContext from "./contexts/AccountContext";

const useTruncatedAccountValues = ({
  account,
  isDestination,
}: {
  account: Account;
  isDestination: boolean;
}) => {
  const accountDescription = account.getDescription(isDestination);
  const splittingIndex = accountDescription.indexOf("(");
  let name = accountDescription;
  let lastFour = "";
  if (splittingIndex !== -1) {
    lastFour = accountDescription.substring(splittingIndex);
    name = accountDescription.substring(0, splittingIndex);
  }
  return {
    name,
    lastFour,
  };
};

interface AccountSelectorProps {
  field: string;
  showAddExternalAccountLink?: boolean;
  value: string;
  accounts: Account[];
  isDestination: boolean;
  label: string;
  onChange: (accountId: string) => void;
  error?: string;
  errorText?: string;
}

export default function AccountSelector({
  showAddExternalAccountLink = true,
  isDestination = false,
  value,
  accounts,
  label,
  onChange,
  error,
  errorText,
}: AccountSelectorProps) {
  const { l10n } = useLocalization();
  const userFeatures = useUserFeatures();
  const institutionSettings = useInstitutionSettings();
  const { accounts: allAccounts, setAccounts } = useContext(AccountContext);
  const [modalOpen, setModalOpen] = useState(false);
  const shouldGroup = accounts?.length >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING;

  const groupedAccounts = shouldGroup ? groupAccounts(accounts) : accounts;

  const canAddExternalAccount =
    featureEquals(
      userFeatures as Features,
      "indigo.ExternalAccount_permission",
      "*",
    ) &&
    showAddExternalAccountLink &&
    ((!isDestination && institutionSettings.ach_allows_pull) ||
      (isDestination && institutionSettings.ach_allows_push)) &&
    (userFeatures as any).ach;

  const updateAccounts = (changedAccounts: Account[]) => {
    // TODO the context should manage the update function
    const accountsUpdated = allAccounts.map((account: Account) => account);
    changedAccounts.forEach((acc) => {
      const account = new Account(acc);
      // current AccountContext skips pending external accounts
      if (account.isExternal() && !account.verified) return;
      const idx = accountsUpdated.findIndex(
        (a: Account) => a.id === account.id,
      );
      if (idx !== -1) {
        accountsUpdated[idx] = account;
      } else {
        accountsUpdated.push(account);
      }
    });
    setAccounts(accountsUpdated);
  };

  return (
    <>
      <Select label={label} onChange={onChange} value={value} error={error} errorText={errorText}>
        {/* Flat list of accounts */}
        {isGroupedAccountsArray(groupedAccounts) &&
          [...groupedAccounts].map((account) => {
            const { name, lastFour } = useTruncatedAccountValues({
              account,
              isDestination,
            });
            return (
              <Select.Item
                key={account.id}
                value={account.id}
                searchValue={account.getDescription()}
              >
                <TruncatedAccount lastFour={lastFour} name={name} />
              </Select.Item>
            );
          })}

        {/* Categorized accounts list */}
        {groupedAccounts instanceof Map &&
          [...groupedAccounts].map(
            ([categoryName, categoryAccounts], catIndex) => (
              <Select.Category
                key={`${catIndex}-${categoryName}`}
                label={categoryName}
                isFlat
              >
                {categoryAccounts.map((account, i) => {
                  const { name, lastFour } = useTruncatedAccountValues({
                    account,
                    isDestination,
                  });
                  return (
                    <Select.Item
                      key={`${account.id}-${i}`}
                      value={account.id}
                      searchValue={account.getDescription()}
                    >
                      <TruncatedAccount lastFour={lastFour} name={name} />
                    </Select.Item>
                  );
                })}
              </Select.Category>
            ),
          )}

        {canAddExternalAccount && (
          <Select.Action onSelect={() => setModalOpen(true)}>
            <span className="fontColor--pine fontWeight--bold">
              <span className="narmi-icon-plus padding--right--xs" />
              {l10n.getString("add-new-external-account")}
            </span>
          </Select.Action>
        )}
      </Select>
      <AddAccountModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        updateAccounts={updateAccounts}
      />
    </>
  );
}

AccountSelector.propTypes = {
  field: PropTypes.string,
  accounts: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  isDestination: PropTypes.bool,
  showAddExternalAccountLink: PropTypes.bool,
  error: PropTypes.string,
};

export const OnboardingPromptStatus = {
  completed: "completed",
  inProgress: "in_progress",
} as const;

// To-do CBD 541, 542, 544 - add remaining event names
export const OnboardingEventName = {
  LINK_EXTERNAL_ACCOUNT: "link_external_account",
  ENABLE_ESTATEMENTS: "enable_estatements",
  ADD_SECONDARY_MFA: "add_secondary_mfa",
  ENABLE_BIOMETRICS: "enable_biometrics",
  ADD_SUB_USERS: "add_sub_users",
} as const;

export type OnboardingStatusType =
  (typeof OnboardingPromptStatus)[keyof typeof OnboardingPromptStatus];

export type OnboardingEventNameType =
  (typeof OnboardingEventName)[keyof typeof OnboardingEventName];

export type OnboardingPlatform = "app" | "web";

export type OnboardingPrompt = {
  uuid: string;
  title: string;
  description: string;
  link: string;
  event_name: OnboardingEventNameType;
  icon_name: string;
  platforms: OnboardingPlatform[];
  status: OnboardingStatusType;
};

// To-do CDB-539 - update as needed when API is ready
export type OnboardingPromptResponse = {
  title: string;
  description: string;
  prompts: OnboardingPrompt[];
};

import { DateTime } from "luxon";

// @@ts-expect-error
import Utils from "./utils";

import "./types";
import type Applicant from "./ao/Applicant";
import type { ReactLocalization } from "@fluent/react";
import type { FluentBundle } from "@fluent/bundle";

let dateLocale: string | null = null;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

type CurrencyOptions = {
  absoluteValue?: boolean;
  currency?: string;
  hasDecimal?: boolean;
  hasSign?: boolean;
  truncate?: boolean;
};

type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

// e.g. 1/1/2022
function americanDate(iso8601OrDate: string | Date | Seconds) {
  if (!iso8601OrDate) {
    return "";
  }
  const date = handleZone(iso8601OrDate);
  return date.toFormat("M/d/yyyy");
}

function americanDatePadded(iso8601OrDate: string | Seconds) {
  const date = handleZone(iso8601OrDate);
  return date.toFormat("MM/dd/yyyy");
}

function fractionalSecondsToAmericanDate(stamp: Seconds) {
  // example: 1710357182.344221 --> 3/12/2024
  const date = new Date(stamp * 1000);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function bullet(string: string) {
  if (!string) {
    return "";
  }

  return `\u2022 ${string}`;
}

function capsToCamel(string: string) {
  const lowerString = string.toLowerCase();
  return lowerString.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase(),
  );
}

function camelToSnake(string: string) {
  return string.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
}

function capitalizeFirstLetter(string: string) {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function currency(
  input: Dollars,
  options: CurrencyOptions & { hasDecimal: true },
): string;
function currency(
  input: Cents,
  options?: CurrencyOptions & { hasDecimal?: false },
): string;
function currency(input: number | string, options: CurrencyOptions = {}) {
  let n = Number(input);
  if (Number.isNaN(n)) return "";
  let ccy = options.currency !== undefined ? options.currency : "$";
  const absoluteValue =
    options.absoluteValue !== undefined ? options.absoluteValue : false;

  if (!options.hasDecimal) {
    n /= 100;
  }

  if (n < 0) {
    if (!absoluteValue) {
      ccy = `-${ccy}`;
    }
    n = -n;
  } else if (options.hasSign) {
    ccy = `+${ccy}`;
  }

  const truncated = n > 0 ? Math.floor(n) : Math.ceil(n);
  const decimalized =
    options && options.truncate ? truncated.toString() : n.toFixed(2);
  const groupedNumber = decimalized.replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1,");
  return `${ccy}${groupedNumber}`;
}

function dollarsToPennies(amount: Dollars): Cents {
  return Math.round(Number(amount) * 100) as Cents;
}

function filterKeys(object: Record<string, any>, prefix: string) {
  // Returns a copy of `object` containing only those keys starting with `prefix`
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => key.startsWith(prefix)),
  );
}

// If offset information is provided (e.g. iso8601 date), the date should be formatted in
// system's TZ and the "time" should not be converted. The (hh:mm) should remain the same.
// e.g. 22:00 with -05:00 offset should be displayed as 22:00 in zone with -05:00 offset.

// If no offset information is provided (e.g. pre-formatted date), the date should
// ignore any timezone conversion and display the string as it is.
function handleZone(iso8601OrDate: string | Date | Seconds) {
  let date = DateTime.fromISO(iso8601OrDate as string, { setZone: true });
  if (!date.isValid) {
    date = DateTime.fromJSDate(new Date(iso8601OrDate));
  }

  if (dateLocale !== null) {
    // CLDR for translations sets translated month names in
    // Spanish to have lowercase first letter by default.
    // We will keep this behavior to adhere to the standard translations.
    return date.setLocale(dateLocale || "en");
  }
  return date;
}

function humanize(string: string, options = { capitalizeFirst: true }) {
  if (!string) {
    return "";
  }

  let newString = string;
  newString = newString.replace(/^[\s_]+|[\s_]+$/g, "").replace(/[_\s]+/g, " ");

  newString = options.capitalizeFirst
    ? newString.replace(/^[a-z]/, (m) => m.toUpperCase())
    : newString;

  return Utils.formatBankingWords(newString);
}

// e.g. January 1, 2022
function longMonthDayYear(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return date.toFormat("MMMM d, yyyy");
}

// e.g. January 1
function longMonthDay(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return date.toFormat("MMMM d");
}

function longMonthName(monthNumber: MonthNumber) {
  if (!(typeof monthNumber === "number")) {
    return "";
  }
  return monthNames[monthNumber];
}

function numberToText(num: number): string {
  /* Only up to 99. numberToText(9) = "nine"
   */
  const numberToTextMap = {
    ones: [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ],
    tens: [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ],
  };
  if (num < 20) return numberToTextMap.ones[num];
  if (num > 99) return String(num);
  return `${numberToTextMap.tens[Math.floor(num / 10)]}
      ${numberToText(Math.floor(num % 10))}`;
}

function parseCurrency(ccy: string) {
  // parse a currency string into our internal currency format
  const matches = ccy.match(/\$?([0-9.]*)/);
  if (!matches || matches.length < 2) return NaN;
  return parseFloat(matches[1]);
}

function penniesToDollars(amount: number): Dollars {
  return Number(Number(amount / 100).toFixed(2)) as Dollars;
}

function percent(value: string | number) {
  return `${(Number(value) * 100).toFixed(2)}%`;
}

function removeExtraSpaces(string: string) {
  if (!string) {
    return "";
  }
  return string.replace(/\s\s+/g, " ");
}

// e.g. Jan 1, 2022, 12:00 am
function shortMonthDayYearTime(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return `${date.toFormat("MMM d, yyyy")}, ${time(iso8601OrDate)}`;
}

// e.g. Jan 1, 2022
function shortMonthDayYear(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return `${date.toFormat("MMM d, yyyy")}`;
}

function shortMonthName(monthNumber: MonthNumber) {
  if (!monthNames[monthNumber]) {
    return "";
  }
  return monthNames[monthNumber].substr(0, 3);
}

function shortDate(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return date.toFormat("L/d/yy");
}

function snakeToCamel(string: string) {
  return string.replace(/(_[a-z])/g, (match) =>
    match.toUpperCase().replace("-", ""),
  );
}

// e.g. 4:20 pm
function time(iso8601OrDate: string) {
  const date = handleZone(iso8601OrDate);
  return date.toFormat("t").toLocaleLowerCase();
}

function dateRange(date1: string, date2: string) {
  let start = handleZone(date1);
  let end = handleZone(date2);

  // ensure that start is actually the earlier date
  if (start > end) {
    [start, end] = [end, start];
  }

  // if same month and year; e.g. August 6-12, 2024
  if (start.hasSame(end, "month") && start.hasSame(end, "year")) {
    return `${start.toFormat("LLLL d")}-${end.toFormat("d, yyyy")}`;
  }

  // if same year, but different month, e.g. August 30 - September 2, 2024
  if (start.hasSame(end, "year")) {
    return `${start.toFormat("LLLL d")} - ${end.toFormat("LLLL d, yyyy")}`;
  }

  // e.g. December 20, 2024 - January 2, 2025
  return `${start.toFormat("LLLL d, yyyy")} - ${end.toFormat("LLLL d, yyyy")}`;
}

function titlecase(string: string) {
  if (!string) {
    return "";
  }

  let newString = string;
  const capitalizer = (s: string) =>
    `${s.charAt(0).toUpperCase()}${s.substr(1).toLowerCase()}`;
  newString = newString.replace(/\w\S*/g, capitalizer);

  return Utils.formatBankingWords(newString);
}

function truncate(string: string, length: number = 60, more: string = "...") {
  if (!string) {
    return "";
  }

  if (string.length > length) {
    return `${string.slice(0, length)}${more}`;
  }

  return string;
}

function setDateL10n(l10n: ReactLocalization) {
  const l10nBundles = Array.from(l10n.bundles);
  const locales = [...l10nBundles].map((b: FluentBundle) => b.locales[0]);
  dateLocale = locales[0];
}

function getApplicantFullName(applicant: Applicant) {
  if (!applicant) return "";
  if (!applicant.last_name) return applicant.first_name;
  return `${applicant.first_name} ${applicant.last_name}`;
}

/* 
["A"] => "A"
["A", "B"] => "A and B"
["A", "B", "C"] => "A, B, and C"
*/
function humanReadableList(stringArray: string[]) {
  if (!stringArray.length) return "";
  if (stringArray.length === 1) return stringArray[0];
  if (stringArray.length === 2) return stringArray.join(" and ");
  const arrayCopy = [...stringArray];
  const lastItem = arrayCopy.pop();
  return `${arrayCopy.join(", ")}, and ${lastItem}`;
}

/* copied from https://www.bqst.fr/country-code-to-flag-emoji/ */
function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127_397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export default {
  setDateL10n,
  americanDate,
  americanDatePadded,
  bullet,
  capsToCamel,
  camelToSnake,
  capitalizeFirstLetter,
  currency,
  dateRange,
  dollarsToPennies,
  filterKeys,
  getApplicantFullName,
  getFlagEmoji,
  handleZone,
  humanize,
  humanReadableList,
  longMonthDayYear,
  longMonthDay,
  longMonthName,
  numberToText,
  parseCurrency,
  penniesToDollars,
  percent,
  removeExtraSpaces,
  shortMonthDayYearTime,
  shortMonthDayYear,
  shortMonthName,
  shortDate,
  snakeToCamel,
  time,
  titlecase,
  truncate,
  fractionalSecondsToAmericanDate,
};

import { useState } from "react";
import { modules } from "byzantine";
import { Button, Dialog } from "cerulean"
import { useLocalization } from "@fluent/react";

import { moneyFormatter } from "./TransferReview";

export default function TransferFeesDialog(){
  const fees = modules.transfers.useTransferFees();
  const [open, setOpen] = useState(false);
  const { l10n } = useLocalization();

  function displayInstantFees() {
    if(!fees || fees.kind === "free") {
      return l10n.getString("no-fee-disclosure")
    }
    if(fees.kind === "percent"){
      return l10n.getString("percent-fee-disclosure", { percent: (fees.percent as number * 100).toFixed(2), minFee: moneyFormatter.format(fees.min as number), maxFee: moneyFormatter.format(fees.max as number)})
    }
    return l10n.getString("fixed-fee-disclosure", { amount: moneyFormatter.format(fees.max as number) })
  }

  return <div className="padding--top--s">
    <Button label={l10n.getString("label-view-pricing-and-fees")} kind="plain" onClick={() => setOpen(true)}/>
    <Dialog isOpen={open} title={l10n.getString("header-transfers-pricing-and-fees")} onUserDismiss={() => {setOpen(false)}}>
      <p className="fontWeight--bold">{l10n.getString("header-transfers-disclosures-instant")}</p>
      <p>{displayInstantFees()}</p>
      <p className="fontWeight--bold">{l10n.getString("header-transfers-disclosures-ach")}</p>
      <p>{l10n.getString("label-no-fee")}</p>
    </Dialog>
  </div>
}
import * as Yup from "yup";
import { RRule } from "rrule";

import type { AlertFormType } from "./form";

const isValidRecurrenceRule = (rruleStr: string) => {
  try {
    RRule.fromString(rruleStr);
    return true;
  } catch (error) {
    return false;
  }
};

export enum AlertDeliveryChannel {
  EMAIL = "email",
  PUSH = "push",
  SMS = "sms",
}

export const validationSchema = Yup.object<AlertFormType>({
  alertKey: Yup.string()
    .required("Alert type is required")
    .notOneOf(["unknown"], "Alert type is required"),
  rrule: Yup.string()
    .nullable()
    // If alertKey is "available_balance", rrule is required.
    .when("alertKey", {
      is: "available_balance",
      then: (schema) => schema.required("Recurrence rule is required"),
      otherwise: (schema) => schema.nullable(),
    })
    // Custom test: if a value is provided, it must be a valid recurrence rule.
    .test(
      "is-valid-rrule",
      "Valid recurrence rule is required",
      (value) => !value || isValidRecurrenceRule(value),
    ),
  transactionQuery: Yup.string()
    .nullable()
    .when("alertKey", {
      is: (val: string) => ["incoming", "outgoing"].includes(val),
      then: (schema) => schema.required("Amount is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  reminderDaysOffset: Yup.number()
    .nullable()
    .when("alertKey", {
      is: "loan_reminder",
      then: (schema) =>
        schema
          .required("Due date is required")
          .min(0, "Due date must be at least 0 days before payment")
          .max(7, "Due date must be at most 7 days before payment"),
      otherwise: (schema) => schema.nullable(),
    }),
  subscribedAccounts: Yup.array()
    .of(Yup.string().required())
    .nullable()
    .when("alertKey", {
      is: (key: API.AlertKey) =>
        ["low_available_balance", "available_balance"].includes(key),
      then: (schema) =>
        schema.test(
          "non-empty-array-or-null",
          "Accounts are required",
          (value) =>
            value === null || (Array.isArray(value) && value.length > 0),
        ),
      otherwise: (schema) => schema.nullable(),
    }),
  deliveryChannels: Yup.array()
    .of(
      Yup.string().oneOf(
        Object.values(AlertDeliveryChannel),
        "Invalid delivery channel",
      ),
    )
    .min(1, "At least one delivery channel is required")
    .test(
      "unique-delivery-channels",
      "Delivery channels must be unique",
      (value) => !value || value.length === new Set(value).size,
    )
    .required(),
});

import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import { Button, ContentCard, Alert } from "cerulean";

import styles from "./SavvyMoneyWidget.module.scss";

interface SavvyMoneyProps {
  hasErrored: boolean;
  isLoading: boolean;
  refetch: () => void;
}

const ErrorContent = ({
  isLoading,
  hasAlreadyRefetched,
  onRefetch,
}: {
  isLoading: boolean;
  hasAlreadyRefetched: boolean;
  onRefetch: () => void;
}) => {
  const { l10n } = useLocalization();
  const errorMessage = l10n.getString("savvy-money-widget-error");
  const secondaryErrorMessage = l10n.getString(
    "savvy-money-widget-secondary-error",
  );

  return (
    <div className={styles.errorContainer}>
      <Alert isActive isDismissable={false} kind="warn">
        <div>{errorMessage}</div>
        {hasAlreadyRefetched && !isLoading ? (
          <div>{secondaryErrorMessage}</div>
        ) : (
          <div>
            <Button disabled={isLoading} onClick={onRefetch} kind="plain">
              Try again
            </Button>
          </div>
        )}
      </Alert>
    </div>
  );
};

const IframeContent = ({ url }: { url: string }) => (
  <div className={styles.iframeContainer}>
    <iframe
      title="SavvyMoney"
      src={`${url}&linkToAction=${encodeURIComponent(
        `${window.location.origin}/credit_monitor`,
      )}`}
      style={{ width: "338px", height: "350px", border: "none" }}
    />
  </div>
);

const SavvyMoneyWidget: React.FC<SavvyMoneyProps> = ({
  hasErrored,
  isLoading,
  refetch,
}) => {
  const [hasAlreadyRefetched, setHasAlreadyRefetched] = useState(false);

  const url = entities.savvyMoney.useSavvyMoneyUrl(
    entities.savvyMoney.SavvyMoneyViewUrl.WebWidget,
  );

  const handleRefetch = () => {
    if (!hasAlreadyRefetched) {
      setHasAlreadyRefetched(true);
      refetch();
    }
  };

  if (!url && !hasErrored) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ContentCard kind="elevated">
        {hasErrored ? (
          <ErrorContent
            isLoading={isLoading}
            hasAlreadyRefetched={hasAlreadyRefetched}
            onRefetch={handleRefetch}
          />
        ) : (
          url && <IframeContent url={url} />
        )}
      </ContentCard>
    </div>
  );
};

export default SavvyMoneyWidget;

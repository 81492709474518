import type { ReactLocalization } from "@fluent/react";

export const getSelectedLocale = (l10n: ReactLocalization): string => {
  const localeCode = "en";

  if (l10n.areBundlesEmpty()) {
    return localeCode;
  }

  const bundles = Array.from(l10n.bundles || []);
  return bundles[0]?.locales?.[0] || localeCode;
};

import Account from "byzantine/src/Account";
import { TruncatedAccount } from "cerulean";
import { forwardRef, memo, ReactNode } from "react";
import { RenderItemMenuProps } from "../types";
import Details from "../../Details";
import AccountList from "../../AccountList";

interface RenderGroupProps {
  children: ReactNode;
  numGroups: number;
}

const RenderGroup = ({ children, numGroups }: RenderGroupProps) => (
  <div
    className={`account-group navbar-accountGroupColumn--${numGroups}`}
    role="menu"
  >
    {children}
  </div>
);

interface RenderHeaderProps {
  group: ReactNode;
}

const RenderHeader = ({ group }: RenderHeaderProps) => (
  <div className="group-header">{group}</div>
);

interface RenderAccountProps {
  account: Account;
}

const RenderAccount = forwardRef<HTMLAnchorElement, RenderAccountProps>(
  ({ account }, ref) => (
    <div>
      <a
        className="topMenu-button fontWeight--default fontColor--primary"
        href={`/accounts/${account.id}`}
        role="menuitem"
        aria-label={`Account Group - ${account.getGroupName()} - ${
          account.name
        } ${account.getMaskedNumber()}`}
        ref={ref}
      >
        <TruncatedAccount
          name={account.name}
          lastFour={account.getMaskedNumber()}
        />
      </a>
    </div>
  ),
);

RenderAccount.displayName = "RenderAccount";

type Props = {
  item: RenderItemMenuProps<API.AccountMenuItem>;
  orderedAccounts: Account[];
};

const AccountItem = ({ orderedAccounts }: Props) => (
  <Details
    label={"Accounts"}
    key="Accounts"
    type="wide details"
    ariaLabel="Accounts menu"
  >
    <AccountList
      accounts={orderedAccounts.filter((a) => a.isInternal())}
      alwaysGroup={true}
      RenderGroup={RenderGroup}
      RenderHeader={RenderHeader}
      RenderAccount={RenderAccount}
    />
  </Details>
);

export default memo(AccountItem);

import { DEFAULT_STATE } from "byzantine";

type Props = {
  user?: API.User;
  accounts?: API.AnyAccount[];
  limits?: API.Limits.ByType;
};

const preloadState = ({ user, accounts = [], limits = {} }: Props) => {
  const defaultState = structuredClone(DEFAULT_STATE);
  defaultState.user.user = user || null;
  defaultState.accounts.byId = Object.fromEntries(
    accounts.map((a) => [a.id, a])
  );
  defaultState.limits.data = limits;
  return defaultState;
};

export default preloadState;

import { useBreakpoints, Drawer, IconButton } from "cerulean";
import { memo, PropsWithChildren, useEffect } from "react";
import { FocusOn } from "react-focus-on";

type Props = PropsWithChildren<{
  isOpen: boolean;
  close: () => void;
}>;

const MobileNavigationDrawer = ({ isOpen, close, children }: Props) => {
  // Hack for now to remove the bundled NDS Drawer mobile dismiss button.
  // Add prop "showMobileControls" to NDS Drawer to resolve.
  const { m, l } = useBreakpoints();
  useEffect(() => {
    if (!isOpen || m) {
      return;
    }
    document
      .querySelectorAll(".mobile-navigation-button .narmi-icon-x")
      .forEach((button) => {
        button.remove();
      });
  }, [isOpen, m]);

  useEffect(() => {
    if (isOpen && l) {
      close();
    }
  }, [isOpen, l]);

  return (
    <Drawer
      isOpen={isOpen}
      onUserDismiss={close}
      showControls={false}
      showClose={false}
      depth={"350px"}
      paddingSize="l"
    >
      <FocusOn scrollLock={false}>
        <div className="alignChild--right--center">
          <IconButton
            kind="action"
            label="Close drawer"
            name="x"
            onClick={close}
          />
        </div>
        <div className="navBar">{children}</div>
      </FocusOn>
    </Drawer>
  );
};

export default memo(MobileNavigationDrawer);

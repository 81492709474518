import { cents, currencyToCents, type GenericForm, getInForm } from "byzantine";
import filters from "byzantine/src/filters";

function createCommonProps<T, Form extends GenericForm<any>>(
  form: Form,
  key: string
) {
  const error = (getInForm(form.touched, key) &&
    getInForm(form.errors, key)) as string;
  const value = getInForm(form.values, key);

  return {
    id: key,
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    value,
    error,
  };
}

export function input<
  T extends string,
  Form extends GenericForm<any>,
  K extends keyof Form["values"] & string
>(form: Form, key: K) {
  const commonProps = createCommonProps<T, Form>(form, key);
  return commonProps;
}

// to be used with components that have their own onChange handler
// gets the value from the component's onChange, and sets the formik field value
export function fieldWithOnChange<
  T extends string,
  Form extends GenericForm<any>,
  K extends keyof Form["values"] & string
>(form: Form, key: K) {
  const { error, ...commonProps } = createCommonProps<T, Form>(form, key);
  return {
    ...commonProps,
    onChange: (value: number | string | boolean) => {
      form.setFieldValue(key, value, true);
    },
    error,
  };
}

export function amountInput<
  T extends string,
  Form extends GenericForm<any>,
  K extends keyof Form["values"] & string
>(form: Form, key: K) {
  const { error, value, ...rest } = createCommonProps<T, Form>(form, key);
  const displayValue = filters.currency(cents(value));
  return {
    ...rest,
    value: displayValue,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(key, currencyToCents(event.target.value), true);
    },
    error,
  };
}

export function radio<
  T extends string,
  Form extends GenericForm<any>,
  K extends keyof Form["values"] & string
>(form: Form, key: K) {
  const commonProps = createCommonProps<T, Form>(form, key);
  return {
    ...commonProps,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(key, event.target.value, true);
    },
  };
}

export function select<
  T extends string,
  Form extends GenericForm<any>,
  K extends keyof Form["values"] & string
>(form: Form, key: K) {
  const { error, ...commonProps } = createCommonProps<T, Form>(form, key);
  return {
    ...commonProps,
    onChange: (value: string) => form.setFieldValue(key, value, true),
    errorText: error,
    error,
  };
}

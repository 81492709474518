import { createForm } from "../../forms";

import { validationSchema } from "./schema";

export type AlertFormType = {
  rrule: string | null;
  alertKey: API.AlertKey;
  transactionQuery: string | null;
  reminderDaysOffset: number | null;
  subscribedAccounts: API.AccountId[] | null;
  deliveryChannels: API.DeliveryChannel[];
};

const initialValues: AlertFormType = {
  rrule: null,
  alertKey: "unknown",
  transactionQuery: null,
  reminderDaysOffset: null,
  subscribedAccounts: [],
  deliveryChannels: [],
};

export const alertForm = createForm<AlertFormType>({
  initialValues,
  validationSchema,
});

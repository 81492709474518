import {
  createContext,
  useEffect,
  ReactNode,
  useContext,
} from "react";

import { entities } from "byzantine";

const defaultInstitutionSettings: API.Institution = { long_name: "" };
const InstitutionSettingsContext = createContext(defaultInstitutionSettings);

interface InstitutionSettingsContextProviderProps {
  children: ReactNode;
}

export const InstitutionSettingsContextProvider = ({
  children,
}: InstitutionSettingsContextProviderProps) => {

  const institution = entities.institution.useInstitution();
  const { send } = entities.institution.useFetchInstitution();

  useEffect(() => {
    send();
  }, [])

  return (
    <InstitutionSettingsContext.Provider value={institution || defaultInstitutionSettings}>
      {children}
    </InstitutionSettingsContext.Provider>
  );
};

export const useInstitutionSettings = () =>
  useContext(InstitutionSettingsContext);

export default InstitutionSettingsContext;

import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";

import type { RootState } from "../store";

const selectFromState = (state: RootState) => state.fedwireTemplates.byId;

export const selectFedwireTemplates = createDeepEqualSelector(
  selectFromState,
  (byId) => Object.values(byId),
);

export const selectOneFedwireTemplate = createDeepEqualSelector(
  [
    selectFromState,
    (_, fedWireTemplateId?: API.FedwireTemplateId) => fedWireTemplateId,
  ],
  (byId, fedWireTemplateId) => {
    return fedWireTemplateId ? byId[fedWireTemplateId] : undefined;
  },
);

export const selectSortedFedwireTemplateIds = createDeepEqualSelector(
  [selectFedwireTemplates],
  (templates) => {
    // Because a Timestamp in a template may be null, we must do a check against `a` and `b`.
    // When a last used date is null, we push it to the end of the list.
    return templates
      .sort((a, b) => {
        if (!a.last_used) return 1;
        if (!b.last_used) return -1;
        return Date.parse(b.last_used) - Date.parse(a.last_used);
      })
      .map((t) => t.id);
  },
);

import { Route, Routes } from "react-router-dom";
import { ComposeProviders, entities, modules } from "byzantine";
import {
  initialValues as achPaymentFormInitialValues,
  type ACHPaymentFormType,
  type LimitsForDisplayType,
} from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import Container from "../../Container";
import ACHPaymentContainer from "./ACHPaymentContainer";
import ReviewScreen from "./ReviewScreen";
import { SudoProvider, SudoRoutes } from "../../SudoContext";
import RequestOTP from "../../SudoContext/RequestOTP";
import SubmitOTP from "../../SudoContext/SubmitOTP";
import PaymentScreen from "./PaymentScreen";
import AdditionalDetailsScreen from "./AdditionalDetailsScreen";
import { useInstitutionSettings } from "../../contexts/InstitutionSettingsContext";

// corresponding to routes in indigo/urls.py
export const ACH_PAYMENT_ROUTE = "ach_payment";
export const ACH_PAYMENT_REVIEW_ROUTE = "review";
export const ACH_PAYMENT_DETAIL_ROUTE = "detail";
export const ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE = "additional";

// have to bootstrap recipient.bootstrap.Provider so we can call the `useFetchRecipients` hook in `ACHPaymentContainer`
// `useFetchRecipients` is defined in the bootstrap request hooks
// also bootstrap the form provider so we can use formik
const Providers = [
  modules.achPayments.bootstrap.Provider,
  entities.recipients.ACHDestinationForm.Provider,
  entities.recipients.ACHPaymentRecipientForm.Provider,
];

const initialAchPaymentFormValues: (
  institution: API.Institution,
) => ACHPaymentFormType = (institution: API.Institution) => ({
  ...achPaymentFormInitialValues,
  startDate: modules.achPayments.getStartDate(institution.ach_next_cutoff),
});

const ACHPaymentRoutes = ({ limits }: { limits: LimitsForDisplayType }) => {
  const institution = useInstitutionSettings();
  if (!Object.prototype.hasOwnProperty.call(institution, "ach_next_cutoff")) {
    return null;
  }
  return (
    <ComposeProviders components={Providers}>
      <modules.achPayments.ACHPaymentForm.Provider
        initialValues={initialAchPaymentFormValues(institution)}
      >
        <Container>
          <SudoProvider basename={ACH_PAYMENT_ROUTE}>
            <Routes>
              <Route path="/" element={<ACHPaymentContainer />} />
              <Route
                path={ACH_PAYMENT_DETAIL_ROUTE}
                element={<PaymentScreen limits={limits} />}
              />
              <Route
                path={ACH_PAYMENT_REVIEW_ROUTE}
                element={<ReviewScreen />}
              />
              <Route
                path={ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE}
                element={<AdditionalDetailsScreen />}
              />
              <Route
                path={`${SudoRoutes.RequestOTP}`}
                element={<RequestOTP />}
              />
              <Route path={`${SudoRoutes.SubmitOTP}`} element={<SubmitOTP />} />
            </Routes>
          </SudoProvider>
        </Container>
      </modules.achPayments.ACHPaymentForm.Provider>
    </ComposeProviders>
  );
};

export default ACHPaymentRoutes;

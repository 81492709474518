import { useEffect, useRef } from "react";
import { entities } from "byzantine";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";

import { LoadingShim, useNotificationContext } from "cerulean";

import useSavvyMoneyResizeHandlers from "../hooks/useSavvyMoneyResizeHandlers";

import styles from "./SavvyMoneyDashboard.module.scss";

interface SavvyMoneyDashboardProps {
  features: API.Features;
}

const SavvyMoneyDashboard = ({ features }: SavvyMoneyDashboardProps) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const navigate = useNavigate();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { send: fetchSavvyMoneyRedirectUrl, error: hasErrored } =
    entities.savvyMoney.useFetchSavvyMoneyRedirectUrl(
      entities.savvyMoney.SavvyMoneyViewUrl.WebDashboard,
      Boolean(features.olb_enable_credit_monitor),
    );

  const url = entities.savvyMoney.useSavvyMoneyUrl(
    entities.savvyMoney.SavvyMoneyViewUrl.WebDashboard,
  );

  if (hasErrored) {
    sendNotification({
      type: "negative",
      text: l10n.getString("savvy-money-full-experience-error"),
    });

    navigate("/", { replace: true });
  }

  useEffect(() => {
    fetchSavvyMoneyRedirectUrl();
  }, [fetchSavvyMoneyRedirectUrl]);

  useSavvyMoneyResizeHandlers(url, iframeRef);

  return url ? (
    <div className={styles.container}>
      <iframe
        ref={iframeRef}
        title="SavvyMoney"
        src={url}
        style={{ width: "100%", height: "337px", border: "none" }}
      />
    </div>
  ) : (
    <LoadingShim isLoading>
      <div style={{ height: "100vh" }} />
    </LoadingShim>
  );
};

export default SavvyMoneyDashboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transferSummaryContainer--DCOIA{display:inline}.transferSummarySpan--ljt24{width:auto;display:inline}.receiptButton--sULZV{display:flex;flex-wrap:nowrap;justify-content:center}", "",{"version":3,"sources":["webpack://./components/transfer/funds/TransferReview.module.scss"],"names":[],"mappings":"AACA,iCACE,cAAA,CAGF,4BACE,UAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":["\n.transferSummaryContainer {\n  display: inline;\n}\n\n.transferSummarySpan {\n  width: auto;\n  display: inline;\n}\n\n.receiptButton {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transferSummaryContainer": "transferSummaryContainer--DCOIA",
	"transferSummarySpan": "transferSummarySpan--ljt24",
	"receiptButton": "receiptButton--sULZV"
};
export default ___CSS_LOADER_EXPORT___;

import "./Limits.types";

export type UserLimitBody = Record<`${number}`, Dollars>;

export type UserLimits = Partial<Record<API.Limits.Type, UserLimitBody>>;

export type ModelStr =
  | "indigo.Account"
  | "indigo.AchTransfer"
  | "integrations.base.Card"
  | "indigo.ExternalAccount"
  | "indigo.IntraInstitutionTransfer"
  | "indigo.MemberFriend"
  | "integrations.base.Payee"
  | "integrations.base.Payment"
  | "indigo.Transaction"
  | "indigo.WireTransaction"
  | "integrations.rdc"
  | "indigo.DualApprovalRequest"
  | "indigo.Organization"
  | "indigo.OrganizationUser"
  | "indigo.Role"
  | "indigo.Recipient"
  | "integrations.documents"
  | "indigo.ACHPayment";

export const MODEL_STR_NAMES: Record<string, ModelStr> = {
  ACCOUNT: "indigo.Account",
  ACH_TRANSFER: "indigo.AchTransfer",
  CARD: "integrations.base.Card",
  EXTERNAL_ACCOUNT: "indigo.ExternalAccount",
  INTRA_INSTITUTION_TRANSFER: "indigo.IntraInstitutionTransfer",
  MEMBER_FRIEND: "indigo.MemberFriend",
  PAYEE: "integrations.base.Payee",
  PAYMENT: "integrations.base.Payment",
  TRANSACTION: "indigo.Transaction",
  WIRE_TRANSACTION: "indigo.WireTransaction",
  RDC: "integrations.rdc",
  DUAL_APPROVAL_REQUEST: "indigo.DualApprovalRequest",
  ORGANIZATION: "indigo.Organization",
  ORGANIZATION_USER: "indigo.OrganizationUser",
  ROLE: "indigo.Role",
  DOCUMENTS: "integrations.documents",
  RECIPIENT: "indigo.Recipient",
  ACH_PAYMENT: "indigo.ACHPayment",
} as const;

export type Operation = "read" | "write" | "*";

export const ROLE_NAMES = {
  ACCOUNT_HOLDER: "Account Holder",
  ADMIN: "Admin",
  COLLABORATOR: "Collaborator",
  VIEWER: "Viewer",
} as const;

export type RoleName =
  | (typeof ROLE_NAMES)[keyof typeof ROLE_NAMES]
  | (string & Record<string, never>);

const ROLE_DESCS = {
  ACCOUNT_HOLDER: "Primary account holder",
  ADMIN: "Full access including adding new users",
  VIEWER: "Limited access with no ability to move money",
  COLLABORATOR: "Customize your teammate's access",
} as const;

// Custom roles have null descriptions
export type RoleDescription =
  | (typeof ROLE_DESCS)[keyof typeof ROLE_DESCS]
  | null;

declare global {
  namespace API {
    type RoleId = Brand<string, "RoleId">;

    interface Role {
      uuid: API.RoleId;
      organization_uuid: API.OrganizationId;
      name: RoleName;
      description: RoleDescription;
      permissions: Permission[];
      limits: UserLimits;
    }
  }
}

export type OrgRolesPayload = {
  roles: API.Role[];
};

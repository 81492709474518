import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import { Navigate } from "react-router-dom";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import Address from "byzantine/src/Address";
import User from "byzantine/src/User";
import {
  Button,
  Row,
  useFormData,
  useNotificationContext,
  Checkbox,
  ContextForm,
  useLoadingContext,
} from "cerulean";
import type { InstitutionFeatures } from "byzantine/src/types";
import BankingUSAddressField from "../../../../address/BankingUSAddressField";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import { useInstitutionSettings } from "../../../../contexts/InstitutionSettingsContext";
import utils from "../../../../../utils";

interface AddressEditDialogBodyProps {
  doAddressesMatch: boolean;
  closeDialog: () => void;
}

interface AddressFormData {
  primary_address: Address; // eslint-disable-line camelcase
  mailing_address: Address; // eslint-disable-line camelcase
}

const AddressEditDialogBody = ({
  doAddressesMatch,
  closeDialog,
}: AddressEditDialogBodyProps) => {
  const { l10n } = useLocalization();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { sendNotificationToParent } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const institutionSettings = useInstitutionSettings();
  const { formData, setFormData, onChange } = useFormData({
    primary_address: currentUser?.getPrimaryAddress()?.toJSON(),
    mailing_address: currentUser?.getMailingAddress()?.toJSON(),
  });
  const [addressesMatch, setAddressesMatch] = useState(doAddressesMatch);
  const [primaryAddressErrors, setPrimaryAddressErrors] = useState({});
  const [mailingAddressErrors, setMailingAddressErrors] = useState({});
  const isBusinessUser = !!currentUser?.isBusiness();
  const streetAddressLabel = l10n.getString(
    isBusinessUser ? "label-business-street-address" : "label-street-address",
  );

  const onPrimaryAddressChange = (update: Partial<Address>) => {
    setFormData((prev: AddressFormData) => ({
      ...prev,
      primary_address: { ...prev.primary_address, ...update },
    }));
  };

  const onMailingAddressChange = (update: Partial<Address>) => {
    setFormData((prev: AddressFormData) => ({
      ...prev,
      mailing_address: { ...prev.mailing_address, ...update },
    }));
  };

  const validateAddresses = () => {
    const primaryErrors = utils.validateAddress(formData.primary_address);
    const mailingErrors = utils.validateAddress(formData.mailing_address);
    setPrimaryAddressErrors(primaryErrors);
    setMailingAddressErrors(mailingErrors);
    if (
      Object.keys(primaryErrors).length !== 0 ||
      Object.keys(mailingErrors).length !== 0
    ) {
      return "Please correct the below errors";
    }
    return null;
  };

  useEffect(() => {
    if (addressesMatch) {
      setFormData({
        ...formData,
        mailing_address: { ...formData.primary_address, type: "mailing" },
      });
    }
  }, [addressesMatch]);

  const onSubmit = async (callback: CallableFunction) => {
    if (validateAddresses() !== null) {
      setIsLoading(false);
      return;
    }
    try {
      const primaryAddress = new Address(formData.primary_address);
      let mailingAddress = new Address(formData.mailing_address);
      if (addressesMatch) {
        mailingAddress = new Address({
          ...formData.primary_address,
          type: "mailing",
        });
      }
      // we first update the primary address and then the mailing address
      let updatedUser = await currentUser?.updateAddress(primaryAddress);
      updatedUser = await currentUser?.updateAddress(mailingAddress);

      if (updatedUser) {
        const { addresses } = updatedUser;
        setCurrentUser(new User({ ...currentUser, addresses }));
        const updatedAddressesNotificationText =
          institutionSettings.has_mailing_address
            ? "Addresses updated."
            : "Address updated.";
        sendNotificationToParent({
          type: "success",
          text: updatedAddressesNotificationText,
        });
        callback();
      }
      closeDialog();
    } catch (error) {
      if (error instanceof Error) {
        callback(error.message);
      } else {
        callback(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="margin--top--s" />
      <div
        className="fontWeight--bold  margin--bottom--s"
        style={{ color: "RGB(var(--nds-black))" }}
      >
        {l10n.getString(
          isBusinessUser
            ? "label-business-primary-address"
            : "label-personal-primary-address",
        )}
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field field="primary_address">
          <BankingUSAddressField
            addressType={"primary"}
            onUpdate={onPrimaryAddressChange}
            streetAddressLabel={streetAddressLabel}
            data={formData.primary_address || {}}
            showTitle={false}
            errors={primaryAddressErrors}
            marginTop="none"
          />
        </ContextForm.Field>
        {institutionSettings.has_mailing_address && (
          <>
            <div
              className="fontWeight--bold margin--bottom--s"
              style={{ color: "RGB(var(--nds-black))" }}
            >
              {l10n.getString(
                isBusinessUser
                  ? "label-business-mailing-address"
                  : "label-personal-mailing-address",
              )}
            </div>
            {!addressesMatch && (
              <ContextForm.Field field="mailing_address">
                <BankingUSAddressField
                  addressType={"mailing"}
                  onUpdate={onMailingAddressChange}
                  streetAddressLabel={streetAddressLabel}
                  data={formData.mailing_address || {}}
                  showTitle={false}
                  errors={mailingAddressErrors}
                  marginTop="none"
                  marginBottom="margin--bottom--s"
                />
              </ContextForm.Field>
            )}
            <Checkbox
              onChange={() => setAddressesMatch(!addressesMatch)}
              label={l10n.getString(
                isBusinessUser
                  ? "label-same-business-address"
                  : "label-same-residential-address",
              )}
              checked={addressesMatch}
            />
          </>
        )}
        <div className="margin--bottom--xl" />
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <Button
              type="button"
              onClick={closeDialog}
              kind="negative"
              label="Cancel"
            />
          </Row.Item>
          <Row.Item shrink>
            <ContextForm.Action onSubmit={onSubmit}>
              <Button kind="primary" label="Save changes" />
            </ContextForm.Action>
          </Row.Item>
        </Row>
      </ContextForm>
    </>
  );
};

interface AddressEditDialogBodyWrapperProps {
  closeDialog: () => void;
}

const AddressEditDialogBodyWrapper = ({
  closeDialog,
}: AddressEditDialogBodyWrapperProps) => {
  const { currentUser } = useCurrentUser();
  const primaryAddress = currentUser?.getPrimaryAddress();
  const mailingAddress = currentUser?.getMailingAddress();

  const doAddressesMatch =
    isEqual(omit(primaryAddress, ["type"]), omit(mailingAddress, ["type"])) ||
    !mailingAddress;

  const institutionSettings = useInstitutionSettings();
  const userFeatures = useUserFeatures() as InstitutionFeatures;

  if (
    !institutionSettings?.core_update_address ||
    !userFeatures.address_change ||
    !currentUser?.isPersonalUserOrBusinessAdmin()
  ) {
    return <Navigate to={"/profile"} replace />;
  }
  return (
    <AddressEditDialogBody
      doAddressesMatch={doAddressesMatch}
      closeDialog={closeDialog}
    />
  );
};

export default AddressEditDialogBodyWrapper;

import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useEnsureSingleVisibleModal } from "./modal.hooks";

const preventMouseScroll = (event) => {
  event.preventDefault();
  event.stopPropagation();
  return false;
};
const preventKeyBoardScroll = (event) => {
  if ([32, 33, 34, 35, 37, 38, 39, 40].includes(event.keyCode)) {
    event.preventDefault();
    return false;
  }
  return true;
};
const disableScroll = () => {
  document.addEventListener("wheel", preventMouseScroll, { passive: false });
  document.addEventListener("keydown", preventKeyBoardScroll);
};
const enableScroll = () => {
  document.removeEventListener("wheel", preventMouseScroll);
  document.removeEventListener("keydown", preventKeyBoardScroll);
};

const Modal = ({
  open = false,
  setOpen,
  children,
  type = "center",
  header,
  actions,
  nullIfClosed,
}) => {
  React.useEffect(() => {
    // disable scrolling while a modal is open
    if (open) {
      disableScroll();
    } else {
      enableScroll();
    }
    return enableScroll;
  }, [open]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  useEnsureSingleVisibleModal({ open, closeModal });

  if (!open && nullIfClosed) {
    return null;
  }

  return (
    <div className={`nds-modal ${type}`} data-open={open.toString()}>
      <div
        role="button"
        tabIndex="-1"
        className="nds-modal-overlay"
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            closeModal();
          }
        }}
        onClick={closeModal}
      ></div>
      <div className="nds-modal-container" data-open={open.toString()}>
        <div
          role="button"
          tabIndex="0"
          className="nds-modal-dismiss"
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              closeModal();
            }
          }}
          onClick={closeModal}
        >
          <span
            className={"narmi-icon-x"}
            style={{ fontSize: "20px", color: "rgb(var(--nds-black))" }}
          />
        </div>
        {header ? (
          <>
            <h4 className="nds-sans">{header}</h4>
            <hr className="nds-hr" />
          </>
        ) : (
          ""
        )}
        {children}
        {actions && <div className="nds-modal-action-row">{actions}</div>}
      </div>
    </div>
  );
};

Modal.propTypes = {
  /** content to render within the Modal body */
  children: PropTypes.node.isRequired,
  /** content to render as the Modal header */
  header: PropTypes.node,
  /** When true, the Modal will be open */
  open: PropTypes.bool,
  /** the setState for open */
  setOpen: PropTypes.func,
  /** content to render within the actions area of the Modal */
  actions: PropTypes.node,
  /** Type of modal */
  type: PropTypes.oneOf([
    "center",
    "right",
    "details",
    "wide details",
    "medium details",
  ]),
  /** render null if true and open = false */
  nullIfClosed: PropTypes.bool,
};

export default memo(Modal);

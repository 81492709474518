import React from "react";
import PropTypes from "prop-types";
import { Select } from "@narmi/design_system";

const DropdownField = ({
  options,
  onChange = () => {},
  label = "",
  value = "",
  field,
}) => {
  /**
   * The managed `value` in a Select dropdown is always a string,
   * but this component is expecting either a string OR array for `value`.
   */
  const handleChange = (val) => onChange(JSON.parse(val));
  return (
    <Select
      label={label}
      value={JSON.stringify(value)}
      onChange={handleChange}
      id={field}
    >
      {options.map((option, i) => (
        <Select.Item
          key={`${option.value}-${i}`}
          value={JSON.stringify(option.value)}
          searchValue={option.displayName}
        >
          {option.displayName}
        </Select.Item>
      ))}
    </Select>
  );
};

DropdownField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.any,
};

export default DropdownField;

import QRCode from "react-qr-code";
import { catcat as cc } from "cerulean";
import style from "../AppDownloadDialogBody.module.scss";

const AppDownloadDesktop = () => {
  const downloadAppUrl = new URL("/app_download", window.location.origin);

  return (
    <>
      <div className="margin--bottom--l">
        Securely manage your finances within our app.
      </div>
      <div
        className={cc("padding--all--l rounded--all--m", style.themeBackground)}
      >
        <div className="alignChild--center--center">
          <div className="padding--right--l">
            <div
              className={cc(
                "padding--all--xs rounded--all--m bgColor--white",
                style.qrCodeWrapper
              )}
            >
              <QRCode
                size={112}
                fgColor={"var(--theme-primary)"}
                value={downloadAppUrl.toString()}
              />
            </div>
          </div>
          <div className="fontSize--s fontWeight--semibold">
            Use your mobile phone to scan the QR code.
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDownloadDesktop;

import React from "react";
import { Select } from "@narmi/design_system";
import PropTypes from "prop-types";

const CountrySelector = ({ label, onChange, value, error, countries }) => {
  const sortAndMapCountries = () => {
    countries.sort((a, b) => {
      if (a.code === "US") return -1;
      if (b.code === "US") return 1;
      return a.name.localeCompare(b.name);
    });
    return countries.map((c) => ({
      key: c.code,
      text: c.name,
      value: c.code,
    }));
  };
  return (
    <Select label={label} value={value} onChange={onChange} error={error}>
      {Object.values(sortAndMapCountries()).map((c) => (
        <Select.Item key={c.key} value={c.value} searchValue={c.text}>
          {c.text}
        </Select.Item>
      ))}
    </Select>
  );
};
CountrySelector.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  countries: PropTypes.array,
};

export default CountrySelector;

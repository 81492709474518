import { Select, Row } from "@narmi/design_system";

interface RecipientSelectorProps {
  field: string;
  value: string;
  recipients: API.Recipient[];
  label: string;
  onChange: (recipientId: API.Recipient["id"]) => void;
  onAddRecipientClick: () => void;
  error?: string;
  displayBottomAction?: boolean;
}

const RecipientSelector = ({
  value,
  recipients,
  label,
  onChange,
  onAddRecipientClick,
  displayBottomAction,
  error,
}: RecipientSelectorProps) => (
  <Select label={label} value={value} onChange={onChange} errorText={error}>
    {recipients.map((r) => (
      <Select.Item key={r.id} value={r.id} searchValue={r.name}>
        <Row gapSize="xxs">
          <Row.Item>{r.name}</Row.Item>
          {r.nickname ? (
            <Row.Item shrink>
              <span className="fontSize--xs fontColor--secondary">
                {r.nickname}
              </span>
            </Row.Item>
          ) : null}
        </Row>
      </Select.Item>
    ))}
    {displayBottomAction && (
      <Select.Action onSelect={onAddRecipientClick}>
        <span className="fontColor--theme--primary fontWeight--bold">
          <span className="narmi-icon-plus padding--right--xs" />
          Add a new recipient
        </span>
      </Select.Action>
    )}
  </Select>
);

export default RecipientSelector;

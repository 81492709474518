import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { entities } from "byzantine";
import AzulFooter from "../components/AzulFooter";

const FooterContainer = (props) => {
  const institution = entities.institution.useInstitution();
  const { send } = entities.institution.useFetchInstitution();

  useEffect(() => {
    send();
  }, [])

  return (
    <AzulFooter
      ehlLogoUrl={props.ehlLogoUrl}
      institutionSettings={institution || {}}
    />
  );
};
FooterContainer.propTypes = {
  ehlLogoUrl: PropTypes.string,
};

export default FooterContainer;

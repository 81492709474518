import { createSlice } from "@reduxjs/toolkit";

import { userAlertEntityAdapter } from "./adapter";

const userAlertsSlice = createSlice({
  name: "userAlerts",
  initialState: userAlertEntityAdapter.getInitialState(),
  reducers: {
    setOne: userAlertEntityAdapter.setOne,
    setMany: userAlertEntityAdapter.setMany,
    addOne: userAlertEntityAdapter.addOne,
    removeOne: userAlertEntityAdapter.removeOne,
  },
});

export const { actions } = userAlertsSlice;

export default {
  userAlerts: userAlertsSlice.reducer,
};

/* eslint-disable camelcase */

import { useContext } from "react";
import { useLocalization } from "@fluent/react";
import ReactMarkdown from "react-markdown";
import {
  type LimitsForDisplayType,
} from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import { Dialog } from "cerulean";
import InstitutionSettingsContext from "../../contexts/InstitutionSettingsContext";
import { RenderLimit } from "../LimitsDialog";

type LimitsAndDisclosuresDialog = {
  isOpen: boolean;
  handleClose: () => void;
  limits: LimitsForDisplayType;
};

const LimitsAndDisclosuresDialog = ({
  isOpen,
  handleClose,
  limits,
}: LimitsAndDisclosuresDialog) => {
  const { l10n } = useLocalization();
  const { ach_payment_disclosure } = useContext(InstitutionSettingsContext);

  const stripLimits = (limitsForDisplay: LimitsForDisplayType) =>
    Object.fromEntries(
      Object.entries(limitsForDisplay).map(([transactionType, values]) => {
        // for each transactionType, filter the object
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([key]) =>
            // keep the "amount_available" for numeric keys;
            Number(key),
          ),
        );

        return [transactionType, filteredValues];
      }),
    );

  const limitsForDisplay = stripLimits(limits);

  const renderRemainingUnverifiedACHLimits = () => (
    <>
      <div className="margin--top--l margin--bottom--l">
        {limitsForDisplay?.organization_user_unverified_ach_push && (
          <>
            <div className="fontColor--heading">
              {l10n.getString("limit-personal-title")}
            </div>
            <RenderLimit
              limits={limitsForDisplay}
              transactionType="organization_user_unverified_ach_push"
            />
            <br />
          </>
        )}
        {limitsForDisplay?.unverified_ach_push && (
          <>
            <div className="fontColor--heading">
              {l10n.getString("limit-organizational-title")}
            </div>
            <RenderLimit
              limits={limitsForDisplay}
              transactionType="unverified_ach_push"
            />
          </>
        )}
      </div>
    </>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("ach-payment-limits-modal-title")}
    >
      {renderRemainingUnverifiedACHLimits()}
      {ach_payment_disclosure ? (
        <div className={"fontColor--heading fontWeight--semibold"}>
          {l10n.getString("limit-disclosures-title")}
          <ReactMarkdown linkTarget="_blank">
            {ach_payment_disclosure}
          </ReactMarkdown>
        </div>
      ) : null}
    </Dialog>
  );
};

export default LimitsAndDisclosuresDialog;

import { Dialog, Button, Row } from "cerulean";
import { useLocalization } from "@fluent/react";

export type DualApprovalRejectApproveDialogType = {
  isOpen: boolean;
  type: "" | "approve" | "reject",
  handleClose: () => void;
  handleReject: () => void;
  handleApprove: () => void;
  totalSelected: number;
};

const DualApprovalRejectApproveDialog = ({
  isOpen,
  type,
  handleClose,
  handleReject,
  handleApprove,
  totalSelected,
}: DualApprovalRejectApproveDialogType) => {
  const { l10n } = useLocalization();
  const rejectModal = type === "reject";

  const handleClickRejectOrApprove = () => {
    if (type === "reject") {
      handleReject();
    } else if (type === "approve") {
      handleApprove();
    }
    handleClose();
  };

  return (
    <Dialog
      width="385px"
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={rejectModal ? l10n.getString("reject-modal-heading", { totalSelected }) : l10n.getString("approve-modal-heading", { totalSelected })}
      footer={
        <Row alignItems="center">
          <Row.Item>
            <Button kind="plain" onClick={handleClose}>
              Cancel
            </Button>
          </Row.Item>
          <Row.Item shrink>
            <Button onClick={handleClickRejectOrApprove}>
              {rejectModal ? l10n.getString("reject-modal-confirm") : l10n.getString("approve-modal-confirm")}
            </Button>
          </Row.Item>
        </Row>
      }
    >
      <span className="margin--bottom--xl">
        {rejectModal ? l10n.getString("reject-modal-content", { totalSelected }) : l10n.getString("approve-modal-content", { totalSelected })}
      </span>
    </Dialog>
  );
};

export default DualApprovalRejectApproveDialog;

import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { selectBAccount, selectBAccounts, selectOneAccount } from "./selectors";

import type { RootState } from "../store";
import type Account from "../../../../Account";

export const useBAccount = (id: API.AccountId): Account | undefined => {
  return useSelector((state: RootState) => selectBAccount(state, id), isEqual);
};

export const useAccounts = () => {
  return useSelector(selectBAccounts);
};

export const useAccount = (accountId?: API.AccountId): Account | undefined => {
  return useSelector((state) => selectOneAccount(state, accountId), isEqual);
};

export const useValidTransferSources = (
  selectedDestinationAccountId?: Account["id"],
  supportsInternalTransfers: boolean = true,
): Account[] => {
  const accounts = useAccounts();
  const selectedDestinationAccount = accounts.find(
    (a) => a.id === selectedDestinationAccountId,
  );
  return accounts.filter((a) => {
    if (a.isExternal() && !a.verified) {
      return false;
    }
    return a.isValidTransferSourceForDestination(
      selectedDestinationAccount,
      supportsInternalTransfers,
    );
  });
};

export function useValidTransferDestinations(
  achAllowsPush: boolean,
  selectedSourceAccountId?: Account["id"],
  supportsInternalTransfers: boolean = true,
  areLoansAllowed: boolean = true,
): Account[] {
  const accounts = useAccounts();
  const selectedSourceAccount = accounts.find(
    (a) => a.id === selectedSourceAccountId,
  );
  return accounts.filter((a) => {
    if (a.isExternal() && !a.verified) {
      return false;
    }
    return a.isValidTransferDestinationForSource(
      selectedSourceAccount,
      achAllowsPush,
      supportsInternalTransfers,
      areLoansAllowed,
    );
  });
}

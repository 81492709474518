import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { Alert } from "@narmi/design_system";
import ReactMarkdown from "react-markdown";
import { entities, modules } from "byzantine";
import { LoadingSkeleton } from "cerulean";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import type Account from "byzantine/src/Account";
import Tabs from "../tabs/Tab";
import { AccountContextProvider } from "../contexts/AccountContext";
import DualApprovalHistoryContainer from "./DualApprovalHistoryContainer";
import DualApprovalPendingContainer, {
  PAGE_SIZE,
} from "./DualApprovalPendingContainer";
import styles from "./DualApproval.module.scss";

type DualApprovalContainerType = {
  approvalRequests: API.AnyDualApproval[];
  accounts: Account[];
};

export type SelectedDualApprovalsMapperType = {
  [key: string]: DualApprovalRequest;
};

export const DualApprovalPermissionsAlert = () => {
  const { l10n } = useLocalization();
  const permissionedErrorMessage = l10n.getString(
    "approval-requires-multiple-permissioned-users",
    {br: "\n"},
    "You’re the only user with approvals permissions, so any items you submit can’t be approved or rejected by others.\n[Update user permissions](/manage_users)",
    );

  return (
    <div className={styles.permissionsAlert}>
      <Alert isActive isDismissable={false} kind="error" >
        {permissionedErrorMessage.split("\n").map((line: string, index: number) => (
          <>
            <ReactMarkdown className={styles.approvalPermissionMessageContent} key={index}>
              {line}
            </ReactMarkdown>
          </>
        ))}
      </Alert>
    </div>
)}

const DualApprovalContainer = ({
  approvalRequests,
  accounts,
}: DualApprovalContainerType) => {
  const { l10n } = useLocalization();
  const [selectedDualApprovals, setSelectedDualApprovals] =
    useState<SelectedDualApprovalsMapperType>({});
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState(
    approvalRequests.length,
  );
  const { send: fetchRecipients, loading: recipientsLoading } =
    entities.recipients.useFetchRecipients();
  const recipients = entities.recipients.useRecipients();
  const { organizationHasMultipleApprovalUsers, loading: orgUsersLoading, isOrgUserWithEditPerms } =
    modules.achPayments.useOrganizationHasMultipleApprovalUsers();
  const loading = recipientsLoading || orgUsersLoading;
  const showDualApprovalsAlert = !organizationHasMultipleApprovalUsers && !orgUsersLoading && isOrgUserWithEditPerms;

  useEffect(() => {
    fetchRecipients();
  }, []);

  if (loading) {
    return <LoadingSkeleton content="paragraph" isLoading={loading} />;
  }

  const handleSetTotalDualApprovals = (total: number) => {
    setTotalAwaitingApproval(total);
  };

  const getPageNumber = (selectedIndex: number, pageSize: number) => {
    if (selectedIndex === -1) {
      // if no found index, default page number to 1
      return 1;
    }

    return Math.floor(selectedIndex / pageSize) + 1;
  };

  const renderPendingApprovals = () => {
    const { dualApprovalId: selectedDualApprovalId } = useParams();
    const selectedIndex = approvalRequests.findIndex(
      (approvalRequest) => approvalRequest.uuid === selectedDualApprovalId,
    );
    const pageNumber = getPageNumber(selectedIndex, PAGE_SIZE);

    return (
      <DualApprovalPendingContainer
        selectedDualApprovals={selectedDualApprovals}
        setSelectedDualApprovals={setSelectedDualApprovals}
        updateTotal={handleSetTotalDualApprovals}
        recipients={recipients}
        pageNumber={pageNumber}
      />
    );
  };

  const renderApprovalsHistory = () => (
    <DualApprovalHistoryContainer recipients={recipients} />
  );

  const tabPanes = [
    {
      header: {
        text: l10n.getString("heading-pending-approvals-count", {
          totalAwaitingApproval,
        }),
      },
      render: renderPendingApprovals,
    },
    {
      header: l10n.getString("heading-approval-history"),
      render: renderApprovalsHistory,
    },
  ];

  return (
    <Router basename="approval_requests">
      <AccountContextProvider accounts={[...accounts]}>
      {showDualApprovalsAlert ?
        <DualApprovalPermissionsAlert />
      : null}
        <Routes>
          <Route path="/" element={<Tabs panes={tabPanes} />} />
          <Route path="/:dualApprovalId" element={<Tabs panes={tabPanes} />} />
        </Routes>
      </AccountContextProvider>
    </Router>
  );
};

export default DualApprovalContainer;

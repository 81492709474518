import { useBreakpoints } from "cerulean";
import AppDownloadMobile from "./AppDownloadMobile";
import AppDownloadDesktop from "./AppDownloadDesktop";

interface AppDownloadDialogBodyProps {
  fiName: string;
  androidUrl: string;
  appleUrl: string;
}

const AppDownloadDialogBody = ({
  fiName,
  androidUrl,
  appleUrl,
}: AppDownloadDialogBodyProps) => {
  const { m } = useBreakpoints();
  return m ? (
    <AppDownloadDesktop />
  ) : (
    <AppDownloadMobile
      fiName={fiName}
      androidUrl={androidUrl}
      appleUrl={appleUrl}
    />
  );
};

export default AppDownloadDialogBody;

import React from "react";
import PropTypes from "prop-types";
import Filters from "byzantine/src/filters";
import { Localized } from "@fluent/react";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const EstatementsLastEnabledAt = () => {
  const { currentUser } = useCurrentUser();

  if (!currentUser?.enabled_estatements_at) return null;
  const humanReadableEstatementsEnabledAt = Filters.longMonthDayYear(
    currentUser.enabled_estatements_at,
  );
  return (
    <p className="fontSize--s fontColor--secondary">
      <br />
      <Localized
        id="last-enabled-estatement"
        vars={{ date: humanReadableEstatementsEnabledAt }}
      >
        {`Paperless statements last enabled on ${humanReadableEstatementsEnabledAt}.`}
      </Localized>
    </p>
  );
};

const EstatementsEnabled = ({ estatementsWereEnabledInitially }) => {
  const { currentUser } = useCurrentUser();
  // we use different text & punctuation based on whether the user just enrolled in paperless or was already enrolled in paperless
  const recentlyEnrolledText = estatementsWereEnabledInitially ? "" : " now";
  const recentlyEnrolledPunctuation = ".";

  // we also use different text if the user is in the DB enrollment context (vs. settings context)
  const isEnrollmentContext = window.location.pathname.includes("/enroll");

  // if the user is proceeding through DB enrollment or has the option to unenroll from paperless,
  // we don't want to show them text about contacting support (since they most likely just enrolled in paperless)
  const unenrollmentText =
    isEnrollmentContext ||
    currentUser?.features?.paperless_statement_unenrollment
      ? ""
      : " If you wish to unenroll, please contact support.";

  // this section is only relevant if the user is in the DB enrollment flow (either through conversion or standard enrollment)
  // if the user has enabled estatements as of page-load (including refresh), we render copy that indicates that we've transferred their paperless settings over
  // the is_converting flag will always be true during web enrollment (incl. conversions), so this scenario is relevant to all users progressing through the web enrollment experience
  if (
    currentUser?.is_converting &&
    estatementsWereEnabledInitially &&
    isEnrollmentContext
  ) {
    return (
      <p>
        <Localized id="enrolled-in-paperless-converting">
          You&apos;re already enrolled in paperless statements. Your previous
          selections have been transferred over.
        </Localized>
      </p>
    );
  }
  return (
    <p>
      <Localized
        id="enrolled-in-paperless"
        vars={{
          recentlyEnrolledText,
          recentlyEnrolledPunctuation,
          unenrollmentText,
        }}
      ></Localized>
      {!isEnrollmentContext && estatementsWereEnabledInitially && (
        // this component is not relevant in the DB enrollment context, because the user would've just enabled paperless (so no need to show them that they enabled today)
        // it is also not relevant to converting users (but that case is already covered above)
        // it is also not relevant if the user just enrolled paperless (similar to the enrollment case)
        <EstatementsLastEnabledAt />
      )}
    </p>
  );
};
EstatementsEnabled.propTypes = {
  estatementsWereEnabledInitially: PropTypes.bool,
};

export default EstatementsEnabled;

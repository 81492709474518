/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useLocalization } from "@fluent/react";
import { useNotificationContext } from "cerulean";
import { entities, useFeature, useTopMenu } from "byzantine";

import { useCurrentUser } from "../contexts/CurrentUserContext";
import useAccountSorting from "../hooks/useAccountSorting";

import { TopMenuProps } from "./types";

export const useTopMenuContainer = ({
  legacyNafUrls,
  institution,
}: TopMenuProps) => {
  const { sendNotification } = useNotificationContext();
  const { l10n } = useLocalization();

  const { features, featureEnabled } = useFeature();
  const accounts = entities.accounts.useAccounts();

  // TODO - pull from redux
  const { currentUser } = useCurrentUser();

  const isLoggedIn = currentUser !== null;

  const { orderedAccounts = [] } = isLoggedIn
    ? useAccountSorting(accounts)
    : {};

  const topMenu = useTopMenu();

  const menuItems = isLoggedIn ? topMenu : [];

  // TODO - use DBBL toasts
  // For institutions that require MFA, inform users without a permitted device
  useEffect(() => {
    if (currentUser?.has_permitted_device === false && sendNotification) {
      sendNotification({
        type: "info",
        text: l10n.getString(
          "info-add-permitted-mfa-device",
          null,
          "We've updated our account security. Please add a new two-factor authentication device, which is required to access Digital Banking. [Add one now](/settings/security).",
        ),
      });
    }
  }, [currentUser?.has_permitted_device, sendNotification, l10n]);

  return {
    menuItems,
    institution,
    features,
    orderedAccounts,
    legacyNafUrls,
    featureEnabled,
    isLoggedIn,
  };
};

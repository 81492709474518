import { memo, useEffect } from "react";
import NavBar from "../NavBar";

import { TopMenuProps } from "./types";
import { isAccountItem, isAppItem, isLinkItem, isNafItem } from "./utils";
import { useTopMenuContainer } from "./topMenu.container";

import NAFItem from "./components/nafItem.component";
import LinkItem from "./components/linkItem.component";
import AccountItem from "./components/account.component";
import AppItem from "./components/appItem.component";
import NestedItem from "./components/nestedItem.component";
import { Bootstrap } from "./topMenu.bootstrap";

function TopMenuComponent(props: TopMenuProps) {
  const {
    menuItems,
    institution,
    features,
    orderedAccounts,
    legacyNafUrls,
    isLoggedIn,
  } = useTopMenuContainer(props);

  const { load } = Bootstrap.useBootstrap();

  useEffect(() => {
    load();
  }, []);

  return (
    <NavBar
      features={features}
      deviceIsRemembered={props.deviceIsRemembered}
      institution={institution}
    >
      {menuItems
        .map((item) => {
          if (isLinkItem(item.itemProps)) {
            return (
              <LinkItem
                item={{ name: item.name, itemProps: item.itemProps }}
                key={item.name}
              />
            );
          }

          if (isAccountItem(item.itemProps)) {
            return (
              <AccountItem
                item={{ name: item.name, itemProps: item.itemProps }}
                orderedAccounts={orderedAccounts}
                key={item.name}
              />
            );
          }

          if (isAppItem(item.itemProps)) {
            return (
              <AppItem
                item={{ name: item.name, itemProps: item.itemProps }}
                key={item.name}
              />
            );
          }

          if (isNafItem(item.itemProps)) {
            return (
              <NAFItem
                item={{ name: item.name, itemProps: item.itemProps }}
                legacyNafUrls={legacyNafUrls}
                key={item.name}
              />
            );
          }

          return null;
        })
        .filter(Boolean)}
      {isLoggedIn ? <NestedItem legacyNafUrls={legacyNafUrls} /> : null}
    </NavBar>
  );
}

export default Bootstrap.WrapComponent(memo(TopMenuComponent));

import { Outlet } from "react-router-dom";
import { Portal } from "cerulean";

import TopMenu from "../topMenu/TopMenuComponent";
import { Institution, LegacyNafUrl } from "../../types";

interface MainLayoutProps {
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
  institution: Institution;
}

const MainLayout = (props: MainLayoutProps) => (
  <>
    <Portal rootId="top-menu-react">
      <TopMenu {...props} />
    </Portal>
    <Outlet />
  </>
);

export default MainLayout;

import { memo, useMemo } from "react";
import App from "byzantine/src/App";
import AppAuthorize from "../../AppAuthorize";
import { RenderItemMenuProps } from "../types";

const AppItem = ({ item }: { item: RenderItemMenuProps<API.AppMenuItem> }) => {
  const app = useMemo(() => {
    try {
      return App.deserialize(item.itemProps.app);
    } catch {
      return null;
    }
  }, [item.itemProps.app]);

  if (!app) {
    return null;
  }

  return (
    <AppAuthorize app={app} key={app.name}>
      {app.name}
    </AppAuthorize>
  );
};

export default memo(AppItem);

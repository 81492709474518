import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "cerulean";
import Modal from "./Modal";

const Details = ({ label, children, type, ariaLabel }) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <div className="nds-details navbar-nds-btn">
      <Button
        endIcon={`chevron-${open ? "up" : "down"}`}
        kind="menu"
        label={label}
        onClick={toggleMenu}
        ariaLabel={ariaLabel}
      />
      <Modal type={type} open={open} setOpen={setOpen} nullIfClosed={true}>
        <div
          className={`nds-details-container ${
            type === "wide details" ? "narmi-grid-container" : ""
          }`}
        >
          <div className={type === "wide details" ? "narmi-grid-content" : ""}>
            {children}
          </div>
        </div>
      </Modal>
    </div>
  );
};

Details.propTypes = {
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  type: PropTypes.oneOf(["details", "wide details", "medium details"]),
  label: PropTypes.string,
};

Details.defaultProps = {
  type: "details",
};

export default Details;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".themeBackground--OtmVj{background-color:RGBA(var(--theme-rgb-primary), var(--alpha-10));padding:var(--space-s);border-radius:var(--border-radius-m)}.qrCodeWrapper--Q09U_{display:inline-block;width:128px}.logo--qFma0{height:40px}", "",{"version":3,"sources":["webpack://./components/dashboard/AppDownloadDialogBody/AppDownloadDialogBody.module.scss"],"names":[],"mappings":"AAEA,wBACI,gEAAA,CACA,sBAAA,CACA,oCAAA,CAGJ,sBACE,oBAAA,CACA,WAAA,CAGF,aACE,WAAA","sourcesContent":["@import \"../../../../scss/mixins\";\n\n.themeBackground {\n    background-color: RGBA(var(--theme-rgb-primary), var(--alpha-10));\n    padding: var(--space-s);\n    border-radius: var(--border-radius-m);\n}\n\n.qrCodeWrapper {\n  display: inline-block;\n  width: 128px;\n}\n\n.logo {\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themeBackground": "themeBackground--OtmVj",
	"qrCodeWrapper": "qrCodeWrapper--Q09U_",
	"logo": "logo--qFma0"
};
export default ___CSS_LOADER_EXPORT___;

import { Dialog } from "cerulean";
import AppDownloadMobile from "../dashboard/AppDownloadDialogBody/AppDownloadMobile";

interface AppDownloadDialogProps {
  isOpen: boolean;
  fiName: string;
  androidUrl: string;
  appleUrl: string;
  closeDialog: () => void;
}

const AppDownloadDialog = ({
  fiName,
  androidUrl,
  appleUrl,
}: AppDownloadDialogProps) => (
  <Dialog
    isOpen={true}
    title="Download the mobile app"
    onUserDismiss={() => window.location.assign("/")}
  >
    <div className="margin--top--s" />
    <AppDownloadMobile
      fiName={fiName}
      androidUrl={androidUrl}
      appleUrl={appleUrl}
    />
  </Dialog>
);

export default AppDownloadDialog;

import type { NetworkPayload } from "../../../composites";

export const API = {
  checkInstantEligible: ({
    routing_number,
  }: API.InstantEligibilityRequest): NetworkPayload => {
    return {
      url: `transfers/instant/eligibility/${routing_number}/`,
      options: {
        method: "GET",
      },
    };
  },
  getInstantTransferFees: (): NetworkPayload => {
    return {
      url: "transfers/instant/fees/",
      options: {
        method: "GET",
      },
    };
  },
  submitTransferImmediate: ({
    payload,
    idempotencyKey,
  }: {
    payload: API.TransferImmediateRequest;
    idempotencyKey: UUID;
  }): NetworkPayload => {
    return {
      url: "transfers/",
      options: {
        method: "POST",
        payload,
        idempotencyKey,
      },
    };
  },
  submitTransferScheduled: ({
    payload,
    idempotencyKey,
  }: {
    payload: API.TransferScheduledRequest;
    idempotencyKey: UUID;
  }): NetworkPayload => {
    return {
      url: "transfers/scheduled/",
      options: {
        method: "POST",
        payload,
        idempotencyKey,
      },
    };
  },
};

import { useCallback } from "react";

import { useSelector } from "react-redux";

import {
  type OverrideRunProps,
  useRequestWithFeedback,
  combineRunProps,
  parseErrorResponse,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useLibrary } from "../../../providers";
import * as entities from "../../entities";

import { sendViaTemplateForm, upsertTemplateForm } from "./forms";
import { API } from "./api";

export const useCreateWireViaTemplate = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateWireViaFedwireTemplate.Response,
    API.CreateWireViaFedwireTemplate.Error
  >();

  const {
    values: { fedwireTemplate, wireReason, amount, memo },
  } = sendViaTemplateForm.useForm();

  const requiresDualApproval = useSelector(entities.dualApprovalRequired);

  const { throwToast } = useLibrary("toasts");
  const t = useLibrary("translations");

  const dispatch = entities.utils.useAppDispatch();

  const { send: fetchTemplates } =
    entities.fedwireTemplates.useFetchTemplates();
  const { send: fetchWireRecipients } = entities.wires.useFetchWireRecipients();
  const { sendAll: fetchAllLimits } =
    entities.limits.useFetchMultipleLimitsRequest();

  const createWireViaTemplate = useCallback(
    (
      idempotencyKey: UUID,
      overrideRunProps: OverrideRunProps<
        API.CreateWireViaFedwireTemplate.Response,
        API.CreateWireViaFedwireTemplate.Error
      > = {},
    ) => {
      if (!fedwireTemplate) {
        return;
      }
      const message = requiresDualApproval
        ? t.getString(
            "toast-wire-approval-success-banner",
            null,
            "Wire submitted for approval.",
          )
        : t.getString("toast-wire-success-banner", null, "Wire sent.");
      send({
        action: API.createWireViaTemplate({
          idempotencyKey,
          fedwireTemplate,
          wireReason,
          amount,
          memo,
        }),
        messaging: {
          toast: {
            success: message,
          },
        },
        ...combineRunProps<
          API.CreateWireViaFedwireTemplate.Response,
          API.CreateWireViaFedwireTemplate.Error
        >(
          {
            onError: async (error) => {
              const { errors } = await parseErrorResponse(error);
              if (errors.length) {
                throwToast({
                  kind: "error",
                  message: `${errors[0].description}`,
                });
                fetchTemplates();
                fetchWireRecipients();
                fetchAllLimits();
              }
            },
            onData: (wire) => {
              dispatch(entities.wires.actions.upsertOneWire(wire));
            },
          },
          overrideRunProps,
        ),
      });
    },
    [
      fedwireTemplate,
      send,
      wireReason,
      amount,
      memo,
      requiresDualApproval,
      throwToast,
      fetchTemplates,
      fetchWireRecipients,
      fetchAllLimits,
      dispatch,
      t,
    ],
  );

  return useObjectMemo({
    createWireViaTemplate,
    loading,
  });
};

export const useCreateOrEditTemplate = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateOrUpdateFedwireTemplate.Response,
    API.CreateOrUpdateFedwireTemplate.Error
  >();

  const { values } = upsertTemplateForm.useForm();
  const dispatch = entities.utils.useAppDispatch();
  const { throwToast } = useLibrary("toasts");
  const createWireTemplate = useCallback(
    (
      existingFedwireTemplateId?: API.FedwireTemplateId,
      overrideRunProps: OverrideRunProps<
        API.CreateOrUpdateFedwireTemplate.Response,
        API.CreateOrUpdateFedwireTemplate.Error
      > = {},
    ) => {
      send({
        action: API.createOrEditTemplate(values, existingFedwireTemplateId),
        messaging: {
          toast: {
            success: existingFedwireTemplateId
              ? "Template updated."
              : "Template created.",
          },
        },
        onData: (template) => {
          dispatch(entities.fedwireTemplates.actions.updateOne(template));
        },
        onError: async (error) => {
          const { errors } = await parseErrorResponse(error);
          if (errors.length) {
            throwToast({
              kind: "error",
              message: `${errors[0].description}`,
            });
          }
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, throwToast, values],
  );

  return useObjectMemo({
    createWireTemplate,
    loading,
  });
};

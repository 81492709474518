import { useCallback } from "react";

import { useRequestWithFeedback } from "../../../composites";

import API from "./api";

import type { OnboardingPromptResponse } from "./types";
import type { ApiError } from "../../../../types/models/v1/Error.types";
import type { OverrideRunProps } from "../../../composites";

export const useFetchOnboardingPrompts = () => {
  const { send, loading } = useRequestWithFeedback<
    OnboardingPromptResponse,
    ApiError
  >();

  const fetchOnboardingPrompts = useCallback(
    (overrideRunProps?: OverrideRunProps<OnboardingPromptResponse>) => {
      send({
        action: API.getOnboardingPrompts(),
        ...overrideRunProps,
      });
    },
    [send],
  );

  return { fetchOnboardingPrompts, loading };
};

import { useMemo } from "react";

import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import { useAccount } from "../../entities";
import { FREQUENCIES } from "../../../../Recurrence";

import { TransferForm } from "./TransferFunds.form";

import type { RootState } from "../../entities";

export const useSelectedSourceAccount = () => {
  const {
    values: { fromAccount },
  } = TransferForm.useForm();
  return useAccount(fromAccount);
};

export const useSelectedDestinationAccount = () => {
  const {
    values: { toAccount },
  } = TransferForm.useForm();
  return useAccount(toAccount);
};

export const useIsInstantEligible = () => {
  return useSelector(
    (state: RootState) => state.transfers.destinationInstantEligible,
  );
};

export const useIsScheduledTransfer = () => {
  const {
    values: { frequency, date, instant },
  } = TransferForm.useForm();
  const today = DateTime.now().startOf("day");
  const dtDate = date && DateTime.fromFormat(date, "M/d/yyyy");
  return !!(
    !instant &&
    dtDate &&
    (frequency !== FREQUENCIES.ONCE || !dtDate.equals(today))
  );
};

export const useSubmittedTransferState = () => {
  return useSelector((state: RootState) => state.transfers.transferState);
};
export const useSubmittedTransferId = () => {
  return useSelector(
    (state: RootState) => state.transfers.paymentIdentification,
  );
};

export const useIsExternalTransfer = () => {
  const {
    values: { toAccount, fromAccount },
  } = TransferForm.useForm();
  const source = useAccount(fromAccount);
  const destination = useAccount(toAccount);
  return (
    (source && source.isExternal()) || (destination && destination.isExternal())
  );
};

export const useTransferFees = () => {
  return useSelector((state: RootState) => state.transfers.fees);
};

export const useCalculateInstantTransferFee = () => {
  const fees = useTransferFees();
  const {
    values: { amount },
  } = TransferForm.useForm();
  return useMemo(() => {
    if (!fees) {
      return 0;
    }
    switch (fees.kind) {
      case "free":
        return 0;
      case "fixed":
        return fees.max as number;
      case "percent": {
        if (
          fees.min === undefined ||
          fees.max === undefined ||
          fees.percent === undefined
        ) {
          return 0;
        }
        const percentValue = (fees.percent * amount) / 100;
        if (percentValue < fees.min) {
          return fees.min;
        }
        if (percentValue > fees.max) {
          return fees.max;
        }
        return percentValue;
      }
      default:
        return 0;
    }
  }, [fees, amount]);
};

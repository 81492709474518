import { Button } from "cerulean";

import { memo, useCallback } from "react";
import { RenderItemMenuProps } from "../types";
import { getLabel } from "../utils";

const LinkItem = ({
  item,
}: {
  item: RenderItemMenuProps<API.LinkMenuItem>;
}) => {
  const label = getLabel(item);

  const { url, openNewTab } = item.itemProps;

  const onClick = useCallback(() => {
    if (openNewTab) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  }, []);

  return <Button label={label} kind="menu" onClick={onClick} key={item.name} />;
};

export default memo(LinkItem);

import { useCallback } from "react";

import { useObjectMemo } from "../../../hooks";
import { useRequestWithFeedback } from "../../../composites";
import { REQUEST_STATUS } from "../../../composites/request/request.types";
import { useAppDispatch } from "../utils";

import { API } from "./api";
import { actions } from "./slice";
import { useACHCompany } from "./hooks";

import type { BootstrapFetchRequest } from "../../bootstrap";

export const useFetchACHCompanies = () => {
  const { send, status } = useRequestWithFeedback<
    API.FetchACHCompanies.Response,
    API.FetchACHCompanies.Error
  >();

  const dispatch = useAppDispatch();
  const fetchACHCompanies = useCallback(() => {
    send({
      action: API.fetchACHCompanies(),
      onData: ({ results: achCompanies }) => {
        dispatch(actions.setMany(achCompanies));
      },
    });
  }, [dispatch, send]);

  const hasData = !!useACHCompany();

  return useObjectMemo({
    error: status === REQUEST_STATUS.error,
    send: fetchACHCompanies,
    loading:
      status === REQUEST_STATUS.idle || status === REQUEST_STATUS.loading,
    hasData,
  }) satisfies BootstrapFetchRequest;
};

import { matchRoutes, useLocation } from "react-router-dom";

const DIALOG_ROUTES = [
  { path: "/profile/address" },
  { path: "/profile/email" },
  { path: "/profile/phone" },
  { path: "/profile/username" },
];

const useIsProfileEditDialogOpen = () => {
  const location = useLocation();
  return (matchRoutes(DIALOG_ROUTES, location?.pathname) || []).length > 0;
};

export const getEditDialogType = () => {
  const location = useLocation();
  return location?.pathname.split("/").pop();
};

export default useIsProfileEditDialogOpen;

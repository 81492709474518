import { useState } from "react";
import Feature from "byzantine/src/Feature";
import { LoadingShim } from "@narmi/design_system";
import SectionCard from "../SectionCard";
import SignedUrlIframe from "../SignedUrlIframe";
import { useUserFeatures } from "../contexts/UserFeaturesContext";

const ChartCard = () => {
  const features = useUserFeatures();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  if (error) return null;
  return (
    <Feature features={features} flag="trends" equals="mx">
      <SectionCard classNames="dashboard-section-card">
        <LoadingShim isLoading={loading}>
          <div style={{ minHeight: 200 }}>
            <SectionCard.Title text="Net worth" />
            <SignedUrlIframe
              endpoint="signed_urls/mx/urls/net_worth_widget"
              height="550"
              setLoading={setLoading}
              setError={setError}
            />
          </div>
        </LoadingShim>
      </SectionCard>
    </Feature>
  );
};

export default ChartCard;

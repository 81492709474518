import ApiHttp from "./ApiHttp";
import { MODEL_STR_NAMES } from "./types/models/v1";

export default class Organization {
  constructor(props) {
    this.uuid = props.uuid;
    this.dual_approval_required = props.dual_approval_required;
    this.dual_approval_user_control = props.dual_approval_user_control;
  }

  static dualApprovalRequired(orgUuid, method = "GET", value = undefined) {
    return ApiHttp.fetch(
      `organizations/${orgUuid}/dual_approval_required`,
      {
        method,
      },
      {
        value,
      },
    ).then((res) => {
      return Organization.deserialize(res.organization);
    });
  }

  static achDualApprovalRequired(orgUuid, method = "GET", value = undefined) {
    return ApiHttp.fetch(
      `organizations/${orgUuid}/`,
      {
        method,
      },
      {
        ach_dual_approval_required: value,
      },
    ).then((res) => {
      return res;
    });
  }

  static getNumberOfDualApprovalRequests() {
    return ApiHttp.fetch(`approval_requests`, {
      method: "GET",
      params: { exclude_user_requests: true },
    }).then((res) => res.meta.total);
  }

  static getOrganizationHasMultipleApprovalUsers(organizationUsers) {
    if (!organizationUsers) {
      return false;
    }
    const hasApprovalPermission = (organizationUser) =>
      organizationUser.role.permissions.some(
        (p) =>
          ["*", MODEL_STR_NAMES.DUAL_APPROVAL_REQUEST].includes(p.model_str) &&
          ["*", "write"].includes(p.operation),
      );
    return organizationUsers.filter(hasApprovalPermission).length > 1;
  }

  /*
   *  Factory method returning a new instance of Organization from
   *  an ApiHttp serialized Organization
   */
  static deserialize(payload) {
    return new Organization({
      uuid: payload.uuid,
      dual_approval_required: payload.dual_approval_required,
      dual_approval_user_control: payload.dual_approval_user_control,
    });
  }

  serialize() {
    return {
      uuid: this.uuid,
      dual_approval_required: this.dual_approval_required,
      dual_approval_user_control: this.dual_approval_user_control,
    };
  }
}

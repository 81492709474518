import { useCallback } from "react";

import { useDispatch } from "react-redux";

import {
  combineRunProps,
  type OverrideRunProps,
  useRequestWithFeedback,
  parseErrorResponse,
  type AnyException,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useFeature } from "../features";
import { actions } from "../../entities/transfers";
import { useLibrary } from "../../../providers";

import {
  TransferForm,
  transformTransferFundsFormFieldsToApiFields,
} from "./TransferFunds.form";
import { useIsScheduledTransfer, useSelectedDestinationAccount } from "./hooks";
import { API } from "./api";

export const useCheckDestinationInstantEligibility = () => {
  const { send, loading } = useRequestWithFeedback<
    API.InstantEligibilityResponse,
    AnyException
  >();
  const dispatch = useDispatch();
  const { featureEnabled } = useFeature();
  const destination = useSelectedDestinationAccount();
  const checkDestinationInstantEligibility = useCallback(
    (
      overrideRunProps: OverrideRunProps<
        API.InstantEligibilityResponse,
        AnyException
      > = {},
    ) => {
      if (
        !destination ||
        destination.source === "institution" ||
        !featureEnabled("fednow_send")
      ) {
        dispatch(actions.resetEligible());
        return;
      }
      send({
        action: API.checkInstantEligible({
          routing_number: destination.routing,
        }),
        ...combineRunProps<API.InstantEligibilityResponse, AnyException>(
          {
            onError: (_) => {
              dispatch(actions.resetEligible());
            },
            onSuccess(data) {
              dispatch(actions.eligible(data));
            },
          },
          overrideRunProps,
        ),
      });
    },
    [destination, send, dispatch, featureEnabled],
  );

  return useObjectMemo({ checkDestinationInstantEligibility, loading });
};

export const useGetInstantTransferFees = () => {
  const { send, loading } = useRequestWithFeedback<
    API.InstantFeesResponse,
    AnyException
  >();
  const dispatch = useDispatch();
  const { featureEnabled } = useFeature();
  const destination = useSelectedDestinationAccount();
  const getInstantTransferFees = useCallback(
    (
      overrideRunProps: OverrideRunProps<
        API.InstantFeesResponse,
        AnyException
      > = {},
    ) => {
      if (
        !destination ||
        destination.source === "institution" ||
        !featureEnabled("fednow_send")
      ) {
        dispatch(actions.resetEligible());
        return;
      }
      send({
        action: API.getInstantTransferFees(),
        ...combineRunProps<API.InstantFeesResponse, AnyException>(
          {
            onError: (_) => {},
            onSuccess(data) {
              dispatch(actions.setFees(data));
            },
          },
          overrideRunProps,
        ),
      });
    },
    [destination, send, dispatch, featureEnabled],
  );
  return useObjectMemo({ getInstantTransferFees, loading });
};

export const useSubmitTransfer = (idempotencyKey: UUID) => {
  const { send, loading } = useRequestWithFeedback<
    API.TransferResponse,
    AnyException
  >();
  const dispatch = useDispatch();
  const form = TransferForm.useForm();
  const isScheduledTransfer = useIsScheduledTransfer();
  const { throwToast } = useLibrary("toasts");

  const submitTransfer = useCallback(
    (
      overrideRunProps: OverrideRunProps<
        API.TransferResponse,
        AnyException
      > = {},
    ) => {
      const args = {
        payload: transformTransferFundsFormFieldsToApiFields(form.values),
        idempotencyKey,
      };
      send({
        action: isScheduledTransfer
          ? API.submitTransferScheduled(args)
          : API.submitTransferImmediate(args),
        ...combineRunProps<API.TransferResponse, AnyException>(
          {
            onError: async (err) => {
              const { errors } = await parseErrorResponse(err);
              const defaultError =
                "We could not process your transfer. Please try again or contact support.";
              const message = errors.length
                ? `${errors[0].description}`
                : defaultError;
              if (message === "Instant transfer was rejected.") {
                dispatch(actions.setTransferRejected());
                return;
              }
              throwToast({
                kind: "error",
                message,
              });
            },
            onSuccess: (data) => {
              if (
                (data.transfer as API.InstantTransfer)?.payment_identification
              ) {
                dispatch(actions.setInstantTransferCreated(data));
              } else {
                dispatch(actions.setTransferCreated(data));
              }
            },
          },
          overrideRunProps,
        ),
      });
    },
    [
      send,
      dispatch,
      form.values,
      idempotencyKey,
      isScheduledTransfer,
      throwToast,
    ],
  );

  return useObjectMemo({ submitTransfer, loading });
};

import { entities } from "byzantine";
import { memo } from "react";
import { LoadingShim } from "cerulean";
import { LegacyNafUrl } from "../../../types";
import { RenderItemMenuProps } from "../types";
import { isAppItem, isLinkItem } from "../utils";
import LinkItem from "./linkItem.component";
import AppItem from "./appItem.component";
import { Bootstrap } from "../topMenu.bootstrap";

type Props = {
  item: RenderItemMenuProps<API.NAFMenuItem>;
  legacyNafUrls: LegacyNafUrl[];
};

const NAFItem = ({ legacyNafUrls }: Props) => {
  const listOfNafItems = entities.institution.useNAFItems();

  legacyNafUrls.forEach((link) => {
    listOfNafItems[link.title] = { url: link.url, openNewTab: true };
  });

  const { loading } = Bootstrap.useBootstrap();

  return (
    <LoadingShim isLoading={loading}>
      {Object.entries(listOfNafItems).map(([nafName, nafItemProps]) => {
        if (isLinkItem(nafItemProps)) {
          return (
            <LinkItem
              item={{ name: nafName, itemProps: nafItemProps }}
              key={nafName}
            />
          );
        }
        if (isAppItem(nafItemProps)) {
          return (
            <AppItem
              item={{ name: nafName, itemProps: nafItemProps }}
              key={nafName}
            />
          );
        }

        return null;
      })}
    </LoadingShim>
  );
};

export default memo(NAFItem);

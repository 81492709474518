import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "@narmi/design_system";

interface RecipientSelectorProps {
  field: string;
  value: string;
  recipients: API.WireRecipient[];
  label: string;
  onChange: (recipientId: API.WireRecipientId) => void;
  error?: string;
}

const RecipientSelector = ({
  value,
  recipients,
  label,
  onChange,
  error,
}: RecipientSelectorProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Select label={label} value={value} onChange={onChange} error={error}>
      {recipients.map((recipient) => (
        <Select.Item
          key={recipient.id}
          value={recipient.id}
          searchValue={recipient.name}
        >
          {recipient.name}
        </Select.Item>
      ))}
      <Select.Action
        onSelect={() => {
          navigate("/wires/templates/recipients/new", {
            state: { from: location.pathname },
          });
        }}
      >
        <span className="fontColor--theme--primary fontWeight--bold">
          <span className="narmi-icon-plus padding--right--xs" />
          Add a new recipient
        </span>
      </Select.Action>
    </Select>
  );
};

export default RecipientSelector;

import { matchRoutes, useLocation } from "react-router-dom";

const DASHBOARD_DIALOG_ROUTES = [{ path: "/dashboard/:action" }];

const useIsDashboardDialogOpen = () => {
  const location = useLocation();
  return (
    (matchRoutes(DASHBOARD_DIALOG_ROUTES, location?.pathname) || []).length > 0
  );
};

export const isRouteInDashboard = (route: string) =>
  (matchRoutes(DASHBOARD_DIALOG_ROUTES, route) || []).length > 0;

export default useIsDashboardDialogOpen;

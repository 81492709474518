import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { useObjectMemo, usePrevious } from "../../../../hooks";
import {
  dualApprovalRequired,
  wires,
  useInstitution,
  useUser,
  fedwireTemplates,
} from "../../../entities";
import {
  useFedwireTemplates,
  useOneFedwireTemplate,
} from "../../../entities/fedwireTemplates/hooks";
import filters from "../../../../../filters";
import { methodSelectorForm, sendViaTemplateForm } from "../forms";
import {
  upsertTemplateForm,
  transformApiTemplateToForm,
} from "../forms/upsertTemplate.form";
import {
  selectFedwireTemplateDetails,
  selectFedwireTemplatesWithRecipients,
} from "../selectors";
import { TEMPLATE_UPDATED_FLASH_DURATION } from "../consts";
import { useCreateOrEditTemplate } from "../requests";
import { useLibrary } from "../../../../providers";

import { useWirePermissions } from "./permissions";

export const useWireReasons = () => {
  const institution = useInstitution();
  return institution?.wire_reason_types || [];
};

export const useData = () => {
  const user = useUser();
  const institution = useInstitution();
  const templates = useFedwireTemplates();
  const templatesWithRecipients = useSelector(
    selectFedwireTemplatesWithRecipients,
  );
  const recipients = useSelector(wires.selectWireRecipients);
  const sortedFedwireTemplateIds = useSelector(
    fedwireTemplates.selectSortedFedwireTemplateIds,
  );
  const sudoModeRequiredForWires = institution?.sudo_mode_required_for_wires;

  return useObjectMemo({
    user,
    institution,
    templates,
    recipients,
    sudoModeRequiredForWires,
    wireReasons: institution?.wire_reason_types || [],
    templatesWithRecipients,
    sortedFedwireTemplateIds,
  });
};

export const useFedwireTemplatePreview = (initiallyCollapsed = true) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);

  const onToggle = useCallback(() => setCollapsed((c) => !c), []);

  return useObjectMemo({
    collapsed,
    onToggle,
  });
};

export const useSubmitSendViaTemplateForm = () => {
  const { wireReasons, sudoModeRequiredForWires } = useData();

  const form = sendViaTemplateForm.useForm();

  const {
    values: { fedwireTemplateId },
    submitForm,
  } = form;

  const { clearAllToasts } = useLibrary("toasts");
  const onContinue = useCallback(
    async (next: () => void, trySudo: () => void) => {
      const result = await submitForm();
      if (!result.success) {
        return null;
      }
      clearAllToasts();
      if (!sudoModeRequiredForWires) {
        return next();
      }

      return trySudo();
    },
    [submitForm, sudoModeRequiredForWires, clearAllToasts],
  );

  return useObjectMemo({
    wireReasons,
    fedwireTemplateId,
    onContinue,
    form,
  });
};

export const useTemplateSelector = () => {
  const { sortedFedwireTemplateIds } = useData();
  const { showManageTemplatesUI } = useWirePermissions();

  const {
    values: { method },
  } = methodSelectorForm.useForm();

  const form = sendViaTemplateForm.useForm();
  const {
    values: { fedwireTemplateId },
    setFieldValue,
    touched,
    errors,
    resetForm,
  } = form;

  const fedwireTemplate = useOneFedwireTemplate(fedwireTemplateId);

  useEffect(() => {
    setFieldValue("fedwireTemplate", fedwireTemplate, false);
  }, [setFieldValue, fedwireTemplate]);

  const onSelectTemplate = useCallback(
    (templateId: API.FedwireTemplateId) => {
      resetForm();
      const value = templateId === fedwireTemplateId ? "" : templateId;
      setFieldValue("fedwireTemplateId", value, true);
    },
    [fedwireTemplateId, setFieldValue, resetForm],
  );

  return useObjectMemo({
    showManageTemplatesUI,
    sortedFedwireTemplateIds,
    selectedTemplateId: fedwireTemplateId,
    onSelectTemplate,
    method,
    error: touched.fedwireTemplateId && errors.fedwireTemplateId,
    form,
    resetForm,
  });
};

export const useTemplateDetails = (
  fedwireTemplateId: API.FedwireTemplateId,
) => {
  const data = useSelector((state) =>
    selectFedwireTemplateDetails(state, fedwireTemplateId),
  );

  return useMemo(() => {
    if (!data) {
      return null;
    }

    const { template, recipient, account } = data;

    const templateDetails = {
      header: "Template details",
      rows: [
        template.amount ? filters.currency(template.amount) : null,
        template.memo,
        template.wire_reason,
        account.getShortDescription(false),
      ],
    };

    const recipientDetails = {
      header: "Recipient details",
      rows: [recipient.name].concat(
        recipient.address
          .toString()
          .split(",")
          .map((row) => row.trim()),
      ),
    };

    const bankDetails = {
      header: "Bank details",
      rows: [recipient.bankDetails],
    };

    return {
      title: template.name,
      subheader: recipient.name,
      templateDetails,
      recipientDetails,
      bankDetails,
      versionId: template.version_id,
    };
  }, [data]);
};

export const useTemplateUpdated = (
  versionId: API.FedwireTemplate["version_id"],
) => {
  const [showUpdated, setShowUpdated] = useState(false);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const clearLocalTimeout = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  useEffect(() => {
    return clearLocalTimeout;
  }, [clearLocalTimeout]);

  const flashUpdated = useCallback(() => {
    clearLocalTimeout();
    setShowUpdated(true);
    timeout.current = setTimeout(() => {
      setShowUpdated(false);
    }, TEMPLATE_UPDATED_FLASH_DURATION);
  }, [clearLocalTimeout]);

  const previousVersionId = usePrevious(versionId);
  useEffect(() => {
    if (!!previousVersionId && !!versionId && previousVersionId !== versionId) {
      flashUpdated();
    }
  }, [clearLocalTimeout, flashUpdated, previousVersionId, versionId]);

  return showUpdated;
};

export const useReviewDetails = () => {
  const {
    values: { fedwireTemplateId, amount, memo, wireReason },
  } = sendViaTemplateForm.useForm();

  const data = useSelector(
    (state) => selectFedwireTemplateDetails(state, fedwireTemplateId),
    // ToDo is the isEqual needed?
    isEqual,
  );

  const institution = useInstitution();
  const requiresDualApproval = useSelector(dualApprovalRequired);
  const termsUrl = institution?.ach_terms_url;
  const wireFee = institution?.wire_display_fee;

  return useMemo(() => {
    if (!data) {
      return null;
    }

    const { template, recipient, account } = data;

    type Section = {
      header: string;
      rows: Array<{ header: string; value: string }>;
    };

    const templateSection = {
      header: template.name,
      rows: [
        { header: "Recipient name", value: recipient.name },
        { header: "Address", value: recipient.address.toString() },
        {
          header: "Bank details",
          value: recipient.bankDetails,
        },
        {
          header: "Funding account",
          value: account.getShortDescription(false),
        },
      ],
    };

    const additionalDetailsSection: Section = {
      header: "Additional details",
      rows: [],
    };

    const amountRow = (value: Cents) => ({
      header: "Amount",
      value: filters.currency(value),
    });

    if (template.amount) {
      templateSection.rows.push(amountRow(template.amount));
    } else if (amount) {
      additionalDetailsSection.rows.push(amountRow(amount));
    }

    const wireReasonRow = (value: string) => ({
      header: "Wire reason",
      value,
    });

    if (template.wire_reason) {
      templateSection.rows.push(wireReasonRow(template.wire_reason));
    } else if (wireReason) {
      additionalDetailsSection.rows.push(wireReasonRow(wireReason));
    }

    const memoRow = (value: string) => ({
      header: "Memo",
      value,
    });

    if (template.memo) {
      templateSection.rows.push(memoRow(template.memo));
    } else if (memo) {
      additionalDetailsSection.rows.push(memoRow(memo));
    }

    if (wireFee) {
      additionalDetailsSection.rows.push({
        header: "Wire fee",
        value: wireFee,
      });
    }

    const sections: Array<Section> = [templateSection];
    if (additionalDetailsSection.rows.length) {
      sections.push(additionalDetailsSection);
    }

    const disclosure =
      termsUrl &&
      `By ${
        requiresDualApproval ? "submitting," : `tapping "Send wire"`
      } you acknowledge that you agree to the terms of the [electronic funds transfer agreement.](${termsUrl})`;

    const buttonLabel = requiresDualApproval ? "Submit" : "Send wire";

    return {
      sections,
      disclosure,
      buttonLabel,
    };
  }, [amount, data, memo, requiresDualApproval, termsUrl, wireFee, wireReason]);
};

export const useConditionalDetails = () => {
  const form = sendViaTemplateForm.useForm();
  const {
    values: { fedwireTemplateId },
  } = form;

  const fedwireTemplate = useOneFedwireTemplate(fedwireTemplateId);
  const requiresAmount = !fedwireTemplate?.amount;
  const requiresMemo = !fedwireTemplate?.memo;
  const requiresWireReason = !fedwireTemplate?.wire_reason;

  return useObjectMemo({
    templateSelected: !!fedwireTemplateId,
    additionalDetailsRequired:
      requiresAmount || requiresMemo || requiresWireReason,
    requiresAmount,
    requiresMemo,
    requiresWireReason,
  });
};

export const useMethodSelector = () => {
  const form = methodSelectorForm.useForm();
  const {
    values: { method },
    submitForm,
    resetForm,
  } = form;

  const submitMethod = useCallback(async () => {
    const result = await submitForm();
    return result.success;
  }, [submitForm]);

  return useObjectMemo({
    submitMethod,
    resetForm,
    method,
    form,
  });
};

export const useAddOrCreateTemplateForm = (
  existingTemplateId?: API.FedwireTemplateId,
) => {
  const form = upsertTemplateForm.useForm();
  const { setValues, setFieldValue, submitForm } = form;

  const existingTemplate = useOneFedwireTemplate(existingTemplateId);

  useEffect(() => {
    if (existingTemplate) {
      setValues(transformApiTemplateToForm(existingTemplate), false);
    }
  }, [existingTemplate, setValues]);

  const onRemoveRecipient = useCallback(() => {
    setFieldValue("wireRecipientId", "", false);
  }, [setFieldValue]);

  const { createWireTemplate, loading } = useCreateOrEditTemplate();

  const onSubmit = useCallback(
    async (onSuccess?: () => void) => {
      const result = await submitForm();
      if (!result.success) {
        return null;
      }
      return createWireTemplate(existingTemplateId, { onSuccess });
    },
    [createWireTemplate, existingTemplateId, submitForm],
  );

  const wireReasons = useWireReasons();

  return useObjectMemo({
    onRemoveRecipient,
    wireReasons,
    onSubmit,
    loading,
    form,
  });
};

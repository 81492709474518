import { getApiError, useRequestWithFeedback } from "../../../composites";
import { useLibrary } from "../../../providers";

import { API } from "./api";

import type { OverrideRunProps } from "../../../composites";
import type { ApiError } from "../../../../types/models/v1/Error.types";

type PositivePayResponse = {
  positive_pay_form: string;
};

export const useFetchPositivePayHTML = () => {
  const { send, loading } = useRequestWithFeedback<
    PositivePayResponse,
    ApiError
  >();

  const { throwToast } = useLibrary("toasts");

  const fetchHTML = (
    overrideRunProps: OverrideRunProps<PositivePayResponse>,
  ) => {
    send({
      action: API.fetchPositivePay(),
      onError: async (error) => {
        const e = await getApiError(error);

        let message;

        if (e.id === "positive_pay_user_not_enrolled") {
          message =
            "It looks like Positive Pay is not enabled. Please contact support if you would like to turn this service on for yourself or your organization.";
        } else {
          message = e.message || "Unable to connect to Positive Pay.";
        }

        throwToast({ kind: "error", message });
      },
      ...overrideRunProps,
    });
  };

  return { fetchHTML, loading };
};

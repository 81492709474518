import React, { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import Utils from "byzantine/src/utils";
import ApiHttp from "byzantine/src/ApiHttp";
import App from "byzantine/src/App";
import PropTypes from "prop-types";
import { Button, Dialog } from "@narmi/design_system";

const AppAuthorize = ({
  appId,
  institution,
  app,
  children,
  autoLaunch,
  dataClassnames,
}) => {
  const { l10n } = useLocalization();
  const currentApp =
    app || App.deserialize(institution.apps.find((a) => a.client_id === appId));
  const [promptAuthorize, setPromptAuthorize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const launch = (token) => {
    if (!currentApp.has_own_layout) {
      // display in iframe
      window.location = `/apps/${currentApp.client_id}`;
    } else {
      // open in new window
      ApiHttp.fetch(`signed_urls/apps/apps/${currentApp.client_id}`).then(
        (response) => {
          if (autoLaunch) {
            window.location = Utils.appendQuerystringParam(
              response.url,
              "token",
              token,
            ).href;
          } else {
            window.open(
              Utils.appendQuerystringParam(response.url, "token", token).href,
              currentApp.name,
            );
          }
        },
      );
    }
  };
  const handleClick = () => {
    if (!isAuthorizing) {
      setIsAuthorizing(true);
      currentApp
        .getAuthorization()
        .then(({ token }) => {
          launch(token);
        })
        .catch(() => {
          setPromptAuthorize(true);
          setIsLoading(false);
        })
        .finally(() => {
          setIsAuthorizing(false);
        });
    }
  };
  const handleAuthorize = () => {
    if (!isAuthorizing) {
      setIsAuthorizing(true);
      currentApp
        .createAuthorization()
        .then(({ token }) => {
          launch(token);
        })
        .catch()
        .finally(() => {
          setIsAuthorizing(false);
        });
    }
  };

  useEffect(() => {
    if (autoLaunch) {
      setIsLoading(true);
      handleClick();
    }
  }, [autoLaunch]);

  const onUserDismiss = (event) => {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }
    setPromptAuthorize(false);
  };

  if (!isLoading) {
    return (
      <>
        <Button
          kind="menu"
          tabIndex="0"
          data-classname={
            dataClassnames ? `AppAuthorize ${dataClassnames}` : "AppAuthorize"
          }
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              handleClick();
            }
          }}
          onClick={handleClick}
        >
          {children}
        </Button>
        <Dialog
          isOpen={promptAuthorize}
          onUserDismiss={onUserDismiss}
          title={l10n.getString("dialog-title-app-permission", {
            appName: currentApp.name,
          })}
          footer={
            <>
              <Button
                kind="negative"
                onClick={onUserDismiss}
                style={{ paddingRight: "16px" }}
                label={l10n.getString("button-cancel")}
              />
              <Button
                onClick={handleAuthorize}
                label={l10n.getString("button-proceed")}
              />
            </>
          }
        >
          {l10n.getString("app-permission-disclosure", {
            appName: currentApp.name,
          })}
          <ul>
            {Object.values(currentApp.scopes)
              .filter((x) => x)
              .map((scope) => (
                <li key={scope}>{scope}</li>
              ))}
          </ul>
        </Dialog>
      </>
    );
  }
  return null;
};

AppAuthorize.propTypes = {
  appId: PropTypes.string,
  institution: PropTypes.object,
  app: PropTypes.object,
  autoLaunch: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataClassnames: PropTypes.string,
};

export default AppAuthorize;

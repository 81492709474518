/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  OnboardingPromptStatus,
  type OnboardingPlatform,
  type OnboardingPromptResponse,
} from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";

export const filterOnboardingPrompts = (
  platform: OnboardingPlatform,
  prompts: OnboardingPromptResponse["prompts"],
) =>
  prompts
    .filter((item) => item.platforms.includes(platform))
    .map((item) => {
      const updatedItem = { ...item };
      if (item.status === OnboardingPromptStatus.completed) {
        updatedItem.icon_name = "check";
      }
      return updatedItem;
    });

const name = "onboarding";

type OnboardingSliceState = OnboardingPromptResponse;

const initialState: OnboardingSliceState = {
  title: "",
  description: "",
  prompts: [],
};

type UpsertOnboardingPrompts = {
  platform: OnboardingPlatform;
} & OnboardingPromptResponse;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    upsert: (state, action: PayloadAction<UpsertOnboardingPrompts>) => {
      const {
        payload: { title, description, prompts, platform },
      } = action;
      const filteredPrompts = filterOnboardingPrompts(platform, prompts);

      state.title = title;
      state.description = description;
      state.prompts = filteredPrompts;
    },
    clearAll: (state) => {
      state.title = "";
      state.description = "";
      state.prompts = [];
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};

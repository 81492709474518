import { useEffect } from "react";
import { modules } from "byzantine";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import {
  ResponsiveFlex,
  Button,
  Row,
} from "cerulean";
import { moneyFormatter } from "./TransferReview";
import { TRANSFERS_ROUTE } from "./TransferRoutes";
import styles  from "./TransferReview.module.scss"

const ReceiptRow = ({name, value, pad=true}: {name: string, value: string | undefined, pad?: boolean}) => <Row>
    <Row.Item>
      <p className={pad ? "padding--top--s" : ""}>{name}</p>
    </Row.Item>
    <Row.Item shrink><p className={pad ? "padding--top--s" : ""}>{value || "-"}</p></Row.Item>
  </Row>;

export default function TransferReceipt() {
  const form = modules.transfers.TransferForm.useForm();
  const navigate = useNavigate();
  const destinationAccount = modules.transfers.useSelectedDestinationAccount();
  const paymentId = modules.transfers.useSubmittedTransferId();
  const transferState = modules.transfers.useSubmittedTransferState();
  const isExternalTransfer = modules.transfers.useIsExternalTransfer();
  const { l10n } = useLocalization();
  const icon = transferState === "processed" ? "check" : "x";
  const bgColor = transferState === "processed" ? "success" : "error";
  const title = transferState === "processed" ? l10n.getString("header-transfer-receipt") : l10n.getString("header-transfer-rejected");
  const speed = !isExternalTransfer || form.values.instant ? l10n.getString("label-instant") : l10n.getString("label-ach-speed-short");

  useEffect(() => {
    form.submitForm().then(({success}) => {
      if (!success) {
        navigate(`/${TRANSFERS_ROUTE}`);
      }
    })
  }, [])

  return <ResponsiveFlex gapSize="m">
    <div className="padding--all bgColor--white rounded--all">
      <h2 className="padding--y">{title} <span className={`narmi-icon-${icon}`} /></h2>
      <div className={`padding--all bgColor--${bgColor} rounded--all`}>
        <ReceiptRow name={l10n.getString("label-deposit-to")} value={destinationAccount?.getShortDescription()} pad={false} />
        <ReceiptRow name={l10n.getString("label-amount")}value={moneyFormatter.format(form.values.amount / 100)} />
        <ReceiptRow name={l10n.getString("label-delivery-speed")} value={transferState !== "processed" ? l10n.getString("label-transfer-rejected") : speed} />
        {paymentId && <ReceiptRow name={l10n.getString("label-payment-id")} value={paymentId} />}
      </div>
      <div className={`padding--bottom--s margin--top ${styles.receiptButton}`}>
      <Button label={l10n.getString("label-done")} onClick={() => {
        window.location.href = "/";
      }}/>
      </div>
      <div className={`padding--bottom--s ${styles.receiptButton}`}>
        <Button label={l10n.getString("label-make-another-transfer")} kind="plain" onClick={() => {
          window.location.href = "/transfer";
        }}/>
      </div>
    </div>
  </ResponsiveFlex>
}

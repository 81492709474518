import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Select } from "@narmi/design_system";
import WireRecipient from "byzantine/src/WireRecipient";

const RecipientSelector = ({
  field,
  label,
  onChange,
  recipients,
  setShowNewRecipientForm,
  value,
  error,
}) => {
  const { l10n } = useLocalization();
  const selectedRecipient = value.id;

  const handleChange = (recipientId) => {
    const newSelectedRecipient = recipients.find(
      ({ id }) => recipientId === id,
    );
    onChange(newSelectedRecipient);
  };

  return (
    <div className="margin--bottom--l">
      <Select
        id={field}
        label={label}
        value={selectedRecipient}
        error={error}
        onChange={handleChange}
      >
        {recipients.map((recipient) => (
          <Select.Item
            key={recipient.id}
            value={recipient.id}
            searchValue={recipient.displayValue}
          >
            {recipient.displayValue}
          </Select.Item>
        ))}
        <Select.Action onSelect={() => setShowNewRecipientForm(true)}>
          <span className="fontColor--theme--primary fontWeight--semibold">
            <span className="narmi-icon-plus padding--right--xs" />
            {l10n.getString(
              "button-add-new-wire-recipient",
              null,
              "Add new wire recipient",
            )}
          </span>
        </Select.Action>
      </Select>
    </div>
  );
};

RecipientSelector.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  setShowNewRecipientForm: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.string,
};

export default RecipientSelector;

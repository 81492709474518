import React, { useContext } from "react";
import { Select } from "@narmi/design_system";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { FREQUENCIES } from "byzantine/src/Recurrence";

import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";

export const TRANSLATED_FREQUENCIES = Object.freeze({
  [FREQUENCIES.ONCE]: "frequency-once",
  [FREQUENCIES.DAILY]: "frequency-daily",
  [FREQUENCIES.WEEKLY]: "frequency-weekly",
  [FREQUENCIES.EVERY_TWO_WEEKS]: "frequency-every-two-weeks",
  [FREQUENCIES.FIRST_AND_FIFTHTEENTH]: "frequency-first-and-fifteenth",
  [FREQUENCIES.MONTHLY]: "frequency-monthly",
  [FREQUENCIES.YEARLY]: "frequency-yearly",
});

const FrequencySelector = ({ label, onChange, value, excludedFrequencies }) => {
  const { l10n } = useLocalization();
  const { disable_daily_transfer, disable_semimonthly_transfer } = useContext(
    InstitutionSettingsContext,
  );

  return (
    <Select label={label} onChange={onChange} value={value}>
      {Object.values(FREQUENCIES)
        .filter(
          // Filter out frequencies if institutions have respective settings to disable them
          (f) =>
            !(f === FREQUENCIES.DAILY && disable_daily_transfer) &&
            !(
              f === FREQUENCIES.FIRST_AND_FIFTHTEENTH &&
              disable_semimonthly_transfer
            ) &&
            !(excludedFrequencies?.length && excludedFrequencies.includes(f)),
        )
        .map((f) => (
          <Select.Item
            key={f}
            value={f}
            searchValue={TRANSLATED_FREQUENCIES[f]}
            onClick={() => onChange(f)}
          >
            {l10n.getString(TRANSLATED_FREQUENCIES[f])}
          </Select.Item>
        ))}
    </Select>
  );
};
FrequencySelector.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  excludedFrequencies: PropTypes.array,
};

export default FrequencySelector;

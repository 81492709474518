import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";
import BAccount from "../../../../Account";

import { createGroups } from "./utils";

import type Account from "../../../../Account";
import type { RootState } from "../store";
import type { AccountsSliceState } from "./types";

export const selectAccountSorting = (state: RootState) =>
  state.accountSorting.sorting;

export const selectAccountsById = (state: RootState) => state.accounts.byId;

export const selectAccountIdWithCertainty = (
  _: RootState,
  accountId: API.AccountId,
) => accountId;

export const outputBAccount = (
  accounts: AccountsSliceState["byId"],
  accountId: API.AccountId,
) => BAccount.deserialize(accounts[accountId]);

export const selectBAccount = createDeepEqualSelector(
  [selectAccountsById, selectAccountIdWithCertainty],
  outputBAccount,
);

export const outputBAccount2 = (
  accounts: AccountsSliceState["byId"],
  accountId: API.AccountId,
) => {
  if (accounts[accountId]) {
    return BAccount.deserialize(accounts[accountId]);
  }
  return null;
};

export const selectBAccount2 = createDeepEqualSelector(
  [selectAccountsById, selectAccountIdWithCertainty],
  outputBAccount2,
);

export const selectAllAccounts = (state: RootState) =>
  Object.values(state.accounts.byId);

export const selectInternalAccounts = createDeepEqualSelector(
  selectAllAccounts,
  (accounts) =>
    accounts.filter(
      (account) => account.source === "institution",
    ) as API.Account[],
);

export const selectAccounts = createDeepEqualSelector(
  [selectInternalAccounts, (_, accountIds?: string[]) => accountIds],
  (accounts, accountIds) =>
    accounts.filter(({ id }) => !accountIds?.length || accountIds.includes(id)),
);

export const selectBAccounts = createDeepEqualSelector(
  [selectAllAccounts],
  (accounts) => {
    return accounts.map((a) => {
      return BAccount.deserialize(a);
    });
  },
);

export const selectPayableLoanAccounts = createDeepEqualSelector(
  [selectBAccounts],
  (accounts) =>
    accounts.filter(
      (a) => a.isInternalLoan() && a.features.includes("transfer_destination"),
    ),
);

export const selectLoansPayableByCard = createDeepEqualSelector(
  [selectBAccounts],
  (accounts) => accounts.filter((a) => a.isPayableByCard()),
);

export const selectGroupedLoanAccounts = createDeepEqualSelector(
  [selectPayableLoanAccounts, selectAccountSorting],
  (accounts, sorting) => createGroups(accounts, sorting),
);

export const selectGroupedLoanAccountsPayableByCard = createDeepEqualSelector(
  [selectLoansPayableByCard, selectAccountSorting],
  (accounts, sorting) => createGroups(accounts, sorting),
);

export const selectMinimumPayment = createDeepEqualSelector(
  [
    (state) => selectAccounts(state),
    (_, toAccountId: API.AccountId) => toAccountId,
  ],
  (accounts, toAccountId) => {
    const loanAccount =
      accounts.find((account) => account.id === toAccountId) || null;
    return (loanAccount?.loan_details?.minimum_payment as Cents) || null;
  },
);

export const selectMinPaymentDueDate = createDeepEqualSelector(
  [
    (state) => selectAccounts(state),
    (_, toAccountId: API.AccountId) => toAccountId,
  ],
  (accounts, toAccountId) => {
    const loanAccount =
      accounts.find((account) => account.id === toAccountId) || null;
    return loanAccount?.loan_details?.next_payment_at || null;
  },
);

export const selectSourceForInternalLoanPayments = createDeepEqualSelector(
  [selectInternalAccounts],
  (accounts) =>
    accounts.filter((account) => "transfer_source" in account.features),
);

export const selectGroupedInternalLoanPaymentSources = createDeepEqualSelector(
  [selectSourceForInternalLoanPayments, selectAccountSorting],
  (accounts, sorting) => {
    const deserializedAccounts = accounts.map((a) => {
      return BAccount.deserialize(a);
    });
    return createGroups(deserializedAccounts, sorting);
  },
);

export const selectOneAccount = createDeepEqualSelector(
  [selectBAccounts, (_, accountId?: API.AccountId) => accountId],
  (accounts, accountId): Account | undefined => {
    return accountId
      ? accounts.filter((account) => account.id === accountId)[0]
      : undefined;
  },
);

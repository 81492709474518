import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { useTranslation } from "react-i18next";
import M2M from "byzantine/src/M2M";
import { TextInput, Button } from "@narmi/design_system";
import { ContextForm, Dialog, useFormData } from "cerulean";
import { useSudoContext } from "../SudoContext";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";

const M2MAccountNumberTextInput = ({
  onChange,
  value,
  field,
  ...textInputProps
}) => {
  const handleInput = (event) => {
    onChange(event.target.value.replace(/[^a-zA-Z\d=-]/g, ""));
  };
  return <TextInput onChange={handleInput} value={value} {...textInputProps} />;
};
M2MAccountNumberTextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.string,
};

const NewMemberModal = ({ accounts, handleClose, open, newMemberAdded }) => {
  const { l10n } = useLocalization();
  const { t } = useTranslation();
  const { formData, onChange, setFormData } = useFormData({});
  const { establishSudo, setDestinationRoute } = useSudoContext();
  const location = useLocation();
  const { sudo_mode_required_for_add_member } = useInstitutionSettings();

  useEffect(() => {
    setDestinationRoute(location.pathname);
  }, []);

  const closeDialogAndResetForm = () => {
    handleClose();
    setFormData({});
  };

  const addMember = async (callback) => {
    const newMember = new M2M({
      amount: 0,
      from_account_id: accounts[0].id,
      to_account_number: formData.account_number,
      first_name: formData.first_name,
      last_name: formData.last_name,
    });
    try {
      const res = await newMember.addNewMemberFriend();
      newMemberAdded(res.member_friends[0].id);
      callback();
      closeDialogAndResetForm();
    } catch (error) {
      if (sudo_mode_required_for_add_member) {
        // eslint-disable-next-line no-underscore-dangle
        newMemberAdded(undefined, error.__all__?.[0]);
      }
      callback(sudo_mode_required_for_add_member ? undefined : error);
    }
  };

  const onSubmit = (callback) => {
    if (sudo_mode_required_for_add_member) {
      establishSudo(location.pathname, () => addMember(callback));
    } else {
      addMember(callback);
    }
  };

  return (
    <Dialog
      isOpen={open}
      onUserDismiss={closeDialogAndResetForm}
      title={t(l10n.getString("member-add-label"))}
    >
      <div className="margin--top--s">
        <ContextForm data={formData} onChange={onChange}>
          <div className="margin--bottom--l">
            <ContextForm.Field required>
              <TextInput field="first_name" label="First name" />
            </ContextForm.Field>
          </div>
          <div className="margin--bottom--l">
            <ContextForm.Field required>
              <TextInput field="last_name" label="Last name" />
            </ContextForm.Field>
          </div>
          <div className="margin--bottom--l">
            <ContextForm.Field required>
              <M2MAccountNumberTextInput
                field="account_number"
                label="Account number"
              />
            </ContextForm.Field>
          </div>
          <ContextForm.Field>
            <TextInput
              field="relationship"
              label="Relationship (i.e. sister)"
            />
          </ContextForm.Field>
          <ContextForm.ActionBar
            style={{
              marginTop: "40px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <ContextForm.Action
              noValidation
              onSubmit={closeDialogAndResetForm}
              dangerouslyDisableShowLoading
            >
              <Button kind="negative" label="Cancel" type="button" />
            </ContextForm.Action>
            <ContextForm.Action
              dangerouslyDisableShowLoading={sudo_mode_required_for_add_member}
              onSubmit={onSubmit}
            >
              <Button label={t(l10n.getString("member-add-button"))} />
            </ContextForm.Action>
          </ContextForm.ActionBar>
        </ContextForm>
      </div>
    </Dialog>
  );
};

NewMemberModal.propTypes = {
  accounts: PropTypes.array,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  newMemberAdded: PropTypes.func,
};

export default NewMemberModal;

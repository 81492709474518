import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TextInput, ResponsiveFlex, ProgressButtons } from "cerulean";
import { entities, modules, useFeature } from "byzantine";
import {
  type LimitsForDisplayType,
} from "byzantine/src/dbbl/businessLogic/modules/achPayments";
import { useLocalization } from "@fluent/react";
import AccountSelector from "../../AccountSelector";
import * as formAdapters from "../../../src/adapters";
import HeaderWithSteps from "../../HeaderWithSteps";
import LimitsDialog from "../LimitsDialog";
import { useSudoContext } from "../../SudoContext";
import { TRANSFERS_ROUTE, TRANSFER_DETAILS_ROUTE } from "./TransferRoutes";

export const TOTAL_STEPS = 3;

function AccountSelectSection({ limits }: { limits: LimitsForDisplayType }) {
  const institution = entities.institution.useInstitution();
  const { featureEnabled } = useFeature();
  const form = modules.transfers.TransferForm.useForm();
  const selectedSourceAccount = modules.transfers.useSelectedSourceAccount();
  const selectedDestinationAccount =
    modules.transfers.useSelectedDestinationAccount();
  const [searchParams,] = useSearchParams();
  const { l10n } = useLocalization();

  const sourceAccounts = entities.accounts.useValidTransferSources(
    selectedDestinationAccount?.id,
    featureEnabled("internal_transfers"),
  );
  const prepopulateFromAccount = searchParams.get("from_account_id")
  const prepopulateToAccount = searchParams.get("to_account_id")

  const destinationAccounts = entities.accounts.useValidTransferDestinations(
    !!institution?.ach_allows_push,
    selectedSourceAccount?.id,
    featureEnabled("internal_transfers"),
    featureEnabled("enable_loans_in_transfer_flow"),
  );

  useEffect(() => {
    if(prepopulateFromAccount && sourceAccounts.map((a) => a.id).find((id) => id === prepopulateFromAccount)){
      form.setFieldValue("fromAccount", prepopulateFromAccount, true);
    }
    if(prepopulateToAccount && destinationAccounts.map((a) => a.id).find((id) => id === prepopulateToAccount)){
      form.setFieldValue("toAccount", prepopulateToAccount, true);
    }
  }, [prepopulateFromAccount, prepopulateFromAccount])

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">{l10n.getString("header-transfer-details")}</h4>
        <div className="transfer-amount-container">
          <TextInput
            label="Amount"
            {...formAdapters.amountInput(form, "amount")}
          />
        </div>
        <LimitsDialog limitsForDisplay={limits} />
        <h4 className="fontSize--m nds-sans padding--top--xs">
          {l10n.getString("header-select-accounts")}
        </h4>
        <div className="transfer-accounts-container padding--bottom--s">
          <AccountSelector
            accounts={sourceAccounts}
            label={l10n.getString("label-from")}
            field="fromAccount"
            isDestination={false}
            {...formAdapters.select(form, "fromAccount")}
          />
        </div>
        <div className="transfer-accounts-container">
          <AccountSelector
            accounts={destinationAccounts}
            label={l10n.getString("label-to")}
            field="toAccount"
            isDestination={true}
            {...formAdapters.select(form, "toAccount")}
          />
        </div>
      </ResponsiveFlex>
    </>
  );
}

export default function AccountSelectScreen({
  limits,
}: {
  limits: LimitsForDisplayType;
}) {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const { useSetInitialSteps } = useSudoContext();
  const { checkDestinationInstantEligibility } =
    modules.transfers.useCheckDestinationInstantEligibility();
  const { getInstantTransferFees } =
    modules.transfers.useGetInstantTransferFees();
  const destination = modules.transfers.useSelectedDestinationAccount();
  const form = modules.transfers.TransferForm.useForm();

  const handleSubmit = useCallback(async () => {
    checkDestinationInstantEligibility();
    getInstantTransferFees();
    const { success } = await form.submitForm();
    if(success){
      navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_DETAILS_ROUTE}`);
    }
  }, [form.values, destination]);

  useSetInitialSteps(TOTAL_STEPS);

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText={l10n.getString("header-make-a-transfer")}
        step={1}
        totalSteps={TOTAL_STEPS}
      />

      <AccountSelectSection limits={limits} />

      <ProgressButtons
        backLabel={l10n.getString("button-cancel")}
        nextLabel={l10n.getString("button-next")}
        onBack={() => {
          navigate("/transfers");
        }}
        onNext={handleSubmit}
      />
    </ResponsiveFlex>
  );
}

import type { NetworkPayload } from "../../../composites";

const resourceUrl = "prompts";
const API = {
  getOnboardingPrompts: (): NetworkPayload => {
    return {
      url: `${resourceUrl}/onboarding/`,
      options: {
        method: "GET",
      },
    };
  },
};

export default API;

/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import { Button, Dialog, customEvents, useNotificationContext } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";

let timer = null;
export const MAX_POLL_RATE = 5000;
const HEARTBEAT_INTERVAL = 1000;
const { SESSION_HEARTBEAT } = customEvents;
const DEBOUNCE_RATE = 500;

// events on window that count as activity to extend the session
const ACTIVITY_EVENTS = ["click", "keydown", "visibilitychange"];

export const pollCallback = (response, setLastActivity) => {
  // if we're logged out due to inactivity the server 302 redirects us, just reload
  // the reloaded page will include the login form
  if (response?.session?.idle_seconds === undefined) {
    window.open(`/logout`, "_self");
    return;
  }
  setLastActivity(Date.now() - Number(response.session.idle_seconds) * 1000);
};

const sessionActivityGet = (setLastActivity) => {
  ApiHttp.fetch(`/session/activity`, {
    endpointIsUrl: true,
    headers: { "Cache-Control": "no-cache" },
  })
    .then((response) => {
      pollCallback(response, setLastActivity);
    })
    .catch(() => {});
};

export const pollActivity = (warnSeconds, lastActivity, setLastActivity) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    sessionActivityGet(setLastActivity);
  }, Math.max(warnSeconds * 1000 - (Date.now() - lastActivity), MAX_POLL_RATE));
};

export const sessionActivityPost = (setLastActivity, sendNotification) => {
  ApiHttp.fetch("/session/activity", {
    method: "POST",
    endpointIsUrl: true,
  })
    .then(() => {
      // prompts effect hook to close dialog
      setLastActivity(Date.now());
    })
    .catch((error) => {
      sendNotification({ type: "negative", text: error });
    });
};

export const InactivityDialog = ({ warnSeconds }) => {
  const { l10n } = useLocalization();
  const [isInactive, setIsInactive] = useState(false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const { sendNotification } = useNotificationContext();

  useEffect(() => {
    setIsInactive(Date.now() - lastActivity >= warnSeconds * 1000);
    pollActivity(warnSeconds, lastActivity, setLastActivity);

    return () => clearTimeout(timer);
  }, [lastActivity]);

  useEffect(() => {
    const trackActivity = () => {
      sessionActivityPost(setLastActivity, sendNotification);
    };
    // AgentIQ activity
    window.__aiq?.on("ui", trackActivity);

    return () => {
      window.__aiq?.off("ui", trackActivity);
    };
  }, [setLastActivity]);

  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      sessionActivityGet.bind(null, setLastActivity)
    );
    return () =>
      document.removeEventListener(
        "visibilitychange",
        sessionActivityGet.bind(null, setLastActivity)
      );
  }, []);

  useEffect(() => {
    const setActivity = () => {
      // Can't report activity if the user isn't logged in. It throws an error.
      if (window.location.pathname.match(/^\/login/)) {
        return;
      }
      sessionActivityPost(setLastActivity, sendNotification);
    };
    const setActivityDebounced = debounce(setActivity, DEBOUNCE_RATE);
    ACTIVITY_EVENTS.forEach((e) => {
      document.addEventListener(e, setActivityDebounced);
    });
    return () => {
      ACTIVITY_EVENTS.forEach((e) => {
        document.removeEventListener(e, setActivityDebounced);
      });
    };
  }, [sendNotification]);

  // Fire a custom event we can use globally as a session activity heartbeat.
  // Allows code outside of this component (and React) to detect session status.
  useEffect(() => {
    const sessionHeartbeat = window.setInterval(() => {
      if (!isInactive) {
        window.dispatchEvent(new CustomEvent(SESSION_HEARTBEAT));
      }
    }, HEARTBEAT_INTERVAL);
    return () => {
      window.clearInterval(sessionHeartbeat);
    };
  }, []);

  return (
    <div
      className="nds-typography"
      data-testid={isInactive ? "dialog open" : "dialog closed"}
    >
      <Dialog
        isOpen={isInactive}
        onUserDismiss={() => {
          // allow user to dismiss the dialog without extending the session
          setIsInactive(false);
        }}
        footer={
          <div
            style={{ textAlign: "right" }}
            className="inactivity-dialog-footer"
          >
            <Button
              onClick={sessionActivityPost.bind(
                null,
                setLastActivity,
                sendNotification
              )}
              label={l10n.getString("button-continue", null, "Continue")}
            />
          </div>
        }
        title={l10n.getString(
          "heading-inactivity",
          null,
          "Are you still here?"
        )}
      >
        <div style={{ fontSize: "16px" }}>
          <Localized id="inactivity-description">
            For security reasons, your session times out after you’ve been
            inactive for a while. Click Continue to stay logged in.
          </Localized>
        </div>
      </Dialog>
    </div>
  );
};

InactivityDialog.propTypes = {
  warnSeconds: PropTypes.string.isRequired,
};

export default InactivityDialog;
